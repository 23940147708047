import React, { useState, useRef, useMemo } from 'react';
import DataTable from 'react-data-table-component';
import { Button, Modal } from "react-bootstrap"; // Asegúrate de tener Bootstrap como dependencia
import JornadaAnioPeriodoNivelCurso from '../components/Filters/JornadaAnioPeriodoNivelCurso';
import useCustomLightDataTableStyles from '../components/estilosDeDataTable';
import { Select } from '@mui/material';
import EvaluationForm from '../components/Forms/observador-modal';
import observationNoteService from '../services/observationNoteService';
import Loading from '../components/Loading';
import academicService from '../services/academicService';
import { toast } from 'react-toastify';
import evaluativeScaleService from '../services/evaluativeScaleService';
import estudiantesService from '../services/estudiantesService';
import utils from '../utils/generalFunctions';
import moment from 'moment';

const Observador = () => {
    const customStyles = useCustomLightDataTableStyles();
    const filterReference = useRef(null);
    const initData ={
      schoolYearId: "0",
      journeyId:"0",
      periodId:"",
      educationLevelId: "0",
  };
  const [periodos, setPeriodosList] = useState([]);
  const [formData, setFormData] = useState(initData);
  const [datosBusqueda, setDatosBusqueda] = useState([]);
  const [estudiantes, setEstudiantes] = useState([]);

    // Estado y función para manejar el modal
  const [showModal, setShowModal] = useState(false);
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [showLoading, setShowLoading] = useState(false);
  const [readOnly, setReadOnly] = useState(false);
  const date = moment(formData.startDate).format("yyyy-MM-DD");

  const updateForm = async (data) => {
    if (data.schoolYearId!==formData.schoolYearId){   
      await cargarPeriodos(data.schoolYearId);
    }
    setFormData(prevState => ({
        ...prevState,
        ...data
      }));
}

const cargarPeriodos = async (schoolYearId) => {
  try {
      const schoolYear = await academicService.getYearById(schoolYearId);              
      if(schoolYear){
          setPeriodosList(schoolYear.periods)
      }            
  } catch (error) {
       console.error("Error al obtener información de año escolar:", error);
  }
}

const evaluationExecuted = (evaluationProcess) => {
  const evaluation = evaluationProcess?.filter(e=> e.evaluativeScaleId!==null && e.observationEvaluateId>0);
  return evaluation?.length > 0;
}

  const handleShowModal = (student, readOnly) => {
    setReadOnly(readOnly);
    setSelectedStudent(student);
    setShowModal(true);
  };

  const handleCloseModal = (reload, clearStudent) => {

    if(reload){
      generarReporte();
    }
    if(clearStudent){
      const evaluationProcess = selectedStudent.evaluationProcess;
        evaluationProcess.map(m=> {
          if(m.evaluativeScaleId && m.observationEvaluate===0){
            m.evaluativeScaleId = null;
          }
          return m;
      })
    }
    setShowModal(false);
    setSelectedStudent(null);
  };

  const columns = [
    { name: "Estudiantes", selector: row => row.student.studentFullName, sortable: true },
    {
      name: "Evaluar",
      button: true,
      cell: row => (
        <>
           {!evaluationExecuted(row.evaluationProcess) && (
            <button variant="primary" onClick={() => handleShowModal(row, false)}>
                <i class="fa-solid fa-clipboard-list"></i>
            </button>
           )}
        </>
      )
    },
    {
      name: "Realizado",
      button: true,
      cell: row => (
        <>
        {evaluationExecuted(row.evaluationProcess) && (
          <button variant="success" onClick={() => handleShowModal(row, false)}>
            <i class="fa-solid fa-clipboard-check"></i>
          </button>
        )}
     </>
      )
    },
  ];


  // Función para manejar el evento "Buscar"
  const generarReporte  = async (e)  => {
        if(e) e.preventDefault();
        const requiredFields = ['schoolYearId', 'journeyId', 'periodId','educationLevelId','courseId'];
        for (let field of requiredFields) {
            if (!formData[field] || formData[field]==='0') {
                toast.error("Por favor, completa todos los campos obligatorios.", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                  });
                return;
            }
        }
        setDatosBusqueda([]);
        setShowLoading(true);
        try {
           const copyData = utils.copy(formData);
           copyData.periodName = periodos.find(p=>String(p.academicPeriodId)===String(copyData.periodId))?.periodName??"";
           const years = filterReference.current?.getSchoolYears();
           if(years){
                copyData.year = years.find(p=>String(p.schoolYearId)===String(copyData.schoolYearId))?.year??"";
           }
           const journeys = filterReference.current?.getJourneys();
           if(journeys){
                copyData.journey = journeys.find(p=>String(p.journeyId)===String(copyData.journeyId))?.name??"";
           }

           const courses = filterReference.current?.getCursos();
           if(courses){
                copyData.course = courses.find(p=>String(p.courseId)===copyData.courseId)?.name??"";
           }

           const educationLevels = filterReference?.current?.getEducationLeves();
           if (educationLevels.length > 0) {
                copyData.evaluationTypeId = educationLevels.find((c) => String(c.educationLevelId) === String(copyData.educationLevelId))?.evaluationTypeId;
           }

          // const evaluationScaleResult = await evaluativeScaleService.getEvaluativeScale(
          //       copyData.evaluationTypeId
          // );
          // setEvaluationScale(evaluationScaleResult);

           setDatosBusqueda(copyData);
           
          const prevData = await observationNoteService.getObservationNotasCourseReport(
            "",
            copyData.courseId,
            copyData.schoolYearId,
            copyData.periodId
          ); // Obtenemos los estudiantes de tu sistema
          const data = prevData.filter(s=>s.student.isActive);
          console.log(data);
          if(data && data.length>0){
            setEstudiantes(data);
          }else{
            toast.error("No se encontraron estudiantes matriculados.", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
              setEstudiantes([]); 
          }
        } catch (error) {
          console.error("Error al obtener asistencias:", error);
        }
        setShowLoading(false);
  };

  return (
    <>
    <div className="card p-3 mb-3">
  <h1>Observador</h1>
  <p>Visualiza y evalúa el desempeño de los estudiantes.</p>
  <div className="card-body">
    <form className="row mb-3 align-items-end">
      <JornadaAnioPeriodoNivelCurso
        callback={updateForm}
        ref={filterReference}
        loadTutoria={true}
        loadCoordinators={true}
        hideAsignatura={true}
        properties={{
          className: 'col-md-4',
          id: 'formObservationEvaluation',
          filtro: false
        }}
      />
      <div className="col-md-4 d-flex text-end align-items-end">
        <button onClick={generarReporte} className="btn btn-primary w-100">
          Buscar
        </button>
      </div>
    </form>
  </div>
</div>


      {showLoading && <Loading />}

      {!showLoading && (
            <DataTable
            columns={columns}
            data={estudiantes}
            customStyles={customStyles}
            className="observador-datatable shadow-sm rounded table table-bordered table-sm my-0 dataTables_wrapper dt-bootstrap5 no-footer"
            noDataComponent="No se encontraron estudiantes."
            noHeader
            dense
            highlightOnHover
            pointerOnHover
            fixedHeader
            fixedHeaderScrollHeight="450px"
          />
      )}
      
       {/* Modal para evaluación de estudiante */}
       {selectedStudent && (
         <Modal show={showModal} onHide={()=> {
              handleCloseModal(false, !readOnly);
            }
          } size="lg">
         <Modal.Header closeButton>
           <Modal.Title>Evaluación de {selectedStudent?.student.studentFullName}</Modal.Title>
         </Modal.Header>
         <Modal.Body>
                <div className="row datos-basicos">
            <div className="col-md-4">
                <div className="text-muted">Nombre del Estudiante:</div>
                <strong>{selectedStudent?.student.studentFullName}</strong>
            </div>
            <div className="col-md-4">
                <div className="text-muted">Grado:</div>
                <strong>{selectedStudent?.student.grade}</strong>
            </div>
            <div className="col-md-4">
                <div className="text-muted">Fecha:</div>
                <strong>{date}</strong>
            </div>
            <div className="col-md-6 text-md-right">
                <div className="text-muted">Tutor:</div>
                <strong>{selectedStudent?.student.groupDirectorName}</strong>
            </div>
                </div>
                <hr class="my-4"></hr>
                <EvaluationForm 
                  selectedStudent={selectedStudent} 
                  readOnly={readOnly}
                  onClose={(reload) =>{
                    handleCloseModal(reload, false);
                }} />
        
        </Modal.Body>
 
         <Modal.Footer>
           <button variant="secondary" onClick={()=>{
                      handleCloseModal(false, true);
              }}>
             Cerrar
           </button>
         </Modal.Footer>
       </Modal>
       )}

    </>
  );
};

export default Observador;
