import api from './api';

const getDocentes = async () => {
    try {
        const response = await api.get('/teacher');
        return response.data;
    } catch (error) {
        throw error;
    }
};

const createDocente = async (teacherData) => {
    try {
        const response = await api.post('/teacher', teacherData);
        return response.data;
    } catch (error) {
        throw error;
    }
};

const updateDocente = async (teacherData) => {
    try {
        const response = await api.put('/teacher', teacherData);
        return response.data;
    } catch (error) {
        throw error;
    }
};

const getDocente = async (id) => {
    try {
        const response = await api.get(`/teacher/${id}`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

const subirImagenDocente = async (image, teacherId) => {
    try {
        const formDataToSend = new FormData();
        formDataToSend.append('profilePicture', image);   
        formDataToSend.append('teacherId', teacherId);   
        const response = await api.post('/teacher/image-upload', formDataToSend, {
            headers: { 'Content-Type': 'multipart/form-data' }
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};

const deleteDocenteById = async (id) => {
    try {
        const response = await api.delete(`/teacher/${id}`);
        return response.data;
    } catch (error) {
        throw error;
    }
};


export default {
    getDocentes,
    createDocente,
    subirImagenDocente,
    getDocente,
    updateDocente,
    deleteDocenteById
};
