import React, { useState } from 'react';
import courseService from '../../services/courseService';
import Swal from 'sweetalert2';
import { useEffect } from 'react';
import gradesService from '../../services/gradesService';
import Loading from '../Loading';

const CourseForm = (props) => {
    const { course, onClose, ReadOnly } = props;
    const initData = {
        courseId: 0,
        number: "",
        gradeId: "",
        name: "",
        isActive: true
    };

    const [formData, setFormData] = useState(initData);
    const [gradesList, setGradesList] = useState([]);
    const [showLoading, setShowLoading] = useState(true);


    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Validación básica
        const requiredFields = ['gradeId', 'name'];
        for (let field of requiredFields) {
            if (!formData[field]) {
                Swal.fire(
                    "Campos Obligatorios",
                    "Por favor, completa todos los campos obligatorios.",
                    "error"
                );
                return;
            }
        }

        if (!ReadOnly) {
            setShowLoading(true);
            try {
                let courseId = 0;
                if (formData.courseId > 0) {
                    const response = await courseService.updateCurso(formData);
                    courseId = response.courseId;
                } else {
                    const response = await courseService.createCurso(formData);
                    courseId = response.courseId;
                }
                if (courseId) {
                    Swal.fire(
                        "Felicidades!",
                        "Curso guardado con éxito!",
                        "success"
                    );
                    if (onClose) onClose();
                    setFormData(initData);
                } else {
                    Swal.fire(
                        "Ha ocurrido un error",
                        "Por favor intentelo mas tarde",
                        "error"
                    );
                }
            } catch (error) {
                console.error('Error al enviar el formulario:', error);
                Swal.fire(
                    "Ha ocurrido un error",
                    "Por favor intentelo mas tarde",
                    "error"
                );
            }
            setShowLoading(false);
        }
    };

    useEffect(() => {
        const fetchCollections = async () => {
          try {
            const repGradesList = await gradesService.getAllGrades();
            setGradesList(repGradesList);
            if (course) {
              const resGrade = await courseService.getCurso(course);              
              setFormData((prevState) => ({ ...prevState, ...resGrade }));
            }
            setShowLoading(false);
          } catch (error) {
            console.error("Error al obtener listas de formulario:", error);
          }
        };
    
        fetchCollections();
      }, [course]);

    return (

        <>
        {showLoading && <Loading />}

        {!showLoading && (

            <div className="container-fluid mt-4">
                <p className="text-muted">Por favor, llena todos los campos para registrar un nuevo curso.</p>
                <form onSubmit={handleSubmit} className="mt-3">
                    <div className="row">
                        <div className="col-md-6 mb-3">
                            <label htmlFor="name" className="col-form-label  text-sm-right">Nombre:*</label>
                            <input type="text" readOnly={ReadOnly} className="form-control" id="name" name="name" placeholder="Ingrese el nombre del curso" value={formData.name} onChange={handleChange} required />
                        </div>
                        <div className="col-md-6 mb-3">
                            <label className="col-form-label  text-sm-right">Grado:*</label>
                            <select
                                className="form-control form-select validarForm"
                                name="gradeId"
                                value={formData.gradeId}
                                onChange={handleChange}
                                readOnly={ReadOnly}
                                required
                            >
                                <option value="" disabled={ReadOnly}>
                                Seleccione
                                </option>
                                {gradesList?.map((option) => (
                                <option
                                    key={option.gradeId}
                                    value={option.gradeId}
                                    disabled={ReadOnly}
                                >
                                    {option.name}
                                </option>
                                ))}
                            </select>
                        </div>
                        <div className="col-md-6 mb-6 padding-top-20 padding-left-10">
                            <input type="checkbox" disabled={ReadOnly} className="form-check-input" id="isActive" name="isActive" checked={formData.isActive} onChange={e => setFormData({ ...formData, isActive: e.target.checked })} />
                            <label className="form-check-label" htmlFor="isActive">Activo</label>
                        </div>
                    </div>

                    {!ReadOnly && (
                        <div className='row'>
                            <div className='col-md-2 center padding-top-20 padding-bottom-20'>
                                <button type="submit" className="btn btn-primary">Guardar</button>
                            </div>
                        </div>
                    )}
                </form>
            </div>
         )}
        </>
    );
}

export default CourseForm;
