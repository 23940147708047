import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import useCustomDataTableStyles from "../components/estilosDeDataTable";
import TipoIncidenciaForm from "../components/Forms/TipoIncidenciaForm";
import { MDBModal, MDBModalBody, MDBModalContent, MDBModalDialog, MDBModalFooter, MDBModalHeader, MDBModalTitle } from "mdb-react-ui-kit";
import tipoIncidenciaService from "../services/tipoIncidenciaService";
import Swal from "sweetalert2";
import Loading from "../components/Loading";

const TiposIncidencias = () => {
    const [tiposIncidencias, setTiposIncidencias] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedTipoIncidencia, setSelectedTipoIncidencia] = useState(null);
    const [filterTextTipos, setFilterTextTipos] = useState('');
    const [showLoading, setShowLoading] = useState(false);

    const toggleShow = () => {
        setIsModalOpen(!isModalOpen);
        setSelectedTipoIncidencia(null);
    };

    const openModal = () => {
        setSelectedTipoIncidencia(null);
        setIsModalOpen(true);
    };

    const fetchTiposIncidencias = async () => {
        setShowLoading(true);
        try {
            const response = await tipoIncidenciaService.getIncidenciasTipos();
            if (response) {
                const data = response.filter(r => r.parentTypeId !== null);
                setTiposIncidencias(data);
            } else {
                setTiposIncidencias([]);
            }
        } catch (error) {
            console.error("Error al obtener tipos de incidencias:", error);
        }
        setShowLoading(false);
    };

    const removeIncidenciaType = (inicidenceTypeId) => {
        if (inicidenceTypeId) {
            Swal.fire({
                title: '¿Está seguro de que desea eliminar este tipo de incidencia?',
                text: "",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Sí, eliminar!'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    try {
                        await tipoIncidenciaService.deleteIncidenciasTipoById(inicidenceTypeId);
                        Swal.fire(
                            'Felicidades!',
                            'Tipo de incidencia eliminada exitosamente!',
                            'success'
                        );
                        await fetchTiposIncidencias();
                    } catch (error) {
                        Swal.fire(
                            "Ha ocurrido un error",
                            "Por favor intentelo más tarde",
                            "error"
                        );
                    }
                }
            });
        }
    };

    useEffect(() => {
        fetchTiposIncidencias();
    }, []);

    const columns = [
        {
            name: "N°",
            selector: row => row.number,
            sortable: true,
            cell: (row, index) => <span>{index + 1}</span>,
        },
        { name: "Nombre", selector: row => row.description, sortable: true },
        { name: "Categoría", selector: row => row.parentTypeName, sortable: true },
        {
            name: "Acciones",
            selector: "actions",
            cell: (row) => (
                <div className="btn-group btn-group-sm">
                    <button
                        className="btn btn-outline-info me-1"
                        title="Editar"
                        onClick={() => {
                            setSelectedTipoIncidencia(row);
                            setIsModalOpen(true);
                        }}>
                        <i className="icon-edit fas fa-pencil-alt"></i>
                    </button>
                    <button
                        className="btn btn-outline-danger me-1"
                        title="Eliminar"
                        onClick={() => {
                            removeIncidenciaType(row.inicidenceTypeId);
                        }}>
                        <i className="icon-deactivate fas fa-times"></i>
                    </button>
                </div>
            ),
        },
    ];

    const customStyles = useCustomDataTableStyles();

    const normalizeString = (str) => {
        return str
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .toLowerCase();
    };

    const filteredItems = tiposIncidencias?.filter((item) => {
        if (item) {
            const allData = `${item.description} ${item.parentTypeName}`;
            const normalizedFilterText = normalizeString(filterTextTipos);
            const normalizedItemName = normalizeString(allData);
            return normalizedItemName.includes(normalizedFilterText);
        }
        return item;
    });

    return (
        <>
            <div className="card p-3 filter-container">
                <h1>Gestión de Tipos de Incidencias</h1>
                {showLoading && <Loading />}
                {!showLoading && (
                    <>
                        <div className="row mb-3 align-items-end">
                            <div className="col-md-6">
                                <input
                                    type="text"
                                    placeholder="Buscar tipos de incidencias..."
                                    value={filterTextTipos}
                                    onChange={e => setFilterTextTipos(e.target.value)}
                                    className="form-control"
                                />
                            </div>
                            <div className="col-md-6 text-end">
                                <button className="btn btn-primary" onClick={openModal}>
                                    <i className="fa-solid fa-circle-plus"></i> Registrar Tipo de Incidencia
                                </button>
                            </div>
                        </div>
                        <DataTable
                            noHeader
                            noDataComponent="No hay tipos de incidencias que mostrar, por favor agregalos"
                            columns={columns}
                            data={filteredItems}
                            customStyles={customStyles}
                            pagination
                            persistTableHead
                            fixedHeader
                            fixedHeaderScrollHeight="450px"
                        />
                    </>
                )}
                <MDBModal tabIndex='-1' show={isModalOpen} setShow={setIsModalOpen}>
                    <MDBModalDialog>
                        <MDBModalContent>
                            <MDBModalHeader>
                                <MDBModalTitle>{selectedTipoIncidencia ? "Editar Tipo de Incidencia" : "Registrar Tipo de Incidencia"}</MDBModalTitle>
                                <button type='button' className='btn-close' color='none' onClick={toggleShow}></button>
                            </MDBModalHeader>
                            <MDBModalBody>
                                {selectedTipoIncidencia ? (
                                    <TipoIncidenciaForm
                                        tipoIncidencia={selectedTipoIncidencia}
                                        onClose={() => {
                                            toggleShow();
                                            fetchTiposIncidencias();
                                        }}
                                    />
                                ) : (
                                    <TipoIncidenciaForm
                                        tipoIncidencia={null}
                                        onClose={() => {
                                            toggleShow();
                                            fetchTiposIncidencias();
                                        }}
                                    />
                                )}
                            </MDBModalBody>
                            <MDBModalFooter>
                                <button type='button' className='btn-footer-close btn-primary' onClick={toggleShow}>
                                    Cerrar
                                </button>
                            </MDBModalFooter>
                        </MDBModalContent>
                    </MDBModalDialog>
                </MDBModal>
            </div>
        </>
    );
};

export default TiposIncidencias;
