
import { useRef, useState } from "react";
import HeaderActa from "../../../components/headerActas";
import FooterActa from "../../../components/FooterActa";
import moment from "moment";
import types from "../../../services/types";


const RepoBoletinDimension = ({ boletin, periodo }) => {
    const filteReference = useRef(null);
    const [showLoading, setShowLoading] = useState(false);
    const student = boletin.students[0];

    const getRowSpan = (processNotes) =>{
       let rows = processNotes.length+1;
       for(let process of processNotes){
            rows= rows + process.achievements.length;
       }
       return rows;
    }

    return (
        <>
          {!showLoading && boletin && (

            <>
                {/* Cabezera   */}

                <div class="card acta-container">
                
                {/* <!-- Cabezera --> */}
                <div className="card-body">
                <HeaderActa />
                {/* <!-- Ficha de Estudiante --> */}
                <section class="incidencia-info-boletin">
                    <div class="info-header-boletin">
                    <h5 class="section-title-boletin">INFORME EVALUATIVO</h5>
                    </div>
                    <table className="datos-incidencia-table table table-sm my-2">
                        <tbody>
                            <tr>
                            <th className="info-title-boletin">Estudiante</th>
                                <td className="info-content-boletin">{student.studentFullName}</td>
                                <th className="info-title-boletin">Período</th>
                                <td className="info-content-boletin">{boletin.periodName}</td>
                                
                            </tr>
                            <tr>
                            <th className="info-title-boletin">Grado</th>
                                <td className="info-content-boletin">{boletin.gradeName}</td>
                                <th className="info-title-boletin">Año</th>
                                <td className="info-content-boletin">{boletin.schoolYearName}</td>
                                
                            </tr>
                            <tr>
                                
                                <th className="info-title-boletin">Tutor</th>
                                <td className="info-content-boletin">{boletin.tutorName}</td>
                                <th className="info-title-boletin">Fecha impresión</th>
                                <td className="info-content-boletin">{moment().format("DD-MM-yyyy hh:mm")}</td>
                            </tr>
                        </tbody>
                    </table>

                </section>
                
                
                {/* <!-- Sección de Escala Valorativa --> */}
                <section className="escala-valorativa-container">
                    <div className="escala-valorativa-header">
                        <h5 className="section-title-boletin">ESCALA VALORATIVA</h5>
                    </div>
                    <div className="escala-valorativa-body-prescolar">
                        {boletin.evaluationScale?.map((a, index) => (
                        <div key={index} className="escala-item">
                            {a.name} = {a.abbreviation}
                        </div>
                        ))}
                    </div>
                </section>

                
                {/* <!-- Aquí podrías añadir más secciones del informe como el historial académico, asistencia, etc. -->
                <!-- Sección de Áreas Académicas --> */}
                <div class="academic-areas escala-valorativa-container">
                    <table class="table-custom-boletin">
                    {student.areasNotes?.map((a, index) => (
                        <>
                            <thead>
                                <tr>
                                    <th className="area-header asignatura-title-preescolar" >{a.academicAreaName}</th>
                                    <th>Aspectos a evaluar</th>
                                    {boletin.evaluationScale?.map((a, index) => (
                                    <th>{a.abbreviation}</th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {a.subjectNotes?.map((s, index) => (
                                <tr>
                                    <td rowSpan={getRowSpan(s.processNotes)}> {s.schoolSubjetName}</td>
                                </tr>
                                ))}
                                {a.subjectNotes?.map((s, index) => (
                                     <>
                                        {s.processNotes?.map((p, indexP) => (
                                        <>
                                            <tr>
                                                <td className="title-aspecto-preescolar-tabla-dimensiones" colSpan={boletin.evaluationScale.length+1}><strong>{indexP+1}. {p.evaluationProcessName}</strong></td>
                                            </tr>
                                            {p.achievements?.map((a, indexA) => (
                                                <tr>
                                                    <td>{indexP+1}.{indexA+1}. {a.description}</td>
                                                    {boletin.evaluationScale.map((ev) => {
                                                            if(ev.evaluativeScaleId===a.evaluativeScaleId){
                                                                return(<td><b>X</b></td>)
                                                            }else{
                                                                return(<td></td>)
                                                            }
                                                    })} 
                                                </tr>
                                                ))}
                                        </>
                                        ))}
                                    </>
                                ))}
                            </tbody>
                        </>
                        ))}
                    </table>
                </div>

                <div className="academic-areas escala-valorativa-container promedio-curso-vs-estudiante">
                <table className="table-observaciones">
                    <tbody>
                        <tr>
                            <th>Observaciones Generales del Director de Grupo: </th>
                            <td className="padding-left-10" >{student.observations ||  "  "}</td>
                        </tr>
                    
                    </tbody>
                </table>
            </div>

                <FooterActa
                  creadorId={boletin.tutorId??0}
                  reportTypeId={types.reportTypes.BOLETIN}></FooterActa>
                </div>
                
                </div>
                {/* <FooterActa></FooterActa> */}

                <br />
                    <button className="btn btn-primary center display-block" onClick={() => window.print()}>Imprimir</button>
                <br />

            </>
          )}
        </>
    );
  };
  
  export default RepoBoletinDimension;