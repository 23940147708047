import React, { useState, useEffect, useRef } from 'react';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import Loading from '../Loading';
import discountsService from "../../services/discountsService";
import EstudianteFilter from '../Filters/EstudianteFilter';
import billingConceptService from '../../services/billingConceptService';
import collectionsService from '../../services/collectionsService';
import academicService from '../../services/academicService';
import types from '../../services/types';
import moment from 'moment'

const DescuentoForm = (props) => {
    const { billingDiscountId, onClose, ReadOnly } = props; 
    const initData = {
        billingDiscountId: 0,
        studentId: '0',
        billingConceptId: '0',
        discountTypeId: '0',
        discountValue: '',
        isPermanent: false,
        startDate: '',
        endDate: '',
        isActive: true,
    };

    const [formData, setFormData] = useState(initData);
    const [showLoading, setShowLoading] = useState(false);
    const [schoolYearList, setSchoolYearList] = useState([]);
    const [discountTypeList, setDiscountTypeList] = useState([]);
    const [billingConcepts, setBillingConcepts] = useState([]);
    const [billingConceptList, setBillingConceptList] = useState([]);
    
    const filteReference = useRef(null);
    let concepts = [];

    const updateForm = (data) => {
        setFormData(prevState => ({
            ...prevState,
            ...data
          }));
    }

    const fetchDescuento = async () =>{
        setShowLoading(true);
        try{
            concepts = await billingConceptService.getAllBillingItems();
            setBillingConcepts(concepts);
            setBillingConceptList(concepts);
            const parameters = await collectionsService.getParameters();
            const currentAnio = parameters.find((op) => String(op.keyName) === "AnioElectivo");
            if(currentAnio){
                setFormData((prevState) => ({ ...prevState, schoolYearId:Number(currentAnio.value) }));
                LoadBillingConcepts(currentAnio.value);
            }
            const yearsResp = await academicService.getYears();
            setSchoolYearList(yearsResp);
            const discountTypeRes = await collectionsService.getCollection(types.collections.DISCOUNT_TYPE);
            setDiscountTypeList(discountTypeRes);

            if (billingDiscountId) {            
                    const data = await discountsService.getBillingItemById(billingDiscountId);
                    console.log( moment(data.startDate).format("yyyy-MM-DD"));
                    const conceptItem = concepts.find((e)=> e.billingConceptId===data.billingConceptId);
                    setFormData({ ...data, isPermanent: data.isPermanent===1, isActive: data.isActive===1, schoolYearId:conceptItem.schoolYearId,
                        startDate: moment(data.startDate).format("yyyy-MM-DD"),
                        endDate: moment(data.endDate).format("yyyy-MM-DD"), });
                    filteReference?.current?.setFormValues({studentId:data.studentId});
                    if(!filteReference || !filteReference?.current){
                        setTimeout(()=>{
                            filteReference?.current?.setFormValues({studentId:data.studentId});
                        }, 4000);
                    }
            }
        }catch(error){
            console.error("Error al obtener listas de formulario:", error);
        }
        setShowLoading(false);
    }

    const LoadBillingConcepts = (schoolYearId) => {
        if(schoolYearId !== "0"){
            if(concepts.length > 0){
                const data = concepts.filter((op) => String(op.schoolYearId) === schoolYearId);
                setBillingConcepts(data);
            }else if (billingConceptList.length > 0) {
                const data = billingConceptList.filter((op) => String(op.schoolYearId) === schoolYearId);
                setBillingConcepts(data);
            }
        }else{
            setBillingConcepts(billingConceptList);
        }
        setFormData(prevState => ({
            ...prevState,
            billingConceptId:'0'
        }));
        
    };
    
    useEffect( () => {
        fetchDescuento();
    }, [billingDiscountId]);

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: type === 'checkbox' ? checked : value
        }));
        if(name==='schoolYearId'){
            LoadBillingConcepts(value);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const requiredFields = ['billingConceptId', 'studentId','discountTypeId','discountValue' ];
        for (let field of requiredFields) {
            if (!formData[field] || formData[field]==='0') {
                toast.error("Por favor, completa todos los campos obligatorios.", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                  });
                return;
            }
        }
        setShowLoading(true);
        // Lógica para enviar el formulario
        try {
            let response;
            if (formData.billingDiscountId && formData.billingDiscountId!==0) {
                response = await discountsService.updateBillingItem(formData);
            } else {
                response = await discountsService.addBillingItem(formData);
            }
            if (response) {
                Swal.fire("¡Éxito!", "Descuento guardado con éxito.", "success");
                onClose();
            } else {
                Swal.fire("Error", "Error al guardar el descuento.", "error");
            }
        } catch (error) {
            console.error('Error al enviar el formulario:', error);
            Swal.fire("Error", `Error al guardar el descuento: ${error}`, "error");
        } finally {
            setShowLoading(false);
        }
    };

    return (
    <div className="col-md-12">
        {showLoading && <Loading />}
        {!showLoading && (
            <form onSubmit={handleSubmit} className="d-grid gap-3">
                <div className=" flex-fill">
                    
                    <div className="">
                        <div className="row">
                            <EstudianteFilter
                                callback={updateForm}
                                ref={filteReference}
                                properties={{
                                    className: 'col-md-12 mb-2',
                                    id: 'formBilling'
                                }}
                                ReadOnly={ReadOnly}
                            />

                            <div className="col-12 col-xl-4">
                                <div className="mb-3">
                                    <label htmlFor="schoolYearId" className="form-label">Año escolar:*</label>
                                    <select className="form-control form-select" id="schoolYearId" name="schoolYearId" 
                                        value={formData.schoolYearId} onChange={handleChange} required>
                                        <option value="0" disabled={ReadOnly}>Seleccione</option>
                                        {schoolYearList.map((school) => (
                                            <option key={school.schoolYearId} value={school.schoolYearId} disabled={ReadOnly}>
                                                {school.year}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>

                            <div className="col-12 col-xl-4">
                                <div className="mb-3">
                                    <label htmlFor="billingConceptId" className="form-label">Concepto de Facturación:*</label>
                                    <select className="form-control" name="billingConceptId" value={formData.billingConceptId} onChange={handleChange} readOnly={ReadOnly}>
                                        <option value="0" disabled={ReadOnly}>Seleccione un concepto</option>
                                        {billingConcepts.map((concept) => (
                                            <option key={concept.billingConceptId} value={concept.billingConceptId} disabled={ReadOnly}>
                                                {concept.description}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>

                            <div className="col-12 col-xl-4">
                                <div className="mb-3">
                                    <label htmlFor="discountTypeId" className="form-label">Tipo de descuento:*</label>
                                    <select className="form-control form-select validarForm" name="discountTypeId" value={formData.discountTypeId} onChange={handleChange} readOnly={ReadOnly} required>
                                        <option value="0" disabled={ReadOnly}>Seleccione</option>
                                        {discountTypeList.map((option) => (
                                            <option key={option.id} value={option.id} disabled={ReadOnly}>
                                                {option.text}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>

                            <div className="col-12 col-xl-4">
                                <div className="mb-3">
                                    <label htmlFor="discountValue" className="form-label">Valor del Descuento:*</label>
                                    <input type="number" className="form-control" id="discountValue" name="discountValue" value={formData.discountValue} onChange={handleChange} />
                                </div>
                            </div>

                            <div className="col-12 col-xl-4">
                                <div className="mb-3">
                                    <label htmlFor="startDate" className="form-label">Fecha de Inicio</label>
                                    <input type="date" className="form-control" id="startDate" name="startDate" value={formData.startDate} readOnly={ReadOnly} onChange={handleChange} />
                                </div>
                            </div>

                            <div className="col-12 col-xl-4">
                                <div className="mb-3">
                                    <label htmlFor="endDate" className="form-label">Fecha de Fin</label>
                                    <input type="date" className="form-control" id="endDate" name="endDate" value={formData.endDate} readOnly={ReadOnly} onChange={handleChange} />
                                </div>
                            </div>

                            <div className="col-12 col-xl-4">
                                <div className="form-check">
                                    <input type="checkbox" className="form-check-input" id="isActive" name="isActive" checked={formData.isActive} onChange={handleChange} readOnly={ReadOnly} disabled={ReadOnly} />
                                    <label className="form-check-label" htmlFor="isActive">Activo</label>
                                </div>
                            </div>
                            
                            <div className="col-12 col-xl-4">
                                <div className="form-check">
                                    <input type="checkbox" className="form-check-input" id="isPermanent" name="isPermanent" checked={formData.isPermanent} onChange={handleChange} readOnly={ReadOnly} disabled={ReadOnly} />
                                    <label className="form-check-label" htmlFor="isPermanent">Permanente</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12 text-center mb-3 mt-3">
                        <button type="submit" className="btn btn-primary">Guardar Descuento</button>
                    </div>
                </div>
            </form>
        )}
    </div>
);
};
export default DescuentoForm;
