import React, { useState, useEffect, useRef } from "react";
import "../css/tablaProcesosPreventivo.css";
import preventiveNoteService from "../services/preventiveNoteService";

const TablaEvaluacionPreventiva = (props) => {
  const { students, process, evaluationScale, tablaNotes, paramPlanilla, refreshNotas } = props;
  const [estudiantes, setEstudiantes] = useState([]); // Almacenamos los estudiantes aquí
  const [procesos, setProcesos] = useState([]); // Almacenamos los procesos y sus notas aquí
  const [scale, setScale] = useState([]); // Almacenamos los procesos y sus notas aquí
  const [notasTabla, setNotasTabla] = useState([]); // Almacenamos los procesos y sus notas aquí
  const [parametros, setParametros] = useState([]); // Almacenamos los procesos y sus notas aquí

  useEffect(() => {
    const loadInformation = () => {
        setEstudiantes(students);
        setProcesos(process);
        setScale(evaluationScale);
        setNotasTabla(tablaNotes);
        setParametros(paramPlanilla);
    };
    loadInformation();
  }, []);

  const handleNoteChange = async (proceso,studentId,valor,element) => {
    element.dataset.change = true;
    const e = element.parentNode.parentNode.parentNode;
    const preventiveEvaluateId =  notasTabla[`${studentId}-${proceso}`].preventiveEvaluateId;
    try {
        const evaluation ={
            preventiveEvaluateId:preventiveEvaluateId,
            studentId: studentId,
            teacherId: parametros.teacherId,
            schoolSubjectId: parametros.schoolSubjectId,
            evaluationProcessId: proceso,
            evaluativeScaleId: valor,
            schoolYearId:parametros.schoolYearId,
            periodId:parametros.periodId
        };
        const result = await preventiveNoteService.updatePreventivoNotaAcademico(evaluation);
        if(result && result.preventiveEvaluateId>0){
            e.style.backgroundColor ="#fff299";
            setNotasTabla((prevState) => ({
                ...prevState,
                [`${studentId}-${proceso}`]:{
                      evaluativeScaleId:valor,
                      preventiveEvaluateId: preventiveEvaluateId
                  }
              }));
            if(refreshNotas)refreshNotas();
        }else{
            e.style.backgroundColor = "#ffc7c5";
        }
      } catch (ex) {
        e.style.backgroundColor = "#ffc7c5";
        console.log(ex);
      }
  };

  const guardarPreventivoNota = async (estudiante, proceso, e) => {
    const defaultScale = evaluationScale.find(e=>e.abbreviation==="G");
    const asignedScale = (defaultScale)?defaultScale:evaluationScale[0];
    try {
        const newEvaluation ={
            studentId: estudiante.studentId,
            teacherId: parametros.teacherId,
            schoolSubjectId: parametros.schoolSubjectId,
            evaluationProcessId: proceso.evaluationProcessId,
            evaluativeScaleId: asignedScale.evaluativeScaleId,
            schoolYearId:parametros.schoolYearId,
            periodId:parametros.periodId
        };
        const result = await preventiveNoteService.createPreventivoNotaAcademico(newEvaluation);
        if(result && result.preventiveEvaluateId>0){
            e.style.backgroundColor ="#cdfdc1";
            setNotasTabla((prevState) => ({
                ...prevState,
                [`${estudiante.studentId}-${proceso.evaluationProcessId}`]:{
                    evaluativeScaleId:result.evaluativeScaleId,
                    preventiveEvaluateId: result.preventiveEvaluateId
                }
              }));
              if(refreshNotas)refreshNotas();
        }else{
            e.style.backgroundColor = "#ffc7c5";
        }
      } catch (ex) {
        e.style.backgroundColor = "#ffc7c5";
        console.log(ex);
      }
  }

  const eliminarPreventivoNota = async (evaluation, estudiante, proceso, e) => {
    try {
        await preventiveNoteService.deletePreventivoNotasAcademicoById(evaluation.preventiveEvaluateId);
        e.style.backgroundColor = "#fff299";
        setNotasTabla((prevState) => ({
            ...prevState,
            [`${estudiante.studentId}-${proceso.evaluationProcessId}`]:""
          }));
          if(refreshNotas)refreshNotas();
      } catch (ex) {
        e.style.backgroundColor = "#ffc7c5";
        console.log(ex);
      }
  }

  const handleEneableNote = async (evaluation,estudiante, proceso, element ) => {
    const e = element.parentNode.parentNode.parentNode.parentNode;
    if(!evaluation || evaluation===""){
        await guardarPreventivoNota(estudiante,proceso, e);
    }else{
        await eliminarPreventivoNota(evaluation,estudiante, proceso, e);
    }
  }

  const handleEneableAllNote = async (e, proceso) =>{
    const checked = e.target.checked;
    for(let estudiante of estudiantes){
        const evaluation = notasTabla[`${estudiante.studentId}-${proceso.evaluationProcessId}`];
        const chk = document.getElementById("chk-"+estudiante.studentId+"-"+proceso.evaluationProcessId);
        const celda = chk.parentNode.parentNode.parentNode.parentNode;
        if(checked && evaluation===""){
            await guardarPreventivoNota(estudiante,proceso, celda);
        }else{
            if(!checked && evaluation!==""){
                await eliminarPreventivoNota(evaluation, estudiante,proceso, celda);
            }   
        }
    }
  }

  const getPreventivoEstudiante = (estudiante, proceso) => {

    const evlatuation = notasTabla[`${estudiante.studentId}-${proceso.evaluationProcessId}`];
    return(
        <div className="row">
            <div className="col-md-2">
                <div class="form-check form-switch">
                    <input class="form-check-input" type="checkbox" id={"chk-"+estudiante.studentId+"-"+proceso.evaluationProcessId} 
                    checked={(evlatuation && evlatuation!=="")} 
                    onChange={(e)=> handleEneableNote(evlatuation,estudiante, proceso, e.target) } />
                    <label class="form-check-label" for={"chk-"+estudiante.studentId+"-"+proceso.evaluationProcessId}></label>
                </div>
            </div>
            {notasTabla[`${estudiante.studentId}-${proceso.evaluationProcessId}`] && 
            notasTabla[`${estudiante.studentId}-${proceso.evaluationProcessId}`]!=="" && (
            <div className="col-md-10">
                    <select className="form-control form-select" 
                    id={"cmbEvaluation-"+estudiante.studentId} 
                    name={"cmbEvaluation-"+estudiante.studentId}
                    value={notasTabla[`${estudiante.studentId}-${proceso.evaluationProcessId}`]?.evaluativeScaleId}
                    onChange={(e) =>handleNoteChange(proceso.evaluationProcessId,estudiante.studentId,e.target.value,e.target)}
                    >
                        {scale.map((sc) => (
                            <option key={sc.evaluativeScaleId} value={sc.evaluativeScaleId}>
                                {sc.name}
                            </option>
                        ))}
                    </select>
            </div>
            )}
        </div>
    );
  }

  // Aquí renderizamos el componente
  return (
    <div className="table-responsive">
    <table className="table-prevent striped bordered hover responsive table-notas">
       <thead className="thead-prevent table-notas-head">
  <tr className="tr-table-prevent">
    <th rowspan="3" className="th-table-prevent celda-estudiante">
      Estudiante
    </th>
    <th colSpan={procesos.length} className="th-table-prevent text-center">
      Procesos
    </th>
  </tr>
  <tr className="tr-table-prevent">
    {procesos.map((proceso, index) => (
      <th className="th-table-prevent" key={proceso.evaluationProcessId}>
        <div className="d-flex justify-content-between align-items-center">
          <div className="form-check form-switch pe-2">
            <input
              className="form-check-input"
              type="checkbox"
              id={"chk-" + proceso.evaluationProcessId}
              onChange={(e) => { handleEneableAllNote(e, proceso); }}
            />
            <label className="form-check-label" htmlFor={"chk-" + proceso.evaluationProcessId}></label>
          </div>
          <div>
            <span
              data-tooltip-id="my-tooltip"
              data-tooltip-content={proceso.description}
              style={{ cursor: 'pointer' }}
            >
              Proceso {index + 1}
            </span>
          </div>
        </div>
      </th>
    ))}
  </tr>
</thead>

        <tbody className="tbody-table-prevent">
                {(
                    estudiantes.map((estudiante, indexEst) => (
                    <tr className="tr-table-prevent" key={indexEst}>
                        <td className="td-table-prevent">{estudiante.fullName}</td>
                        {procesos.map((proceso, indexProc) => (
                        <React.Fragment key={indexProc}>

                            <td className="td-table-prevent" key={indexProc}>
                                {getPreventivoEstudiante(estudiante, proceso)}
                            </td>

                        </React.Fragment>
                        ))}
                    </tr>
                    ))
                )}
                  </tbody>
   
  </table>
  </div>
  );
};

export default TablaEvaluacionPreventiva;
