
import { useRef, useState } from "react";
import HeaderActa from "../../../components/headerActas";
import FooterActa from "../../../components/FooterActa";
import moment from "moment";
import utils from "../../../utils/generalFunctions";
import types from "../../../services/types";


const RepoBoletin = ({ boletin }) => {
    const filteReference = useRef(null);
    const [showLoading, setShowLoading] = useState(false);
   
    return (
        <>
          {!showLoading && boletin && (

            <>
                {/* Cabezera   */}

                <div class="card acta-container">
                
                {/* <!-- Cabezera --> */}
                <div className="card-body">
                <HeaderActa />
                {/* <!-- Ficha de Estudiante --> */}
                <section className="incidencia-info-boletin">
                    <div className="info-header-boletin">
                        <h5 className="section-title-boletin">INFORME FINAL</h5>
                    </div>
                    <table className="datos-incidencia-table table table-sm my-2">
                        <tbody>
                            <tr>
                                <th className="info-title-boletin">Estudiante</th>
                                <td className="info-content-boletin">{boletin.student.fullName}</td>
                                <th className="info-title-boletin">Periodo</th>
                                <td className="info-content-boletin">Promocional</td>
                                
                            </tr>
                            <tr>
                                <th className="info-title-boletin">Grado</th>
                                <td className="info-content-boletin">{boletin.student.grade}</td>
                                <th className="info-title-boletin">Año</th>
                                <td className="info-content-boletin">{boletin.student.schoolYear}</td>
                            </tr>
                            <tr>
                            <th className="info-title-boletin">Tutor</th>
                                <td className="info-content-boletin">{boletin.goupDirector?.teacherName}</td>
                                <th className="info-title-boletin">Fecha impresión</th>
                                <td className="info-content-boletin">{moment().format("DD-MM-yyyy hh:mm")}</td>
                            </tr>
                            
                        </tbody>
                    </table>
                </section>

                
                
                {/* <!-- Sección de Escala Valorativa --> */}
                <section className="escala-valorativa-container">
                    <div className="escala-valorativa-header">
                        <h5 className="section-title escala-valorativa-title">ESCALA VALORATIVA</h5>
                    </div>
                    <div className="escala-valorativa-body">
                        {boletin.evaluativeScale?.map((a, index) => (
                        <div key={index} className="escala-item">
                            {a.name} = {a.abbreviation} ({a.minScore.toFixed(2)} - {a.maxScore.toFixed(2)})
                        </div>
                        ))}
                    </div>
                    </section>
                                    
                

                
                {/* <!-- Aquí podrías añadir más secciones del informe como el historial académico, asistencia, etc. -->
                <!-- Sección de Áreas Académicas --> */}
                <div class="academic-areas escala-valorativa-container">
                    <table class="table-custom-boletin">
                    {boletin.areaResume?.map((a, index) => (
                        <>
                            <thead>
                                <tr>
                                    <th class="area-header" colspan="4">{a.academicName} (100%)</th>
                                    <th>IHS</th>
                                    <th>Inas</th>
                                    <th>Escala</th>
                                </tr>
                            </thead>
                            <tbody>
                                {a.subjectsResumen?.map((s, index) => (
                                <tr>
                                    <td colspan="4">{s.subjectName} ({s.percentage}%)</td>
                                    <td>{s.hourlyIntensity}</td>
                                    <td>{s.nonAttendance}</td>
                                    <td>{s.noteScale}</td>
                                </tr>
                                ))}
                            </tbody>
                        </>
                        ))}
                    </table>
                </div>
                
                <section class="historico-periodos">
                    <h5 class="historico-title">Histórico de Periodos</h5>
                    <table class="table-historico datatables-reponsive">
                        <thead>
                            <tr>
                                <th rowspan="2">Periodo</th>
                                <th colspan={boletin.subjectsResumen.length}>Asignaturas (Académicas)</th>
                                <th rowspan="2">Promedio</th>
                            </tr>
                            <tr>
                            {boletin.subjectsResumen?.map((sub, index) => (
                                <th>{sub.subjectAbbr}</th>
                            ))}
                            </tr>
                        </thead>
                        <tbody>
                            {boletin.detailsResumen?.map((d, index) => (
                               <tr>
                                    <td>{d.periodo}</td>
                                    {d.subjectsResumen?.map((asig, index) => (
                                        <>
                                        <td>{asig.noteScale} 
                                        
                                            {/* {asig && asig.recuperationNote && asig.recuperationNote > 0 && asig.recuperationNote !== "0" && (
                                                <>
                                                    <br /><span class="recuperacion">R: {asig.recuperationNote}</span>
                                                </>
                                            )} */}
                                        </td>
                                        
                                        </>
                                    ))}
                                    <td>{d.periodNote}</td>
                                    {/* <!-- Datos para el periodo 1 --> */}
                                </tr>
                            ))}
                            {/* <!-- Filas de datos para cada periodo --> */}
                        </tbody>
                    </table>
                    {/* <div class="estado-promocion">Estado de Promoción: Promovido</div> */}
                </section>
                <FooterActa
                  creadorId={boletin.goupDirector?.teacherId??0}
                  reportTypeId={types.reportTypes.BOLETIN}></FooterActa>
                </div>
                
                </div>
                {/* <FooterActa></FooterActa> */}

                <br />
                    <button className="btn btn-primary center display-block" onClick={() => window.print()}>Imprimir</button>
                <br />

            </>
          )}
        </>
    );
  };
  
  export default RepoBoletin;