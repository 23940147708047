import React, { useEffect, useRef, useState } from "react";
import Cardacademy from "../components/CategoryCard.js";
import estudiantesService from "../services/estudiantesService.js";
import gradesService from "../services/gradesService.js";
import Chart from "chart.js/auto";
import userProfile from "../img/user-profile.png";
import moment from "moment";
import 'moment/locale/es'; // Importa el locale en español

moment.locale('es'); // Configura moment para usar el idioma español

const Dashboard = () => {
  const chartRef = useRef(null);
  let myChart = null;
  const [gradesData, setGradesData] = useState([]);
  const [birthdayStudents, setBirthdayStudents] = useState([]);

  const fetchGradesData = async () => {
    try {
      const grades = await gradesService.getGradesWithStudentAmount();
      const data = await Promise.all(
        grades.map(async (grade) => {
          return {
            grade: grade.name,
            count: grade.studentAmount,
          };
        })
      );
      setGradesData(data);
    } catch (error) {
      console.error("Error fetching grades data:", error);
    }
  };

  useEffect(() => {
    fetchGradesData();
    fetchBirthDayStudents();
  }, []);

  const fetchBirthDayStudents = async () => {
    try {
      const result = await estudiantesService.getEstudiantesCumpleanios();
      if (result && result.length > 0) {
        setBirthdayStudents(result);
      } else {
        setBirthdayStudents([]);
      }
    } catch (error) {
      console.error("Error fetching students data:", error);
    }
  };

  useEffect(() => {
    if (myChart) {
      myChart.destroy();
    }
    const myChartRef = chartRef.current.getContext("2d");
    const gradient = myChartRef.createLinearGradient(0, 0, 0, 400);
    gradient.addColorStop(0, "#DCF2F1"); // Empieza con este color en la parte superior
    gradient.addColorStop(0.33, "#7FC7D9"); // A un tercio del camino, cambia a este color
    gradient.addColorStop(0.66, "#365486"); // A dos tercios del camino, cambia a este color
    gradient.addColorStop(1, "#0F1035"); // Termina con este color en la parte inferior

    myChart = new Chart(myChartRef, {
      type: "bar",
      data: {
        labels: gradesData.map((data) => data.grade),
        datasets: [
          {
            label: "Estudiantes por Grado",
            backgroundColor: gradient,
            data: gradesData.map((data) => data.count),
          },
        ],
      },
      options: {
        scales: {
          y: {
            beginAtZero: true,
          },
        },
      },
    });
    return () => {
      if (myChart) {
        myChart.destroy();
      }
    };
  }, [gradesData]);

  const chartContainerStyle = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    flexWrap: "wrap",
    width: "100%",
    padding: "10px",
  };

  const getBadge = (date) => {
    const today = moment();
    const birthDate = moment(date, 'DD [de] MMMM [de] YYYY'); // Usa el formato correcto para interpretar la fecha en español

    if (birthDate.isSame(today, 'day')) {
      return <div className="badge badge-subtle-danger me-2 sm">Today</div>;
    } else if (birthDate.isSame(today.clone().subtract(1, 'days'), 'day')) {
      return <div className="badge badge-subtle-info me-2">HB</div>;
    } else if (birthDate.isSame(today.clone().add(1, 'days'), 'day')) {
      return <div className="badge badge-subtle-warning me-2">Tomorrow</div>;
    }
    return null;
  };

  return (
    <div className="">
      <h1>Dashboard</h1>

      <div className="row">
        <Cardacademy
          title="Descuentos"
          icon="fas fa-percent"
          link="/cartera/descuentos"
          className="teachers-card"
        />
        <Cardacademy
          title="Recibos de pagos"
          icon="fas fa-receipt"
          link="/cartera/facturacion"
          className="calendar-card"
        />
        <Cardacademy
          title="Estado de Cuentas"
          icon="fa-solid fa-circle-dollar-to-slot"
          link="/cartera/estadodecuentas"
          className="students-card"
        />
        <Cardacademy
          title="Matrículas"
          icon="fas fa-address-book"
          link="/matriculas"
          className="grades-card"
        />
        <Cardacademy
          title="Conceptos de pago"
          icon="fas fa-cash-register"
          link="/cartera/conceptos"
          className="subjects-card"
        />
      </div>
      <div className="row">
        <div className="col-12 col-lg-8 d-flex">
          <div className="card flex-fill ">
            {/* Asegúrate de que la tarjeta se expanda completamente con flex-fill */}
            <div className="card-header">
              <h5 className="card-title">Estudiantes por Grado</h5>
              <h6 className="card-subtitle text-muted">
                Muestra la cantidad de estudiantes matriculados por cada grado.
              </h6>
            </div>
            <div className="card-body">
              <div className="chart" style={{ width: '100%', height: '180px' }}>
                {/* Ajusta el contenedor del gráfico para que ocupe todo el ancho disponible */}
                <canvas
                  id="chartjs-bar"
                  ref={chartRef}
                  style={{ display: 'block', width: '100%', height: '120px' }}
                />
              </div>
            </div>
          </div>
        </div>

        {/* Añadiendo la lista de cumpleaños de estudiantes */}
        <div className="col-12 col-lg-4 d-flex">
          <div className="card flex-fill w-100">
            <div className="card-header">
              <span className="badge bg-info float-end">{moment().format('MMMM')}</span>
              <h5 className="card-title mb-0">Cumpleaños de estudiantes</h5>
            </div>
            <div className="card-body" style={{ maxHeight: '300px', overflowY: 'auto' }}>
              {birthdayStudents.map((student, index) => (
                <div key={index}>
                  <div className="d-flex align-items-start">
                    <img
                      src={(student && student.urlImage) ? `${student.urlImage}` : userProfile}
                      width="36"
                      height="36"
                      className="rounded-circle me-2"
                      alt={student.fullName}
                    />
                    <div className="flex-grow-1">
                      {getBadge(student.currentBirthDate)}
                      <h6>{student.fullName}</h6>
                      <ol className="breadcrumb">
                        <li className="breadcrumb-item active"><small>{student.grade}</small></li>
                        <li className="breadcrumb-item active"><small className="text-muted">{student.currentBirthDate}</small></li>
                      </ol>
                    </div>
                    {index < birthdayStudents.length - 1 } <hr></hr>

                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
