import api from './api';

const getLogroNotasAcademicos = async () => {
    try {
        const response = await api.get(`academic/achievement-note`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

const getLogroNotasCourse = async (courseId, schoolSubjectId, schoolYearId, academicPeriodId) => {
    try {
        const response = await api.get(`academic/achievement-note-course?courseId=${courseId}&schoolSubjectId=${schoolSubjectId}&schoolYearId=${schoolYearId}&academicPeriodId=${academicPeriodId}`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

const createLogroNotaAcademico = async (periodoData) => {
    try {
        const response = await api.post(`academic/achievement-note`, periodoData);
        return response.data;
    } catch (error) {
        throw error;
    }
};

const updateLogroNotaAcademico = async (periodoData) => {
    try {
        const response = await api.put(`academic/achievement-note`, periodoData);
        return response.data;
    } catch (error) {
        throw error;
    }
};

const getLogroNotaAcademico = async (id) => {
    try {
        const response = await api.get(`academic/achievement-note/${id}`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

const deleteLogroNotasAcademicoById = async (id) => {
    try {
        const response = await api.delete(`academic/achievement-note/${id}`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export default {
    getLogroNotasAcademicos,
    createLogroNotaAcademico,
    updateLogroNotaAcademico,
    getLogroNotaAcademico,
    deleteLogroNotasAcademicoById,
    getLogroNotasCourse
};