import api from './api';

const getUserInformation= async () => {
    try {
        const response = await api.get(`/user/generalInfo`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

const updateUserInformation = async (information) => {
    try {
        const response = await api.put(`/user/generalInfo`, information);
        return response.data;
    } catch (error) {
        throw error;
    }
};

const updateUserPassword = async (information) => {
    try {
        const response = await api.put(`/user/change-password`, information);
        return response;
    } catch (error) {
        throw error;
    }
};

const updateUserPasswordAdmin = async (information) => {
    try {
        const response = await api.put(`/user/change-password-admin`, information);
        return response;
    } catch (error) {
        throw error;
    }
};

const subirImagenUsuario = async (image, userId) => {
    try {
        const formDataToSend = new FormData();
        formDataToSend.append('profilePicture', image);   
        formDataToSend.append('userId', userId);   
        const response = await api.post('/user/image-upload', formDataToSend, {
            headers: { 'Content-Type': 'multipart/form-data' }
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};

const subirImagenUsuarioAdmin = async (image, userId) => {
    try {
        const formDataToSend = new FormData();
        formDataToSend.append('profilePicture', image);   
        formDataToSend.append('userId', userId);   
        const response = await api.post('/user/image-upload-admin', formDataToSend, {
            headers: { 'Content-Type': 'multipart/form-data' }
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};

const getUsersByAccount = async () => {
    try {
        const response = await api.get(`/user/by-account`);
        return response;
    } catch (error) {
        throw error;
    }
};

const getAllUsers = async () => {
    try {
        const response = await api.get(`/user/`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

const getUserById = async (userId) => {
    try {
        const response = await api.get(`/user/by-id/${userId}`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

const getUserTypes = async () => {
    try {
        const response = await api.get(`/user/types`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

const getUserToken = async (idUser) => {
    try {
        const response = await api.get(`/user/changeToken?userId=${idUser}`);
        return response;
    } catch (error) {
        throw error;
    }
};

const createUser = async (userData) => {
    try {
        const response = await api.post('/user/new', userData);
        return response.data;
    } catch (error) {
        throw error;
    }
};

const updateUser = async (userData) => {
    try {
        const response = await api.put('/user/update', userData);
        return response.data;
    } catch (error) {
        throw error;
    }
};

const deleteUserById = async (id) => {
    try {
        const response = await api.delete(`/user/delete/${id}`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export default {
    updateUserInformation,
    subirImagenUsuario,
    subirImagenUsuarioAdmin,
    updateUserPassword,
    updateUserPasswordAdmin,
    getUserInformation,
    getUsersByAccount,
    getUserToken,
    getAllUsers,
    getUserById,
    getUserTypes,
    createUser,
    updateUser,
    deleteUserById
};