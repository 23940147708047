import React from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

const Cardacademy = ({ title, icon, link, className, count }) => {

    
    const showMensajeInfo = () => {
        toast.info("Estamos en procesos de finalización  de periodo", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
    }

    return (
        <div className="col-12 col-md-6 col-xl-auto flex-grow-1 d-flex">
        
        <Link to={link} className={`cardcate-card card-body ${className}`}>
            <div className='stat'><i className={`fas ${icon} cardcate-icon feather feather-dollar-sign align-middle text-primary `}></i></div>
            <h3 className="cardcate-title text-muted">{title}</h3>
        </Link>
        </div>
    );
};

export default Cardacademy;
