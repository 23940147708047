import api from './api';

const getAcademicOrganizations = async () => {
    try {
        const response = await api.get(`/academic/setting`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

const getAcademicOrganizationsWithCoordinators = async () => {
    try {
        const response = await api.get(`/academic/setting-all`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

const getAcademicOrganizationsByCourse = async (courseId, schoolYearId) => {
    try {
        const response = await api.get(`/academic/setting-by-course?courseId=${courseId}&schoolYearId=${schoolYearId}`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

const createAcademicOrganization = async (organizationData) => {
    try {
        const response = await api.post(`/academic/setting`, organizationData);
        return response.data;
    } catch (error) {
        throw error;
    }
};

const getAcademicOrganization = async (id) => {
    try {
        const response = await api.get(`/academic/setting/${id}`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

const deleteAcademicOrganizationById = async (id) => {
    try {
        const response = await api.delete(`/academic/setting/${id}`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export default {
    getAcademicOrganizations,
    createAcademicOrganization,
    getAcademicOrganization,
    deleteAcademicOrganizationById,
    getAcademicOrganizationsWithCoordinators,
    getAcademicOrganizationsByCourse
};
