import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import studentPhoto from '../../img/user-profile.png';
import {
    MDBBtn,
    MDBModal,
    MDBModalDialog,
    MDBModalContent,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBModalFooter,
} from 'mdb-react-ui-kit';
import Swal from "sweetalert2";
import useCustomDataTableStyles from "../../components/estilosDeDataTable";
import DashboardCard from "../../components/TituloDashboard";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp, faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import billingService from "../../services/billingService";
import util from '../../utils/generalFunctions';
import moment from "moment";

const PagosEstudiantepc = () => {
    const studentInfo = util.getStudentInformation();
    const [pagos, setPagos] = useState([]);
    const [obligaciones, setObligaciones] = useState([]);
    const [showObligaciones, setShowObligaciones] = useState(true); // Estado para controlar la visibilidad


    // Función para alternar la visibilidad de las obligaciones
    const toggleObligaciones = () => {
        setShowObligaciones(!showObligaciones);
    };

     // CSS para el giro del icono
     const iconStyles = {
      transform: showObligaciones ? 'rotate(360deg)' : 'rotate(0deg)',
      transition: 'transform 0.3s ease' // Suaviza la transición del giro
  };

  
  useEffect(() => {
    fetchEstadoDeCuenta(); // Carga inicial de datos
}, []);

    // ... Resto de la lógica para manejar modales, agregar, editar, eliminar pagos, etc.
    const fetchEstadoDeCuenta = async () => {
        try {
            const courseId = studentInfo.courseId;
            const schoolYearId = studentInfo.schoolYearId;
            const educationLevelId = studentInfo.educationLevelId;
            const studentId = studentInfo.studentId;
            const journeyId = studentInfo.journeyId;
            // Asumiendo que tu servicio acepta un objeto de filtros
            const response = await billingService.getEstadoDeCuentas(studentId,  journeyId, schoolYearId, educationLevelId, courseId);
            setObligaciones(response.statusAccount);
            setPagos(response.payments);
            console.log(response);
        } catch (error) {
            console.error("Error al obtener los descuentos:", error);
        }
    };

 
    // Definir las columnas para DataTable
    //format: row => moment(row.endDate).format('DD-MM-YYYY')
    const columnsObligaciones = [
        { name: "Fecha", sortable: true,
        cell: (row) => (
        <span>
            {moment( moment(new Date()).format('yyyy') +"-"+ row.numero + "-01" ).format('YYYY-MM-DD')}
        </span>),
        },//
        { name: "Concepto", selector: "billingConceptName", sortable: true },
        { name: "Monto a pagar", selector: "deudaTotal", sortable: true,
            cell: (row) => (
                <span>
                    {util.formatNumber.new(row.deudaTotal, "$")}
                </span>)
        },
        { name: "Obligación", selector: "valuePaidWithDiscount", sortable: true,
            cell: (row) => (
                <span>
                    {util.formatNumber.new(row.valuePaidWithDiscount, "$")}
                </span>) 
        },
        { name: "Interés", selector: "interes", sortable: true, cell: (row) => (
            <span>
                {util.formatNumber.new(row.mora, "$")}
            </span>) 
        },
        { name: "Estado", sortable: true, cell: (row) => (
            <span>
                {(row.deudaTotal>0)?(<span className="badge bg-warning">Pendiente</span>):(<span className="badge bg-success">Pagado</span>)}
            </span>) 
        },

      ];

      const columnsPagos = [
        { name: "Fecha", selector: "billingDate", sortable: true },
        { name: "Concepto", selector: "billingConceptName", sortable: true },
        { name: "Monto Pagado", selector: "total", sortable: true , cell: (row) => (
            <span>
                {util.formatNumber.new(row.total, "$")}
            </span>) 
        },
      ];


      const filteredItems = pagos.filter(item => {
        // Filtrado de pagos
    });

    const customStyles = useCustomDataTableStyles();

    return (
      <div className="">
      {/* Encabezado y navegación */}
      <div className="">
      <Link to="/dashboard" className="back-to-dashboard-pc">
                <FontAwesomeIcon icon={faArrowLeft} />
            </Link> 
            <DashboardCard
        studentPhoto={studentPhoto}
          title="Historial de pagos"
          subtitle="por concepto"
       
        />

      </div>

      {/* Botón para mostrar/ocultar obligaciones */}
      <div className="text-end mb-3">
        <div className="text-start float-inline-start">
                <a href="https://www.psepagos.co/PSEHostingUI/ShowTicketOffice.aspx?ID=8547" target="blank"
                className="btn btn-success me-2">Pagar por PSE</a>

                <a href="https://checkout.wompi.co/l/9blBnm" target="blank"
                className="btn btn-twitter me-2">Pagar por tarjeta de credito</a>
        </div>

          <button onClick={() => setShowObligaciones(!showObligaciones)} className="btn btn-primary">
              {showObligaciones ? <FontAwesomeIcon icon={faAngleUp} /> : <FontAwesomeIcon icon={faAngleDown} />}
              {' '}Obligaciones de Pago
          </button>
      </div>

      {/* Sección de Obligaciones */}
      <div className={`collapse ${showObligaciones ? 'show' : ''}`}>
          <DataTable
              title="Obligaciones de Pago"
              columns={columnsObligaciones}
              data={obligaciones}
              customStyles={customStyles}
              pagination
              fixedHeader
              fixedHeaderScrollHeight="350px"
          />
      </div>
      <br/>

      {/* Sección de Historial de Pagos */}
      <DataTable
          title="Historial de Pagos"
          columns={columnsPagos}
          data={pagos}
          customStyles={customStyles}
          pagination
          fixedHeader
          fixedHeaderScrollHeight="450px"
      />
  </div>
);
};
export default PagosEstudiantepc;

