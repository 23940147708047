import React, { useRef, useState } from 'react';
import DataTable from 'react-data-table-component'; // Importa el componente DataTable
import useCustomLightDataTableStyles from '../../components/estilosDeDataTable';
import JornadaAnioPeriodoNivelCurso from '../../components/Filters/JornadaAnioPeriodoNivelCurso';
import { Button } from 'react-bootstrap';
import Loading from '../../components/Loading';
import academicService from '../../services/academicService';
import { toast } from 'react-toastify';
import utils from '../../utils/generalFunctions';
import estudiantesService from '../../services/estudiantesService';
import reportsService from '../../services/reportsService';

const DesactivarReportesEstudiantes = () => {
  const filteReference = useRef(null);
    const initData ={
        schoolYearId: "0",
        journeyId:"0",
        periodId:"",
        educationLevelId: "0",
        reportTypeId:"0"
    };
  const [formData, setFormData] = useState(initData);
  const [periodos, setPeriodosList] = useState([]);
  const [showLoading, setShowLoading] = useState(false);
  const [checkAll, setCheckAll] = useState(false);
  const customStyles = useCustomLightDataTableStyles();
  const [estudiantes, setEstudiantes] = useState([]);
  const [filterText, setFilterText] = useState('');

  const updateForm = async (data) => {
    if (data.schoolYearId!==formData.schoolYearId){   
      await cargarPeriodos(data.schoolYearId);
    }
    setFormData(prevState => ({
        ...prevState,
        ...data
      }));
  }

  const updateCustomForm = async (e) => {
    const { name, value } = e.target;
  setFormData(prevState => ({
      ...prevState,
      [name]: value
  }));
  }

  const cargarPeriodos = async (schoolYearId) => {
    try {
        const schoolYear = await academicService.getYearById(schoolYearId);              
        if(schoolYear){
            setPeriodosList(schoolYear.periods)
        }            
    } catch (error) {
         console.error("Error al obtener información de año escolar:", error);
    }
  }

  const cargarPermisosEstudiantes = async (e) =>{
      if(e) e.preventDefault();
      const requiredFields = ['schoolYearId', 'journeyId', 'periodId','educationLevelId','courseId','reportTypeId'];
      for (let field of requiredFields) {
          if (!formData[field] || formData[field]==='0') {
              toast.error("Por favor, completa todos los campos obligatorios.", {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light",
                });
              return;
          }
      }
      setShowLoading(true);
      try {
         const copyData = utils.copy(formData);
         copyData.periodName = periodos.find(p=>String(p.academicPeriodId)===String(copyData.periodId))?.periodName??"";
         const years = filteReference.current?.getSchoolYears();
         if(years){
              copyData.year = years.find(p=>String(p.schoolYearId)===String(copyData.schoolYearId))?.year??"";
         }
         const journeys = filteReference.current?.getJourneys();
         if(journeys){
              copyData.journey = journeys.find(p=>String(p.journeyId)===String(copyData.journeyId))?.name??"";
         }

         const courses = filteReference.current?.getCursos();
         if(courses){
              copyData.course = courses.find(p=>String(p.courseId)===copyData.courseId)?.name??"";
         }

         const educationLevels = filteReference?.current?.getEducationLeves();
         if (educationLevels.length > 0) {
              copyData.evaluationTypeId = educationLevels.find((c) => String(c.educationLevelId) === String(copyData.educationLevelId))?.evaluationTypeId;
         }

         const prevData = await estudiantesService.getEstudiantesPorCurso(
          "",
          copyData.journeyId,
          copyData.schoolYearId,
          copyData.educationLevelId,
          copyData.courseId
        ); // Obtenemos los estudiantes de tu sistema
        const data = prevData.filter(s=>s.isActive);
        const permission = await reportsService.getPermisosEstudiantesPorCurso(
          copyData.periodId,
          copyData.schoolYearId,
          copyData.courseId,
          copyData.reportTypeId
        );
        
        if(data && data.length>0){
          const estudiatesResult = data.map((m) => {
            const per = permission.find(p=> p.studentId === m.studentId);
            m.reportsEnabled = (per)?(per.visible??false):false; 
            m.reportPermisionId = (per)?per.reportPermisionId:null;
            return m;
          });
          const checkForAll=estudiatesResult.find(e=> e.reportsEnabled === false);
          setCheckAll(!(checkForAll!=null));
          setEstudiantes(estudiatesResult);
        }else{
          toast.error("No se encontraron estudiantes matriculados.", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
            setEstudiantes([]); 
        }
      } catch (error) {
        console.error("Error al obtener asistencias:", error);
      }
      setShowLoading(false);
  }
//

  const toggleCheckAll = async (checked) => {

    const copyData = utils.copy(formData);
    copyData.periodName = periodos.find(p=>String(p.academicPeriodId)===String(copyData.periodId))?.periodName??"";
    const years = filteReference.current?.getSchoolYears();
    if(years){
         copyData.year = years.find(p=>String(p.schoolYearId)===String(copyData.schoolYearId))?.year??"";
    }
    const journeys = filteReference.current?.getJourneys();
    if(journeys){
         copyData.journey = journeys.find(p=>String(p.journeyId)===String(copyData.journeyId))?.name??"";
    }

    const courses = filteReference.current?.getCursos();
    if(courses){
         copyData.course = courses.find(p=>String(p.courseId)===copyData.courseId)?.name??"";
    }

    const educationLevels = filteReference?.current?.getEducationLeves();
    if (educationLevels.length > 0) {
         copyData.evaluationTypeId = educationLevels.find((c) => String(c.educationLevelId) === String(copyData.educationLevelId))?.evaluationTypeId;
    }

    setCheckAll(checked);
    const newEstudiantes = utils.copy(estudiantes);
    for(let es of newEstudiantes){
      const permission = {
        reportPermisionId:(es.reportPermisionId !== null && !isNaN(parseFloat(es.reportPermisionId)))?es.reportPermisionId:null,
        studentId:es.studentId,
        schoolYearId:copyData.schoolYearId,
        academicPeriodId:copyData.periodId,
        reportTypeId:copyData.reportTypeId,
        visible: checked
      };
      let result = null;
      if(permission.reportPermisionId==null){
        result = await reportsService.createReportPermission(permission);
      }else{
        result = await reportsService.updateReportPermission(permission);
      }

      if(result!=null && !isNaN(parseFloat(result.reportPermisionId))){
        es.reportPermisionId = result.reportPermisionId;
        es.reportsEnabled = checked;
        setEstudiantes(newEstudiantes.map(estudiante => {
          if (estudiante.studentId === es.studentId) {
            return { ...estudiante, reportsEnabled: result.visible, reportPermisionId:result.reportPermisionId };
          }
          return estudiante;
        }));
      }
    }
  };

  const toggleReporte = async (studentId) => {
    
    const copyData = utils.copy(formData);
    copyData.periodName = periodos.find(p=>String(p.academicPeriodId)===String(copyData.periodId))?.periodName??"";
    const years = filteReference.current?.getSchoolYears();
    if(years){
         copyData.year = years.find(p=>String(p.schoolYearId)===String(copyData.schoolYearId))?.year??"";
    }
    const journeys = filteReference.current?.getJourneys();
    if(journeys){
         copyData.journey = journeys.find(p=>String(p.journeyId)===String(copyData.journeyId))?.name??"";
    }

    const courses = filteReference.current?.getCursos();
    if(courses){
         copyData.course = courses.find(p=>String(p.courseId)===copyData.courseId)?.name??"";
    }

    const educationLevels = filteReference?.current?.getEducationLeves();
    if (educationLevels.length > 0) {
         copyData.evaluationTypeId = educationLevels.find((c) => String(c.educationLevelId) === String(copyData.educationLevelId))?.evaluationTypeId;
    }

    const per = estudiantes.find(p=> p.studentId === studentId);
    const permission = {
      reportPermisionId:(per)?per.reportPermisionId:null,
      studentId:studentId,
      schoolYearId:copyData.schoolYearId,
      academicPeriodId:copyData.periodId,
      reportTypeId:copyData.reportTypeId,
      visible: !per.reportsEnabled
    };
    let result = null;
    if(permission.reportPermisionId==null){
      result = await reportsService.createReportPermission(permission);
    }else{
      result = await reportsService.updateReportPermission(permission);
    }
    if(result!=null){
      setEstudiantes(estudiantes.map(estudiante => {
        if (estudiante.studentId === studentId) {
          return { ...estudiante, reportsEnabled: result.visible, reportPermisionId:result.reportPermisionId };
        }
        return estudiante;
      }));
    }
  };

  const columns = [
    { name: "Número", selector: "consecutive", sortable: true, 
    cell: (row, index) => <span>{index + 1}</span>, },      
    { name: "ID", selector: "identification", sortable:true,grow:"2.5",},
    { name: "Nombre completo", selector: "fullName", sortable:true,grow:"6"}, // Asumiendo que 'nombre' contiene tanto el nombre como el apellido
    {
      name: "Reportes",
      button: true,
      cell: row => (
        <div className="form-check form-switch">
          <input
            className="form-check-input"
            type="checkbox"
            id={"toggle-reports-" + row.studentId}
            checked={row.reportsEnabled}
            onChange={(e) => {
               e.preventDefault();
               toggleReporte(row.studentId)
            }}
          />
          <label className="form-check-label" htmlFor={"toggle-reports-" + row.studentId}></label>
        </div>
      )
    },
  ];

  const normalizeString = (str) => {
    return str
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .toLowerCase();
  };

  const filteredItems = estudiantes?.filter((item) => {
    if (item) {
      const allData = `${item.fullName} ${item.identification} `;
      const normalizedFilterText = normalizeString(filterText);
      const normalizedItemName = normalizeString(allData);
      return normalizedItemName.includes(normalizedFilterText);
    }
    return item;
  });

  return (
    <>

<div className="card p-3 filter-container">
  <h1>Activación de Reportes y Boletines</h1>
  <p>Administra la visibilidad de reportes y boletines para estudiantes y padres.</p>
  <div className="card-body">
    <form className="row mb-3 align-items-end">
      <JornadaAnioPeriodoNivelCurso
        callback={updateForm}
        ref={filteReference}
        loadTutoria={true}
        loadCoordinators={true}
        hideAsignatura={true}
        properties={{
          className: 'col-md-3 mb-2',
          id: 'formReport2',
          filtro: false
        }}
      />
      <div className="col-md-3 mb-2">
        <label htmlFor="reportTypeId" className="col-form-label text-sm-right">Tipo Reporte</label>
        <select
          className="form-control form-select validarForm"
          name="reportTypeId"
          value={formData.reportTypeId}
          onChange={updateCustomForm}
          required
        >
          <option key="0" value="0">Seleccione</option>
          <option key="1" value="1">Boletin</option>
          <option key="2" value="2">Perfil de notas</option>
          <option key="3" value="3">Corte Intermedio</option>
          <option key="4" value="4">Corte Preventivo</option>
        </select>
      </div>
      <div className="col-md-3 mb-2 d-flex text-end align-items-end">
        <button
          onClick={cargarPermisosEstudiantes}
          className="btn btn-primary w-100"
        >
          Buscar
        </button>
      </div>
    </form>
  </div>
</div>

          {showLoading && <Loading />}

          {!showLoading && estudiantes.length>0 && (
            <>
             <div className="card student-table-container p-3 mt-3">
              <div className="student-table-controls mb-3 d-flex justify-content-between align-items-center">
                <input
                    type="text"
                    placeholder="Buscar estudiante"
                    value={filterText}
                    onChange={e => setFilterText(e.target.value)}
                    className="form-control form-control-sm w-50"
                />

                    <div className="form-check form-switch">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id={"toggle-reports-all"}
                          checked={checkAll}
                          onChange={(e) => toggleCheckAll(e.target.checked)}
                        />
                        <label className="form-check-label" htmlFor={"toggle-reports-all"}></label>
                    </div>
                </div>
              
                <DataTable
                  columns={columns}
                  data={filteredItems}
                  customStyles={customStyles}
                  className="students-datatable shadow-sm rounded table table-corporate table-bordered table-sm my-0 dataTables_wrapper dt-bootstrap5 no-footer"
                  noDataComponent="No se encontraron estudiantes"
                  noHeader
                  dense
                  highlightOnHover
                  pointerOnHover
                  fixedHeader
                  fixedHeaderScrollHeight="450px"
                />
            </div>
            </>
          )}
      
    </>
  );
};

export default DesactivarReportesEstudiantes;
