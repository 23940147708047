import { useRef, useState } from "react";
import JornadaAnioFilter from "../../components/Filters/JornadaAnioFilter";
import { Button } from "react-bootstrap";
import asistenciaService from "../../services/asistenciaService";
import moment from "moment";
import HeaderActa from "../../components/headerActas";
import utils from '../../utils/generalFunctions';
import academicService from "../../services/academicService";
import { toast } from 'react-toastify';
import Loading from "../../components/Loading";
import FooterActa from "../../components/FooterActa";
import types from "../../services/types";

const RepoAsistenciaTotal = () => {
    const filteReference = useRef(null);
    const initData ={
        schoolYearId: "0",
        journeyId:"0",
        startDate: "",
        periodId:"",
    };
    const [formData, setFormData] = useState(initData);
    const [asistencias, setAsistencias] = useState([]);
    const [educationLevels, setEducationLevels] = useState([]);
    const [courses, setCourses] = useState([]);
    const [datosBusqueda, setDatosBusqueda] = useState([]);
    const [periodos, setPeriodosList] = useState([]);
    const [showLoading, setShowLoading] = useState(false);
    
    
    const updateForm = async (data) => {
        if (data.schoolYearId!==formData.schoolYearId){   
          await cargarPeriodos(data.schoolYearId);
        }
        setFormData(prevState => ({
            ...prevState,
            ...data
          }));
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const cargarPeriodos = async (schoolYearId) => {
        try {
            const schoolYear = await academicService.getYearById(schoolYearId);              
            if(schoolYear){
                setPeriodosList(schoolYear.periods)
            }            
        } catch (error) {
             console.error("Error al obtener información de año escolar:", error);
        }
    }

    const generarCosolidadReporteTotal = async () =>{
        const requiredFields = ['schoolYearId', 'journeyId', 'startDate','periodId'];
        for (let field of requiredFields) {
            if (!formData[field] || formData[field]==='0') {
                toast.error("Por favor, completa todos los campos del filtro.", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                  });
                return;
            }
        }
        setDatosBusqueda([]);
        setShowLoading(true);
        try {
           const copyData = utils.copy(formData);
           copyData.periodName = periodos.find(p=>String(p.academicPeriodId)===copyData.periodId)?.periodName??"";
           const years = filteReference.current?.getSchoolYears();
           if(years){
                copyData.year = years.find(p=>String(p.schoolYearId)===copyData.schoolYearId)?.year??"";
           }
           const journeys = filteReference.current?.getJourneys();
           if(journeys){
                copyData.journey = journeys.find(p=>String(p.journeyId)===copyData.journeyId)?.name??"";
           }

           setDatosBusqueda(copyData);
           const date = moment(formData.startDate).format("yyyy-MM-DD");
           const asistenciasDB = await asistenciaService.getAsistenciasTotalReport(formData.schoolYearId, formData.periodId, date);
            if(asistenciasDB && asistenciasDB.length>0){
                setAsistencias(asistenciasDB);

                const allEducationLevels = utils.removeDuplicates(asistenciasDB.map((o) => {return {educationLevelId: o.educationLevelId, name: o.educationLevelName}}));
                setEducationLevels(allEducationLevels);

                const allCourses = utils.removeDuplicates(asistenciasDB.map((o) => {return {courseId: o.courseId, name: o.courseName, educationLevelId: o.educationLevelId, educationLevelName: o.educationLevelName}}));
                setCourses(allCourses);
                
            }else{
                setAsistencias([]);
                toast.error("No se encontraron asistencias para la fecha seleccionada.", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                  });
            }
        } catch (error) {
          console.error("Error al obtener asistencias:", error);
        }
        setShowLoading(false);
    }

    return (
        <>
          <div className="card p-3 filter-container">
            <h1>Consolidado total asistencias por dia</h1>
            <p>Selecciona los filtros para generar el reporte.</p>
            
                <div className="row">
                    <JornadaAnioFilter
                                    ref={filteReference}
                                    callback={updateForm}
                                    properties={
                                        {
                                            className:'col-md-2 mb-3',
                                            id:'formReport',
                                            filtro:false
                                        }
                                    }
                                    ReadOnly={false}
                            />
                        <div className="mb-3 col-md-2">
                                <label htmlFor="periodId" className="col-form-label  text-sm-right">Periodo Académico:*</label>
                                <select className="form-control form-select" 
                                        id="periodId"
                                        name="periodId"  
                                        value={formData.periodId} onChange={handleChange}>
                                    <option value="">Seleccione</option>
                                    {periodos.map((period) => (
                                        <option key={period.academicPeriodId} value={period.academicPeriodId} >
                                            {period.periodName}
                                        </option>
                                    ))}
                                </select>
                        </div>

                        <div className="col-md-2 mb-3">
                            <label htmlFor="startDate" className="col-form-label  text-sm-right">Fecha de asistencia</label>
                            <input type="date" className="form-control" id="startDate" name="startDate" placeholder="Seleccione la fecha de asistencia" value={formData.startDate} onChange={handleChange} required />
                        </div>
                        <div className="col-md-3 mb-3 mt-4">
                                <button onClick={generarCosolidadReporteTotal} className="ml-2 btn btn-primary">Buscar</button>
                        </div>
                </div>
          </div>
          {showLoading && <Loading />}

          {!showLoading && asistencias.length>0 && (

                    <div className="">
                    <div className="card acta-container">
                        <div className="card-body">

                                {/* Cabezera */}
                                <HeaderActa />

                                
                                {/* Ficha de Incidencia */}
                                <section className="incidencia-info">
                                <div class="info-header">
                                    <h5 class="section-title">ASISTENCIA ESCOLAR</h5>
                                </div>
                                <div class="info-body">
                                    
                                    <div class="info-item">
                                        <span class="info-title">Año escolar:</span>
                                        <span class="info-content">{datosBusqueda.year}</span>
                                    </div>
                                    <div class="info-item">
                                        <span class="info-title">Fecha de asistencia:</span>
                                        <span class="info-content">{moment(datosBusqueda.startDate).format('DD-MM-YYYY')}</span>
                                    </div>
                                    <div class="info-item">
                                        <span class="info-title">Jornada:</span>
                                        <span class="info-content">{datosBusqueda.journey}</span>
                                    </div>
                                    <div class="info-item">
                                        <span class="info-title">Periodo:</span>
                                        <span class="info-content">{datosBusqueda.periodName}</span>
                                    </div>
                                    <div class="info-item">
                                        <span class="info-title">Fecha de impresion:</span>
                                        <span class="info-content">{moment().format('DD-MM-YYYY')}</span>
                                    </div>
                                   
                                </div>
                                </section>
                                
                
                                {/* Implicados */}
                                
                                        
                                
                    {educationLevels?.map((educationLevel, index) => (
                        <section className="incidencia-implicados">
                            <h5 className="section-title">{educationLevel.name}</h5>
                            <table className="tabla-implicados">
                            <thead>
                                <tr>
                                    <th>No.</th>
                                    <th>Curso</th>
                                    <th>Total asistencias</th>
                                    <th>Total inasistencias</th>
                                    <th>Total excusas</th>
                                    <th>Total llegadas tarde</th>
                                </tr>
                            </thead>
                            <tbody>
                                {courses.filter(c=>c.educationLevelId===educationLevel.educationLevelId)
                                ?.map((course, index) => (
                                <tr key={index}>
                                    <td>{index+1}</td>
                                    <td>{course.name}</td>
                                    <td>{asistencias.find(a=>a.courseName === course.name)?.assists??'0'}</td>
                                    <td>{asistencias.find(a=>a.courseName === course.name)?.noAssists??'0'}</td>
                                    <td>{asistencias.find(a=>a.courseName === course.name)?.excuses??'0'}</td>
                                    <td>{asistencias.find(a=>a.courseName === course.name)?.lates??'0'}</td>
                                </tr>
                                ))}
                            </tbody>
                            </table>
                        </section>
                    ))}
                                <section className="incidencia-implicados">
                                    <h5 className="section-title">Total</h5>
                                    <table className="tabla-implicados">
                                    <thead>
                                        <tr>
                                            <th>Total asistencias</th>
                                            <th>Total inasistencias</th>
                                            <th>Total excusas</th>
                                            <th>Total llegadas tarde</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>{(asistencias.reduce((sum, a) => {return sum + Number(a.assists??0);}, 0))}</td>
                                            <td>{(asistencias.reduce((sum, a) => {return sum + Number(a.noAssists??0);}, 0))}</td>
                                            <td>{(asistencias.reduce((sum, a) => {return sum + Number(a.excuses??0);}, 0))}</td>
                                            <td>{(asistencias.reduce((sum, a) => {return sum + Number(a.lates??0);}, 0))}</td>
                                        </tr>
                                    </tbody>
                                    </table>
                                </section>
                                <FooterActa reportTypeId={types.reportTypes.ASISTENCIA}></FooterActa>
                        </div>
                    </div>
                                <br />
                                    <button className="btn btn-primary center display-block" onClick={() => window.print()}>Imprimir</button>
                                <br />
                    </div>

          )}
        </>
    );
  };
  
  export default RepoAsistenciaTotal;