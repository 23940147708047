import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import useCustomDataTableStyles from "../components/estilosDeDataTable";
import IncidenciasForm from "../components/Forms/IncidenciaForm";
import { MDBModal, MDBModalBody, MDBModalContent, MDBModalDialog, MDBModalFooter, MDBModalHeader, MDBModalTitle } from "mdb-react-ui-kit";
import Loading from "../components/Loading";
import moment from "moment";
import incidenciasService from "../services/incidenciasService";
import Swal from "sweetalert2";
import ActaIncidencia from "../components/Reports/actaDeIncidencia";

const Incidencias = () => {
  const [incidencias, setIncidencias] = useState([]);
  const [filterText, setFilterText] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedIncidencia, setSelectedIncidencia] = useState(null);
  const [BtnVer, setBtnVer] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [verActa, setVerActa] = useState(null);

  const toggleShow = () => {
    setIsModalOpen(!isModalOpen);
    setSelectedIncidencia(null); 
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const fetchIncidencias = async () => {
    setShowLoading(true);
    try {
      const response = await incidenciasService.getIncidencias();
      if(response){
        setIncidencias(response);
      } else {
        setIncidencias([]);
      }
    } catch (error) {
      console.error("Error al obtener incidencias:", error);
    }
    setShowLoading(false);
  };  
  
  useEffect(() => {
    fetchIncidencias();
  }, []);

  const removeIncidencia = (incidenceId) => {
    if(incidenceId){
      Swal.fire({
        title: '¿Está seguro de que desea eliminar esta incidencia?',
        text: "",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sí, eliminar!'
      }).then(async(result) => {
        if (result.isConfirmed) {
          try{
            await incidenciasService.deleteIncidenciasById(incidenceId);
            Swal.fire(
              'Felicidades!',
              'Insidencia eliminada exitosamente!',
              'success'
            );
            await fetchIncidencias();
          } catch(error) {
            Swal.fire(
              "Ha ocurrido un error",
              "Por favor intentelo más tarde",
              "error"
            );
          }
        }
      });
    }
  };

  const columns = [
    { 
      name: "N°",grow:"1", 
      selector: row => row.number, 
      sortable: true, 
      cell: (row, index) => <span>{index + 1}</span>, 
    },
    { name: "Usuario", selector: row => row.createdUserName,grow:"1", sortable: true },
    { name: "Año escolar", selector: row => row.schoolYearName, grow:"1",sortable: true },
    { name: "Periodo", selector: row => row.periodName, grow:"1", sortable: true },
    { name: "Título", selector: row => row.title, grow:"1",sortable: true },
    { name: "Tipo", selector: row => row.incidenceTypeName, grow:"1",sortable: true },
    { name: "Categoría", selector: row => row.parentTypeName, grow:"1",sortable: true },
    { name: "Descripción", selector: row => row.description, sortable: true, grow: "3" },
    { name: "Fecha", selector: row => moment(row.incidenceDate).format('DD-MM-YYYY'),grow:"1", sortable: true },
    { name: "Implicados", selector: row => row.studentsFullName,grow:"1", sortable: true },
    { name: "Acciones",
      selector: "actions",
      cell: (row) => (
        <div className="btn-group btn-group-sm">
          <button className="btn btn-outline-info me-1" title="Editar" onClick={() => {
            setSelectedIncidencia(row);
            setIsModalOpen(true);
          }}>
            <i className="icon-edit fas fa-pencil-alt"></i>
          </button>
          <button className="btn btn-outline-danger me-1" title="Eliminar" onClick={() => removeIncidencia(row.incidenceId)}>
            <i className="icon-deactivate fas fa-times"></i>
          </button>
          <button
            onClick={() => exportarActa(row)}
            className="btn btn-outline-secondary me-1"
            title="Exportar"
          >
            <i className="fa-regular fa-file-pdf"></i>
          </button>
        </div>
      ),
    },
  ];

  const exportarActa = async (acta) => {
    if (acta) {
      setVerActa(acta);
    }
  };

  const volverLista = async () => {
    setVerActa(null);
  };

  const customStyles = useCustomDataTableStyles();

  const normalizeString = (str) => {
    return str
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .toLowerCase();
  };
    
  const filteredItems = incidencias?.filter((item) => {
    if (item) {
      const allData = `${item.title} ${item.description} ${item.studentsFullName} ${item.incidenceTypeName} ${item.parentTypeName} ${item.createdUserName} ${item.schoolYearName} ${item.periodName} ${moment(item.incidenceDate).format('DD-MM-YYYY')}`;
      const normalizedFilterText = normalizeString(filterText);
      const normalizedItemName = normalizeString(allData);
      return normalizedItemName.includes(normalizedFilterText);
    }
    return item;
  });

  return (
    <>
      {!verActa && (
        <div className="card p-3 filter-container">
          <h1>Gestión de Incidencias</h1>
          {showLoading && <Loading />}
          {!showLoading && (
            <>
              <div className="row mb-3 align-items-end">
                <div className="col-md-6">
                  <input
                    type="text"
                    placeholder="Buscar incidencias..."
                    value={filterText}
                    onChange={e => setFilterText(e.target.value)}
                    className="form-control"
                  />
                </div>
                <div className="col-md-6 text-end">
                  <button className="btn btn-primary" onClick={openModal}>
                    <i className="fa-solid fa-circle-plus"></i> Registrar Incidencia
                  </button>
                </div>
              </div>
              <DataTable
                noHeader
                columns={columns}
                noDataComponent="No hay información que mostrar, por favor añadela"
                data={filteredItems}
                customStyles={customStyles}
                pagination
                persistTableHead
                fixedHeader
                fixedHeaderScrollHeight="450px"
              />
              <MDBModal tabIndex='-1' show={isModalOpen} setShow={setIsModalOpen}>
                <MDBModalDialog className="modal-xl-custom">
                  <MDBModalContent>
                    <MDBModalHeader>
                      <MDBModalTitle>Incidencia</MDBModalTitle>
                      <button
                        type='button'
                        className='btn-close'
                        color='none'
                        onClick={toggleShow}
                      ></button>
                    </MDBModalHeader>
                    <MDBModalBody>
                      <div className='card'>
                        {selectedIncidencia ? (
                          <IncidenciasForm
                            key={Date.now()}
                            incidencia={selectedIncidencia.incidenceId}
                            onClose={() => {
                              setIsModalOpen(false);
                              fetchIncidencias();
                            }}
                            ReadOnly={BtnVer}
                          />
                        ) : (
                          <IncidenciasForm
                            key={Date.now()}
                            incidencia={0}
                            onClose={() => {
                              setIsModalOpen(false);
                              fetchIncidencias();
                            }}
                            ReadOnly={false}
                          />
                        )}
                      </div>
                    </MDBModalBody>
                    <MDBModalFooter>
                      <button type='button' className='btn-footer-close btn-primary' onClick={toggleShow}>
                        Cerrar
                      </button>
                    </MDBModalFooter>
                  </MDBModalContent>
                </MDBModalDialog>
              </MDBModal>
            </>
          )}
        </div>
      )}

      {verActa && (
        <div>
          <button className="btn btn-secondary" onClick={() => volverLista()}>
            <i className="fas fa-arrow-left"></i> Volver
          </button>
          <ActaIncidencia incidencia={verActa} />
        </div>
      )}
    </>
  );
};

export default Incidencias;
