import React, { useEffect, useRef, useState } from 'react';
import billingConceptService from '../../services/billingConceptService';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import Loading from '../Loading';
import JornadaAnioFilter from '../Filters/JornadaAnioFilter';
import courseService from '../../services/courseService';
import collectionsService from '../../services/collectionsService';
import types from '../../services/types';

const BillingConceptForm = (props) => {
    const { billingConceptId, onClose, ReadOnly } = props; 
    const initData ={
        billingConceptId: 0,
        description: "",
        value: null,
        schoolYearId: "0",
        educationLevelId: "0",
        journeyId:"0",
        isFinancialObligation: false,
        paymentFrequencyId:"0",
        isActive: true
    };
    const [formData, setFormData] = useState(initData);
    const [showLoading, setShowLoading] = useState(true);  
    const [educationLevels, setEducationLevels] = useState([]); 
    const [paymentFrecuenceList, setPaymentFrecuenceList] = useState([]);
    const filteReference = useRef(null);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const updateForm = (data) => {
        setFormData(prevState => ({
            ...prevState,
            ...data
          }));
    }

    useEffect(() => {
        const fetchInformacion = async () => {
            try {                
                
                const respEd =  await courseService.getEducationLevels();  // Asegúrate de tener un método getCursos en cursoService
                const paymentTypeRes = await collectionsService.getCollection(types.collections.PAYMENT_FRECUENCY);
                setPaymentFrecuenceList(paymentTypeRes);
                setEducationLevels(respEd);
                setShowLoading(false);
                if (billingConceptId) {
                    const resBillingConcept = await billingConceptService.getBillingItemById(billingConceptId);
                    setFormData((prevState) => ({ ...prevState, ...resBillingConcept }));
                    filteReference?.current?.setFormValues(resBillingConcept);
                }
            } catch (error) {
                console.error("Error al obtener listas de formulario:", error);
            }
        };
    
        fetchInformacion();
    }, [billingConceptId]);

    const handleSubmit = async (e) => {
        e.preventDefault();
         // Validación básica
         const requiredFields = ['description', 'value', 'schoolYearId'];
         for (let field of requiredFields) {
             if (!formData[field]) {
                 toast.error("Por favor, completa todos los campos obligatorios.", {
                     position: "top-right",
                     autoClose: 5000,
                     hideProgressBar: false,
                     closeOnClick: true,
                     pauseOnHover: true,
                     draggable: true,
                     progress: undefined,
                     theme: "light",
                   });
                 return;
             }
         }
 

        setShowLoading(true);
        try {
            let response;
            if (formData.billingConceptId > 0) {
                response = await billingConceptService.updateBillingItem(formData);
            } else {
                response = await billingConceptService.addBillingItem(formData);
            }
            if (response && response.billingConceptId) {
                Swal.fire("Felicidades!", "Concepto guardado con éxito!", "success");
                if (onClose) onClose();
                setFormData(initData);
            } else {
                Swal.fire("Ha ocurrido un error", "Por favor intentelo mas tarde", "error");
            }
        } catch (error) {
            console.error('Error al enviar el formulario:', error);
            Swal.fire("Ha ocurrido un error", "Por favor intentelo mas tarde", "error");
        }
        setShowLoading(false);
    };
    

    return (
        <>
        {showLoading && <Loading />}

        {!showLoading && (
           <div className="container-fluid mt-4">
           <p className="text-muted">Por favor, completa todos los campos para registrar un nuevo concepto.</p>
       
           <form onSubmit={handleSubmit} className="mt-3">
               <div className="row g-2">
                   <JornadaAnioFilter
                       ref={filteReference}
                       callback={updateForm}
                       properties={{
                           className: 'col-md-3',
                           id: 'formBillingConcepts',
                           filtro: false,
                       }}
                       ReadOnly={ReadOnly}
                   />
       
                   <div className="col-md-3">
                       <label htmlFor="educationLevelId" className="form-label">Nivel Ed.:</label>
                       <select
                           className="form-select form-select"
                           id="educationLevelId"
                           name="educationLevelId"
                           value={formData.educationLevelId}
                           onChange={handleChange}
                           disabled={ReadOnly}
                           required
                       >
                           <option value="0">Seleccione</option>
                           {educationLevels.map((level) => (
                               <option key={level.educationLevelId} value={level.educationLevelId}>
                                   {level.name}
                               </option>
                           ))}
                       </select>
                   </div>
       
                   <div className="col-md-3">
                       <label htmlFor="description" className="form-label">Descripción</label>
                       <input
                           type="text"
                           className="form-control"
                           id="description"
                           name="description"
                           readOnly={ReadOnly}
                           value={formData.description}
                           onChange={handleChange}
                           required
                       />
                   </div>
       
                   <div className="col-md-3">
                       <label htmlFor="value" className="form-label">Valor</label>
                       <input
                           type="number"
                           className="form-control"
                           id="value"
                           name="value"
                           readOnly={ReadOnly}
                           value={formData.value}
                           onChange={handleChange}
                           required
                       />
                   </div>
       
                   <div className="col-md-3">
                       <label htmlFor="paymentFrequencyId" className="form-label">Frecuencia de pago:</label>
                       <select
                           className="form-select"
                           name="paymentFrequencyId"
                           value={formData.paymentFrequencyId}
                           onChange={handleChange}
                           disabled={ReadOnly}
                           required
                       >
                           <option value="0" disabled={ReadOnly}>
                               Seleccione
                           </option>
                           {paymentFrecuenceList.map((option) => (
                               <option key={option.id} value={option.id} disabled={ReadOnly}>
                                   {option.text}
                               </option>
                           ))}
                       </select>
                   </div>
       
                   <div className="col-md-4">
                       <label className="form-label">Configuración de Pago:</label>
                       <div className="form-check">
                           <input
                               type="checkbox"
                               className="form-check-input"
                               id="isFinancialObligation"
                               name="isFinancialObligation"
                               disabled={ReadOnly}
                               checked={formData.isFinancialObligation}
                               onChange={(e) => setFormData({
                                   ...formData,
                                   isFinancialObligation: e.target.checked,
                               })}
                           />
                           <label className="form-check-label" htmlFor="isFinancialObligation">
                               Obligación financiera
                           </label>
                       </div>
                       <div className="form-check">
                           <input
                               type="checkbox"
                               className="form-check-input"
                               id="isActive"
                               name="isActive"
                               disabled={ReadOnly}
                               checked={formData.isActive}
                               onChange={(e) => setFormData({
                                   ...formData,
                                   isActive: e.target.checked,
                               })}
                           />
                           <label className="form-check-label" htmlFor="isActive">
                               Activo
                           </label>
                       </div>
                   </div>
               </div>
       
               <div className="row mt-4">
                   <div className="col-md-12 text-center">
                       {!ReadOnly && (
                           <button type="submit" className="btn btn-primary">
                               Guardar
                           </button>
                       )}
                   </div>
               </div>
           </form>
       </div>
       
        
        )}
        </>
    );
};

export default BillingConceptForm;
