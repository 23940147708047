import React, { useEffect, useState } from "react";
import AsignacionTutores from "../components/tutoresComponent.js";
import courseService from "../services/courseService.js";
import groupDirector from "../services/groupDirector.js";
import utils from '../utils/generalFunctions.js';
import docentesService from "../services/docentesService.js";
import collectionsService from "../services/collectionsService.js";

const Tutores = () => {
  const [courses,setCourses] = useState([]);
  const [directores,setDirectores] = useState([]);
  const [educationLevels,setEducationLevels] = useState([]);
  const [docentes,setDocentes] = useState([]);
  const [schoolYearId,setSchoolYearId] = useState([]);
  
  useEffect(() => {
    loadInformation();
  }, []);

  const loadInformation = async () => {
        try {
          const parameters = await collectionsService.getParameters();
          const currentAnio = parameters.find((op) => String(op.keyName) === "AnioElectivo");
          if(currentAnio) setSchoolYearId(currentAnio.value);

          const docentesResp = await docentesService.getDocentes();
          setDocentes(docentesResp);
          const respCurso =  await courseService.getCursos();  // Asegúrate de tener un método getCursos en cursoService
          setCourses(respCurso);
          const allEducationLevels = utils.removeDuplicates(respCurso.map((c) => {return {educationLevelId: c.educationLevelId, educationLevelName: c.educationLevelName}}));
          setEducationLevels(allEducationLevels);
          await cargarDirectoresGrupo();
      } catch (error) {
          console.error("Error al obtener listas de formulario:", error);
      }
  };

  const cargarDirectoresGrupo= async () => {
      try {
        const respDirectores =  await groupDirector.getDirectorGrupos();
        setDirectores(respDirectores);
      } catch (error) {
          console.error("Error al obtener listas de formulario:", error);
      }
  }

  // Funciones para manejar cambios en las asignaciones de tutores
  const handleTutorChangePreescolar = (cursoId, tutorId) => {

    let added = false;
    let newDirectores = utils.copy(directores);
    const course = courses.find(c=>Number(c.courseId) === Number(cursoId));
    newDirectores.map(a=> {
            if(Number(a.courseId)===Number(cursoId)){
                a.changed = true;
                a.teacherId = tutorId;
                added=true;
            }
            return a;
    });
    if(!added){
      newDirectores.push({
        groupDirectorId:0,
        teacherId:tutorId,
        courseId:cursoId,
        gradeId:course.gradeId,
        schoolYearId:schoolYearId
      });
    }
    setDirectores(newDirectores);
  };

  return (
    <>
    <div className="card p-3">
        <div className="card-header">
        <h2 className="">Asignación de tutores</h2>
        <h5 className="card-subtitle text-muted">Esta es la vista de los docentes asignados como tutor de un curso por nivel educativo.</h5>
      </div>
      </div>
    <div className="row">
      {educationLevels.map(e=>{

        return(  
          
        <div className="col-12 col-lg-6 col-xl-3">
          <AsignacionTutores
            titulo={e.educationLevelName}
            cursos={courses.filter(c=>c.educationLevelId===e.educationLevelId)}
            tutores={docentes}
            asignaciones={directores}
            handleTutorChange={handleTutorChangePreescolar}
            loadTutores={cargarDirectoresGrupo}            
          />
      </div>);
      })}
    </div>
    </>
  );
};

export default Tutores;
