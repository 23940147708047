import React, { useEffect, useRef, useState } from "react";
import DataTable from "react-data-table-component";
import "../css/estudiantes.css";
import utils from '../utils/generalFunctions';
import { toast } from 'react-toastify';
import Swal from "sweetalert2";
import JornadaAnioPeriodoNivelCursoAsig from "../components/Filters/JornadaAnioPeriodoNivelCursoAsig";
import Loading from "../components/Loading";
import procesoService from "../services/procesoService";
import useCustomDataTableStyles from "../components/estilosDeDataTable";

const ProcesosView = () => {

    const [filters, setFilters] = useState({ // Estado para los filtros aplicados
        teacherId: '0',
        journeyId: '0',
        schoolYearId: '0',
        educationLevelId: '0',
        courseId: '0',
        isRecuperation: false
    });
    const [paramProcesos, setParamProcesos] = useState({});
    const filteReference = useRef(null);
    const [showLoading, setShowLoading] = useState(false);
    const [procesos, setProcesos] = useState([]);
    const [showSearch, setShowSearch] = useState(false);

    const customStyles = useCustomDataTableStyles();

    const handleFilterChange = (newFilters) => {
        // Actualiza el estado de filters con los nuevos valores
        setFilters(prev => ({
            ...prev,
            ...newFilters
        }));
    };

    const cargarProcesos = async (e) => {
        if (e && e.preventDefault) e.preventDefault(); // Evita que se envíe el formulario
        setShowLoading(true); // Cambia el estado a false una vez que los datos se carguen
        const copyData = utils.copy(filters);
        const cursos = filteReference?.current?.getCursos();
        if (cursos.length > 0) {
            copyData.gradeId = cursos.find(c => String(c.courseId) === String(copyData.courseId))?.gradeId;
        }
        const educationLevels = filteReference?.current?.getEducationLeves();
        if (educationLevels.length > 0) {
            copyData.evaluationTypeId = educationLevels.find(c => String(c.educationLevelId) === String(copyData.educationLevelId))?.evaluationTypeId;
        }
        setParamProcesos(copyData);
        try {
            const procesosDB = await procesoService.getProcesosToNotas(copyData.courseId, copyData.schoolSubjectId, copyData.schoolYearId, copyData.periodId, copyData.evaluationTypeId, copyData.evaluationTypeId);
            if (procesosDB) {
                const data = procesosDB.filter(p => p.isRecuperation === (copyData.isRecuperation ? 1 : 0));
                setProcesos(data);
            } else {
                setProcesos([]);
            }
        } catch (error) {
            console.error("Error al obtener procesos:", error);
        }
        setShowSearch(true);
        setShowLoading(false);// Cambia el estado a false una vez que los datos se carguen
    }

    const calculateTotalPorcentajes = () => {
        if (procesos && procesos.length > 0) {
            return procesos?.reduce((acc, proceso) => acc + Number(proceso.percentage), 0);
        } else {
            return 0;
        }
    };

    const handleInputDetailChange = (e, index, field) => {
        const element = procesos.find((_, i) => i === index);
        if (field === "percentage") {
            let numero = ((e.target.value.trim() !== "") ? Number(e.target.value.trim()) : 0);
            numero = ((numero < 0) ? (numero * -1) : numero);
            numero = (numero > 100) ? 100 : numero;
            element[field] = numero;
        } else {
            element[field] = e.target.value;
        }
        const newProcesses = [...procesos];
        setProcesos(newProcesses);
    };

    const columns = [
        { name: "Codigo", selector: "evaluationProcessId", grow: 0.1 },
        { name: "Descripción", selector: "description", grow: 2, cell: (row, index) => (
            <>
                <div className="row w-100">
                    <div className="col-md-10">
                        <input
                            type="text"
                            className="form-control "
                            style={{
                                height: "25px",
                            }}
                            value={row.description}
                            onChange={(e) => handleInputDetailChange(e, index, 'description')} />
                    </div>
                </div>
            </>
        ),
        },
        { name: "Porcentaje", omit: (paramProcesos.evaluationTypeId !== 2), grow: 1, selector: "percentage", cell: (row, index) => (
            <>
                <div className="row ">
                    <div className="col-md-5 ">
                        <input
                            type="number"
                            className="form-control"
                            style={{
                                height: "25px",
                            }}
                            value={row.percentage}
                            onChange={(e) => handleInputDetailChange(e, index, 'percentage')} />
                    </div>
                    <div className="col-md-2  ">
                        <h4>%</h4>
                    </div>
                </div>
            </>
        ),
        },
        { name: "", selector: "evaluationProcessId", cell: (row, index) => (
            <i className="fas fa-xmark clear-button-searcher" aria-hidden="true" onClick={(e) => handleRemoveProcess(e, index)}></i>
        ) },
    ];

    const handleRemoveProcess = (e, index) => {
        e.preventDefault(); // Añade esta línea para prevenir el comportamiento predeterminado del botón
        const removedElement = procesos[index];
        if (removedElement.evaluationProcessId) {
            Swal.fire({
                title: '¿Está seguro de que desea eliminar este proceso?',
                text: "",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Si, eliminar!'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    try {

                        await procesoService.deleteProcesosAcademicoById(removedElement.evaluationProcessId);
                        Swal.fire(
                            'Felicidades!',
                            'Proceso eliminado exitosamente!',
                            'success'
                        );
                        await cargarProcesos();
                    } catch (error) {
                        Swal.fire(
                            "Ha ocurrido un error",
                            "Por favor intentelo más tarde",
                            "error"
                        );
                    }
                }
            })
        } else {
            const updatedDetails = procesos.filter((_, i) => i !== index);
            setProcesos(updatedDetails);
        }
    };

    const handleAddProcess = (e) => {
        e.preventDefault(); // Añade esta línea para prevenir el comportamiento predeterminado del botón
        const newProcess = {
            evaluationProcessId: null,
            description: "",
            percentage: (paramProcesos.evaluationTypeId === 2 ? 0 : null)
        };
        const newProcesses = [...procesos, newProcess];
        setProcesos(newProcesses);
    };

    const validateProcesos = () => {
        if (paramProcesos.evaluationTypeId === 2) {
            const totalPercentage = procesos?.reduce((acc, proceso) => acc + Number(proceso.percentage), 0);
            if (totalPercentage < 100) {
                toast.error("Los procesos deben cubrir el 100% de asignación, estas por debajo del 100%", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                return false;
            }
            if (totalPercentage > 100) {
                toast.error("Los procesos deben cubrir el 100% de asignación, estas por encima del 100%", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                return false;
            }
        }
        const procesosVacios = procesos.filter(p => p.description.trim() === "");
        if (procesosVacios.length > 0) {
            toast.error("La descripción de los conceptos no puede estar vacia, por favor asegurate de llenar todas las descripciones", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            return false;
        }
        return true;
    }

    const guardarProcesos = async (e) => {
        e.preventDefault();
        const valido = validateProcesos();
        if (valido) {
            setShowLoading(true);
            let completado = true;
            for (let i = 0; (i < procesos.length && completado); i++) {
                const item = procesos[i];

                const itemProceso = {
                    courseId: paramProcesos.courseId,
                    gradeId: paramProcesos.gradeId,
                    schoolYearId: paramProcesos.schoolYearId,
                    periodId: paramProcesos.periodId,
                    schoolSubjectId: paramProcesos.schoolSubjectId,
                    isRecuperation: paramProcesos.isRecuperation ? 1 : 0,
                    evaluationTypeId: paramProcesos.evaluationTypeId
                };
                const obj = { ...itemProceso, ...item };
                if (item.evaluationProcessId === null) {
                    const result = await procesoService.createProcesoAcademico(obj);
                    completado = (result?.evaluationProcessId > 0);
                } else {
                    const result = await procesoService.updateProcesoAcademico(obj);
                    completado = (result?.evaluationProcessId > 0);
                }
            }
            setShowLoading(false);
            if (completado) {
                cargarProcesos();
                Swal.fire(
                    'Felicidades!',
                    'Proceso guardado exitosamente!',
                    'success'
                );
            }
        }
    }

    return (
        <>
            <div className="card p-3">
                <div className="card-header">
                    <h2 className="">Procesos de notas</h2>
                    <h5 className="card-subtitle text-muted">Esta es la vista de generación de procesos.</h5>
                </div>

                <div className="card-body">
                    <form className="row  g-2 gy-2  align-items-center " onSubmit={cargarProcesos}>
                        <JornadaAnioPeriodoNivelCursoAsig
                            callback={handleFilterChange}
                            ref={filteReference}
                            properties={{
                                className: 'col-md-3',
                                id: 'formTest',
                                filtro: false,
                            }}
                        />
                        <div className="col-md-2 ">
                            <label className="form-label">Modo vista:</label>
                            <div className="">
                                <input
                                    type="checkbox"
                                    className="form-check-input"
                                    id="isRecuperation"
                                    name="isRecuperation"
                                    checked={filters.isRecuperation}
                                    onChange={(e) => setFilters({ ...filters, isRecuperation: e.target.checked })}
                                />
                                <label className="form-check-label" htmlFor="isRecuperation">
                                    Recuperación
                                </label>
                            </div>
                        </div>
                        <div className="col-md-1  d-flex align-items-end">
                            <button type="submit" className="btn btn-primary mt-3 w-100">Buscar</button>
                        </div>
                    </form>
                </div>
            </div>

            {showLoading && <Loading />}

            {showSearch && (
                <>
                    {!showLoading && (
                        <>
                            <div className="card p-3">
                                <div className="text-end mb-3">
                                    <button type="button" className="btn btn btn-primary" onClick={handleAddProcess}>
                                        <i className="fa-solid fa-circle-plus"></i> Añadir Proceso
                                    </button>
                                </div>
                                <DataTable
                                    columns={columns}
                                    data={procesos}
                                    customStyles={customStyles}
                                    fixedHeader
                                    fixedHeaderScrollHeight="450px"
                                    noDataComponent="No hay procesos que mostrar, por favor agregalos"
                                    noHeader
                                    dense
                                    highlightOnHover
                                    pointerOnHover
                                />
                                <div className="col-md-12 text-end  mt-3" id="pnlTotal">
                                    <h4>Total: {calculateTotalPorcentajes()} %</h4>
                                </div>
                                {procesos && procesos.length > 0 && (
                                    <div className="col-md-12">
                                        <button type="button" className="btn btn btn-success center display-block" onClick={guardarProcesos}>
                                            Guardar procesos
                                        </button>
                                    </div>
                                )}
                            </div>
                        </>
                    )}
                </>
            )}
        </>
    );
};

export default ProcesosView;
