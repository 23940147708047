import React, { useState } from "react";
import { Link } from "react-router-dom";
import { toast } from 'react-toastify';
import util from '../../utils/generalFunctions';

const SidebarStudents= (props) => {
  const { showMenus, activeMenu}=props;

  const [isCollapsed, setIsCollapsed] = useState(true);

  const studentInfo = util.getStudentInformation();

  const toggleMenu = () => {
    setIsCollapsed(!isCollapsed);
  };

  const showMensajeInfo = () => {
    toast.info("Estamos en procesos de finalización  de periodo", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
}


  return (

        <ul className="sidebar-nav">
          <li className="sidebar-header">Menú</li>
          
          <li className="sidebar-item active">
            <Link onClick={activeMenu} className="sidebar-link align-middle" to="/dashboard">
            <i class="fas fa-bars"></i><span className="align-middle">Dashboard</span>
            </Link>
          </li>
              <li className="sidebar-item">
                <Link
                  onClick={activeMenu}
                  to="/dashboard-estudiantepc/observador-asistencia"
                  className="sidebar-link align-middle"
                ><i class="fa-solid fa-person-circle-exclamation"></i>Seguimiento
                </Link>
              </li>
              {studentInfo?.educationLevelId!==1 && (
                <li className="sidebar-item">
                <Link
                  to="/dashboard-estudiantepc/Notas"
                  className="sidebar-link align-middle"
                ><i class="fa-solid fa-chart-simple"></i>Calificaciones
                </Link>
              </li>
              )}        
              {studentInfo?.educationLevelId===1 && (
                  <li className="sidebar-item">
                  <Link
                    onClick={activeMenu}
                    to="/dashboard-estudiante/calificaciones-estudiante-pre"
                    className="sidebar-link align-middle"
                  ><i class="fa-solid fa-award"></i>Logros
                  </Link>
                </li>
              )}                     
              <li className="sidebar-item">
                <Link
                  onClick={activeMenu}
                  to="/dashboard-estudiantepc/horario-de-clases"
                  className="sidebar-link align-middle"
                ><i class="fa-solid fa-calendar-week feather feather-book-open align-middle"></i>Horario
                </Link>
              </li>
              <li className="sidebar-item">
                <Link
                  onClick={activeMenu}
                  to="/dashboard-estudiantepc/estado-de-cuenta"
                  className="sidebar-link align-middle"
                ><i class="fa-solid fa-file-invoice-dollar"></i>Estado de
                  Cuenta
                </Link>
              </li>
              <li className="sidebar-item">
                <Link
                  onClick={activeMenu}
                  to="/dashboard-estudiantepc/perfil"
                  className="sidebar-link align-middle"
                >
                  <i class="fa-regular fa-address-card"></i>Hoja de Vida
                </Link>
              </li>
              <li className="sidebar-item">
                <Link
                  onClick={activeMenu}
                  to="/reportes-estudiantepc"
                  className="sidebar-link align-middle"
                >
                  <i class="fa-solid fa-chart-pie"></i>Reportes
                </Link>
              </li>
              <li className="sidebar-item">
            <a
              onClick={showMenus}
              data-bs-toggle="collapse"
              className="sidebar-link collapsed"
            >
              <i class="fa-solid fa-shield-halved"></i>
              <span className="align-middle">Ajustes de Perfil</span>
            </a>
            <ul
              id="configuraciones"
              data-bs-parent="#sidebar"
              className="sidebar-dropdown list-unstyled collapse"
            >
              <li className="sidebar-item">
                <Link
                  onClick={activeMenu}
                  to="/UserProfile"
                  className="sidebar-link"
                >
                  <i class="fa-solid fa-user-gear"></i>
                  Cuenta de usuario
                </Link>
              </li>
            </ul>
          </li>
        </ul>
  );
}

export default SidebarStudents;
