import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import "../css/estudiantes.css";
import {
    MDBBtn,
    MDBModal,
    MDBModalDialog,
    MDBModalContent,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBModalFooter,
} from 'mdb-react-ui-kit';
import CourseForm from "../components/Forms/FormCursos";  // Asegúrate de crear este componente basado en el formulario anterior
import courseService from "../services/courseService";  // Asegúrate de crear este servicio
import Swal from "sweetalert2";
import useCustomDataTableStyles from "../components/estilosDeDataTable";

const Courses = () => {

    const [fullscreenXlModal, setFullscreenXlModal] = useState(false);
    const [cursos, setCursos] = useState([]);
    const [selectedCurso, setSelectedCurso] = useState(null);
    const [filterText, setFilterText] = useState('');
    const [BtnVer, setBtnVer] = useState('');

    const toggleShow = () => {
        setFullscreenXlModal(!fullscreenXlModal);
        setSelectedCurso(null); 
    }

    const openMaximizeModal = (curso) => {
        setSelectedCurso(curso);
        setFullscreenXlModal(true);
        setBtnVer(true);
    };

    const openEditingModal = async (curso) => {
        setSelectedCurso(curso);
        setFullscreenXlModal(true);
        setBtnVer(false);
    };

    const openAddingModal = () => {
        setSelectedCurso(null);
        setFullscreenXlModal(true);
        setBtnVer(false);
    };

    const deleteCurso = async (curso) => {
        Swal.fire({
            title: '¿Está seguro de que desea eliminar este curso?',
            text: "",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, eliminar'
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    await courseService.deleteCursoById(curso.courseId);  // Asegúrate de tener un método deleteCursoById en cursoService
                    Swal.fire(
                        'Eliminado',
                        'Curso eliminado exitosamente!',
                        'success'
                    );
                    fetchCourses();
                } catch (error) {
                    Swal.fire(
                        "Error",
                        "Ha ocurrido un error al intentar eliminar el curso.",
                        "error"
                    );
                }
            }
        })
    }

    const fetchCourses = async () => {
        try {
            const response = await courseService.getCursos();  // Asegúrate de tener un método getCursos en cursoService
            setCursos(response);
        } catch (error) {
            console.error("Error al obtener los cursos:", error);
        }
    };

    useEffect(() => {
        fetchCourses();
    }, []);

    const columns = [
        { 
            name: "Número", 
            selector: "number", 
            sortable: true, 
            cell: (row, index) => <span>{index + 1}</span>, 
        },
        { 
            name: "Nombre", 
            selector: "name", 
            sortable: true 
        },
        { 
            name: "Grado", 
            selector: "gradeName", 
            sortable: true 
        },
        { 
            name: "Activo", 
            selector: "isActive", 
            sortable: true,
            cell: (row) => (
                <>
                    {row.isActive ? <span>Sí</span> : <span>No</span>}
                </>
            ), 
        },
        {
            name: "Acciones",
            grow: "3",
            selector: "actions",
            cell: (row) => (
                <div className="btn-group btn-group-sm">
                    <button
                        onClick={() => openMaximizeModal(row)}
                        className="btn btn-outline-success me-1"
                        title="Maximizar información"
                    >
                        <i className="fas fa-eye"></i>
                    </button>
                    <button
                        onClick={() => openEditingModal(row)}
                        className="btn btn-outline-info me-1"
                        title="Editar"
                    >
                        <i className="icon-edit fas fa-pencil-alt"></i>
                    </button>
                    <button
                        onClick={() => deleteCurso(row)}  // Cambié deletePeriodo a deleteCurso
                        className="btn btn-outline-danger me-1"
                        title="Eliminar"
                    >
                        <i className="icon-deactivate fas fa-times"></i>
                    </button>
                </div>
            ),
        },
    ];

    const normalizeString = (str) => {
        return str
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .toLowerCase();
    };

    const filteredItems = cursos?.filter((item) => {  // Cambié periodos a cursos
        if (item) {
            const allData = `${item.number} ${item.gradeName} ${item.name} ${item.isActive}`;
            const normalizedFilterText = normalizeString(filterText);
            const normalizedItemName = normalizeString(allData);
            return normalizedItemName.includes(normalizedFilterText);
        }
        return item;
    });

    const customStyles = useCustomDataTableStyles();

    const paginationPerPage = 50; // Ajusta este número según tus necesidades

    return (
        <div className="col-md-12">
            <div className="card ">
                <div className="card-header">
                    <h2 className="">Cursos</h2>
                    <h5 className="card-subtitle text-muted">Esta es la vista de los cursos.</h5>
                </div>
                <div className="card-body">
                    <form className="row mb-3" onSubmit={(e) => e.preventDefault()}>
                        <div className="col-md-6 col-xl-4 mb-2 mb-md-0">
                            <input
                                type="text"
                                placeholder="Buscar"
                                value={filterText}
                                onChange={e => setFilterText(e.target.value)}
                                className="form-control"
                            />
                        </div>
                        <div className="col-md-6 col-xl-8">
                            <div className="text-end">
                                <button type="button" onClick={openAddingModal} className="btn btn btn-primary mb-2">
                                    Crear Curso <i className="fa-solid fa-circle-plus"></i>
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
       
                <DataTable
                    className="students-datatable shadow-sm rounded"
                    noDataComponent="No hay información que mostrar, por favor añadela"   
                    columns={columns}
                    data={filteredItems}
                    pagination
                    customStyles={customStyles}
                    fixedHeader
                    fixedHeaderScrollHeight="450px"
                    paginationPerPage={paginationPerPage} // Agrega esta propiedad para controlar las filas por página
                    paginationRowsPerPageOptions={[50, 100]}
                />

                <>
                    <MDBModal tabIndex='-1' show={fullscreenXlModal} setShow={setFullscreenXlModal}>
                        <MDBModalDialog className="modal-dialog modal-md">
                            <MDBModalContent>
                                <MDBModalHeader>
                                    <MDBModalTitle>DETALLES DEL CURSO</MDBModalTitle>
                                    <MDBBtn
                                        type='button'
                                        className='btn-close'
                                        color='none'
                                        onClick={toggleShow}
                                    ></MDBBtn>
                                </MDBModalHeader>
                                <MDBModalBody>
                                    <div className='card'>
                                        {selectedCurso && (
                                            <CourseForm
                                                key={Date.now()}
                                                course={selectedCurso.courseId}
                                                onClose={() => {
                                                    setFullscreenXlModal(false);
                                                    fetchCourses();
                                                }}
                                                ReadOnly={BtnVer}
                                            />
                                        )}

                                        {!selectedCurso && (
                                            <CourseForm
                                                key={Date.now()}
                                                course={0}
                                                onClose={() => {
                                                    setFullscreenXlModal(false);
                                                    fetchCourses();
                                                }}
                                                ReadOnly={false}
                                            />
                                        )}
                                    </div>
                                </MDBModalBody>
                                <MDBModalFooter>
                                    <button type='button' className='btn-footer-close btn-primary' onClick={toggleShow}>
                                        Cerrar
                                    </button>
                                </MDBModalFooter>
                            </MDBModalContent>
                        </MDBModalDialog>
                    </MDBModal>
                </>
            </div>
        </div>
    );
};

export default Courses;
