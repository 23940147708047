import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import SelectSearch from 'react-select-search';
import docentesService from '../../services/docentesService';
import types from '../../services/types';
import utils from "../../utils/generalFunctions";

const DocentesFilter =  forwardRef((props, ref) => {
    const { callback, properties, ReadOnly } = props; 
    const initData ={
        teacherId: "0"
    };
    const [formData, setFormData] = useState(initData);    
    const [teacherList, setTeacherList] = useState([]);
    useImperativeHandle(ref, () => ({
        setFormValues(data) {
          setFormData(prevState => ({
            ...prevState,
            ...data
        }));
        },
        setDocenteByUser(userId){
            const lista = teacherList.find(t=> t.userId === userId);
            setFormData(prevState => ({
                ...prevState,
                teacherId: (lista)?lista.value:"0"
            }));              
        }
    }));
    const  handleSelectChange =  (selectedValue, selectedOption, optionSnapshot) => {
        const { name, value } = {name:'teacherId', value:selectedValue};
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    useEffect(() => {
        if(callback){
            callback(formData);
        }
      }, [formData]);

    const clearForm = (e) =>{
        setFormData(prevState => ({...prevState, teacherId: '0'}))
    }

    const getValidateTeacher = () => {
        const user= utils.getUserInformation();
        if(user){
            const typeId=Number(user.userTypeId);
            if(typeId===types.userTypes.TEACHER){
                return user.userId;
            }
        }
        return '0';
    };

    useEffect(() => {
        const fetchDocentes = async () => {
            try {
                const id = getValidateTeacher();
                const docentesResp = await docentesService.getDocentes();
                let lista = docentesResp;
                if(id!=="0"){
                    lista = docentesResp.filter(t=> t.userId === id);
                    setFormData(prevState => ({
                        ...prevState,
                        teacherId: (lista.length>0)?lista[0].teacherId:"0"
                    }));
                }
                const teachers = lista.map((teacher, index, array) => {
                    return {name: teacher.fullName, value: teacher.teacherId, userId: teacher.userId}
                });
                setTeacherList(teachers);
            } catch (error) {
                console.error("Error al obtener listas de formulario:", error);
            }
        };
        fetchDocentes();
    }, []);


    return (
        <>
        <div className={(properties && properties.className)?properties.className:"col-md-3"}>
            <label htmlFor={(properties && properties.id)?`cmbDocente-${properties.id}`:"cmbDocente"} className="col-form-label  text-sm-right">Docente:</label>
            <div className='container-searcher-general'>
                <SelectSearch options={teacherList} 
                    id={(properties && properties.id)?`cmbDocente-${properties.id}`:"cmbDocente"}
                    value={formData.teacherId}  name="fullName" search placeholder="Selecciona a un docente" 
                    onChange={handleSelectChange}
                    readOnly={ReadOnly} 
                    disabled={ReadOnly} />
                     {formData.teacherId !== '0' && !ReadOnly && (
                        <i className="fas fa-xmark clear-button-searcher" aria-hidden="true" onClick={clearForm}></i>
                     )}
            </div>
        </div>
        </>
    );
});

export default DocentesFilter;
