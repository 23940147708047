import React from 'react';
import Skeleton from 'react-loading-skeleton';

const SkeletonNotas = ({ numProcesos }) => {
  return (
    <tr>
      <td><Skeleton /></td>
      {[...Array(numProcesos)].map((_, index) => (
        <React.Fragment key={index}>
          <td><Skeleton /></td>
          <td><Skeleton /></td>
        </React.Fragment>
      ))}
      <td><Skeleton /></td>
    </tr>
  );
};

export default SkeletonNotas;
