
import { useEffect, useRef, useState } from "react";
import HeaderActa from "../../../components/headerActas";
import FooterActa from "../../../components/FooterActa";
import moment from "moment";
import types from "../../../services/types";


const RepoBoletinDiurna = ({ boletin, periodo }) => {
    const filteReference = useRef(null);
    const [showLoading, setShowLoading] = useState(false);
    const student = boletin.students[0];
    const [listAsig, setListAsig] = useState([]);

    const getAsignaturas = () =>
    {
        const asig = [];
        for (let i = 0; i < student.periodNotes.length; i++) {
            const period = student.periodNotes[i];

            for (let j = 0; j < period.subjectNotes.length; j++) {
                const subject = period.subjectNotes[j];
                if(!asig.find(a=>a.schoolSubjetId === subject.schoolSubjetId)){
                    asig.push(subject);
                }
            }
        }
        setListAsig(asig);
    }

    useEffect(()=>{
        getAsignaturas();
    });

    return (
        <>
          {!showLoading && boletin && (

            <>
                {/* Cabezera   */}

                <div class="card acta-container">
                
                {/* <!-- Cabezera --> */}
                <div className="card-body">
                <HeaderActa />
                {/* <!-- Ficha de Estudiante --> */}
                <section class="incidencia-info-boletin">
                    <div class="info-header-boletin">
                    <h5 class="section-title-boletin">INFORME EVALUATIVO</h5>
                    </div>
                    <table className="datos-incidencia-table table table-sm my-2">
                        <tbody>
                            <tr>
                            <th className="info-title-boletin">Estudiante</th>
                                <td className="info-content-boletin">{student.studentFullName}</td>
                                <th className="info-title-boletin">Período</th>
                                <td className="info-content-boletin">{boletin.periodName}</td>
                                
                            </tr>
                            <tr>
                            <th className="info-title-boletin">Grado</th>
                                <td className="info-content-boletin">{boletin.gradeName}</td>
                                <th className="info-title-boletin">Año</th>
                                <td className="info-content-boletin">{boletin.schoolYearName}</td>
                                
                            </tr>
                            <tr>
                                
                                <th className="info-title-boletin">Tutor</th>
                                <td className="info-content-boletin">{boletin.tutorName}</td>
                                <th className="info-title-boletin">Fecha impresión</th>
                                <td className="info-content-boletin">{moment().format("DD-MM-yyyy hh:mm")}</td>
                            </tr>
                        </tbody>
                    </table>

                </section>
                
                
                {/* <!-- Sección de Escala Valorativa --> */}
                <section className="escala-valorativa-container">
  <div className="escala-valorativa-header">
    <h5 className="section-title-boletin">ESCALA VALORATIVA</h5>
  </div>
  <div className="escala-valorativa-body">
    {boletin.evaluationScale?.map((a, index) => (
      <div key={index} className="escala-item">
        {a.name} = {a.abbreviation} ({a.minScore.toFixed(2)} - {a.maxScore.toFixed(2)})
      </div>
    ))}
  </div>
</section>

                
                

                
                {/* <!-- Aquí podrías añadir más secciones del informe como el historial académico, asistencia, etc. -->
                <!-- Sección de Áreas Académicas --> */}
                <div class="academic-areas escala-valorativa-container">
                    <table class="table-custom-boletin">
                    {student.areasNotes?.map((a, index) => (
                        <>
                            <thead>
                                <tr>
                                    <th className="area-header asignatura-title" colspan="4">{a.academicAreaName} ({a.percentage}%)</th>
                                    <th>IHS</th>
                                    <th>Inas</th>
                                    <th>LLet</th>
                                    <th>Exc</th>
                                    <th className="escale-title">Escala</th>
                                    <th>NOTA DEL ÁREA: {a.noteScale}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {a.subjectNotes?.map((s, index) => (
                                <tr>
                                    <td colspan="4" >{s.schoolSubjetName} ({s.percentage}%)</td>
                                    <td className="text-center">{s.hourlyIntensity}</td>
                                    <td className="text-center">{s.nonAttendance}</td>
                                    <td className="text-center">{s.lateArrival}</td>
                                    <td className="text-center">{s.excused}</td>
                                    <td className="text-center">{s.noteScale}</td>
                                    <td>
                                        <ul>
                                            {s.achievements?.map((ach, index) => (
                                                <li><span className="info-title-boletin">{ach.evaluativeScaleAbr} </span> {ach.description}</li>
                                            ))}
                                        </ul>
                                    </td>
                                </tr>
                                ))}
                            </tbody>
                        </>
                        ))}
                    </table>
                </div>

                <div class="academic-areas escala-valorativa-container promedio-curso-vs-estudiante">
                    <h5 className="historico-title">Promedios del Período: {boletin.periodName}</h5>
                    <table>
                        <tr>
                        <th className="historico-title">Promedio Estudiante </th>
                        <td> {student.noteScale}</td>
                        </tr>
                        <tr>
                        <th>Promedio del Curso </th>
                        <td> {boletin.noteScale}</td>
                        </tr>
                    </table>
                </div>

                <div className="academic-areas escala-valorativa-container promedio-curso-vs-estudiante">
    <table className="table-observaciones">
        <tbody>
            <tr>
                <th>Observaciones Generales del Director de Grupo: </th>
                <td className="padding-left-10" >{student.observations ||  "  "}</td>
            </tr>
           
        </tbody>
    </table>
</div>

                
                <section class="historico-periodos">
                    <h5 class="historico-title">Histórico de Periodos</h5>
                    <table class="table-historico datatables-reponsive">
                        <thead>
                            <tr>
                                <th rowspan="2">Periodo</th>
                                <th colspan={student.periodNotes[0].subjectNotes.length}>Asignaturas (Académicas)</th>
                                <th rowspan="2">Promedio</th>
                            </tr>
                            <tr>
                            {listAsig?.map((sub, index) => (
                                <th>{sub.schoolSubjetAbbr}</th>
                            ))}
                            </tr>
                        </thead>
                        <tbody>
                            {student.periodNotes?.map((d, index) => (
                               <tr>
                                    <td>{d.periodName}</td>
                                    {listAsig?.map((asig, index) => {
                                        const asigFound = d.subjectNotes?.find(s=> s.schoolSubjetId === asig.schoolSubjetId);
                                        if(asigFound){
                                            return  <td>{asigFound.noteScale} 
                                                    {asigFound && asigFound.recuperationNote && (
                                                        <>
                                                            <br /><span class="recuperacion"><b>R:</b> {asigFound.recuperationNote}</span>
                                                        </>
                                                    )}
                                                </td>
                                        }else{
                                            return <td>  </td>
                                        }
                                    })}
                                    <td>{d.noteScale}</td>
                                    {/* <!-- Datos para el periodo 1 --> */}
                                </tr>
                            ))}
                            {/* <!-- Filas de datos para cada periodo --> */}
                        </tbody>
                    </table>
                    {/* <div class="estado-promocion">Estado de Promoción: Promovido</div> */}
                </section>
                <FooterActa
                  creadorId={boletin.tutorId??0}
                  reportTypeId={types.reportTypes.BOLETIN}></FooterActa>
                </div>
                
                </div>
                {/* <FooterActa></FooterActa> */}

                <br />
                    <button className="btn btn-primary center display-block" onClick={() => window.print()}>Imprimir</button>
                <br />

            </>
          )}
        </>
    );
  };
  
  export default RepoBoletinDiurna;