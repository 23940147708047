import React, { useEffect, useState } from 'react';
import utils from "../utils/generalFunctions";
import ProcesosPreventivosView from './procesosPreventivos.js';
import EvaluacionPreventivaView from './evaluacionPreventiva.js';


const PlantillaPreventivaView = () => {
    const [activeTab, setActiveTab] = useState("tab-1");
    const [showConfiView, setShowConfiView] = useState(false);

    const validarPermisos = ()=> {
        const user = utils.getUserInformation();
        if(user && user.userTypeId<=5){
            setShowConfiView(true);
        }
    }

    useEffect(() => {
        validarPermisos();
    }, []);

    return (
        <div className="col-12 col-lg-12">
            {showConfiView && (
                <div className="tab">
                    <ul className="nav nav-tabs" role="tablist">
                        <li className="nav-item" role="presentation" >
                            <a 
                                className={`nav-link ${activeTab === "tab-1" ? "active" : ""}`}
                                data-bs-toggle="tab"
                                role="tab"
                                aria-selected={activeTab === "tab-1"}
                                onClick={() => setActiveTab("tab-1")}
                            >
                                Evaluación preventiva
                            </a>
                        </li>
                        <li className="nav-item" role="presentation">
                            <a
                                className={`nav-link ${activeTab === "tab-2" ? "active" : ""}`}
                                data-bs-toggle="tab"
                                role="tab"
                                aria-selected={activeTab === "tab-2"}
                                onClick={() => setActiveTab("tab-2")}
                            >
                                Procesos preventivos
                            </a>
                        </li>
                    </ul>
                    <div className="tab-content">
                        <div className={`tab-pane ${activeTab === "tab-1" ? "active show" : ""}`} id="tab-1" role="tabpanel">
                            <EvaluacionPreventivaView />
                        </div>
                        <div className={`tab-pane ${activeTab === "tab-2" ? "active show" : ""}`} id="tab-2" role="tabpanel">
                            <ProcesosPreventivosView />
                        </div>
                    </div>
                </div>
            )}
            {!showConfiView && (
                <EvaluacionPreventivaView />
            )}
        </div>
    );
};

export default PlantillaPreventivaView;
