import api from './api';

const getGrades = async () => {
    try {
        const response = await api.get('/grade');
        return response.data;
    } catch (error) {
        throw error;
    }
};

const getAllGrades = async () => {
    try {
        const response = await api.get('/grade/all');
        return response.data;
    } catch (error) {
        throw error;
    }
};

const getGradesWithStudentAmount = async () => {
    try {
        const response = await api.get('/grade/student-amount');
        return response.data;
    } catch (error) {
        throw error;
    }
};



const getGradeById = async (gradeId) => {
    try {
        const response = await api.get(`/grade/${gradeId}`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

const addGrade = async (grade) => {
    try {
        const response = await api.post('/grade', grade);
        return response.data;
    } catch (error) {
        throw error;
    }
};

const updateGrade = async (grade) => {
    try {
        const response = await api.put(`/grade`, grade);
        return response.data;
    } catch (error) {
        throw error;
    }
};

const deleteGrade = async (gradeId) => {
    try {
        const response = await api.delete(`/grade/${gradeId}`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

// Exportando todas las funciones como un objeto
export default {
    getGrades,
    addGrade,
    updateGrade,
    deleteGrade,
    getAllGrades,
    getGradesWithStudentAmount,
    getGradeById
};
