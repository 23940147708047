import React, { useEffect, useState } from "react";
import "../../css/StudentForm.css";
import userProfileImage from "../../img/user-profile.png";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import Loading from "../Loading";
import userService from "../../services/userService";

function UsuarioForm(props) {
  const { user, onClose, ReadOnly } = props; // Definir el estado para gestionar los datos del formulario.
  
  const initData = {
    userId: 0,
    showImageProfile: userProfileImage,
    urlImage: null,
    identification: "", // Identificación del docente
    userTypeId: "", // Tipo de identificación
    userName: "", //  nombre
    email: "", // Email
    password: "",
    institutionalEmail: "", // Género
    isActive: true, // Estado activo o inactivo
};
const [formData, setFormData] = useState(initData); // Asumiendo que tienes una estructura de datos inicial para los docentes
const [showLoading, setShowLoading] = useState(true);
const [userTypes, setUserTypes] = useState([]);
const [showUpdatePass, setShowUpdatePass] = useState(false);
const [passwords, setPasswords] = useState({
  newPassword: '',
  verifyPassword: '',
  userId: user?.userId??"0"
});

useEffect(() => {
  const fetchCollections = async () => {
    try {
      const userTypesResp = await userService.getUserTypes();
      if(user){
        setUserTypes(userTypesResp);
      }else{
        setUserTypes(userTypesResp.filter(t=>t.userTypeId<5));
      }
      // Añadir aquí el código para recuperar cualquier dato inicial que necesites para el formulario de docentes.
      if (user) {
        const userResp = await userService.getUserById(user.userId); // Asumiendo que tienes un servicio para recuperar detalles del docente
        console.log(userResp);
        if (userResp.urlImage) {
          setFormData((prevState) => ({
            ...prevState,
            showImageProfile: userResp.urlImage,
          }));
        }
        setFormData((prevState) => ({ ...prevState, ...userResp }));
      }
      setShowLoading(false);
    } catch (error) {
      console.error("Error al obtener listas de formulario:", error);
    }
  };

  fetchCollections();
}, [user]);

const handleFileChange = (e) => {
  const file = e.target.files[0];
  if (file) {
    const reader = new FileReader();
    reader.onload = (event) => {
      if(e.target.id==="btnProfile"){
        document.getElementById("urlImage").src = event.target.result;
        setFormData((prevState) => ({ ...prevState, urlImage: file }));
      }
    };
    reader.readAsDataURL(file);
  }
}

const handleInputChange = (event) => {
  const { name, value } = event.target;
  setFormData((prevState) => ({ ...prevState, [name]: value }));
};

const handleSubmit = async (event) => {
  event.preventDefault();

    if (
      !formData.identification || // Identificación del docente
      !formData.userTypeId || // Tipo de identificación
      !formData.userName || // Primer nombre
      !formData.email
    ) {
      toast.error("Por favor, completa todos los campos obligatorios.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return;
    }
    if (!ReadOnly) {
      setShowLoading(true);
      try {
        let userId = 0;
        const subirImagen =
        (
          formData.urlImage &&
          formData.urlImage !== formData.showImageProfile
        );

        if(subirImagen){
          formData.uploadImage = { file: formData.urlImage }
          formData.urlImage=null;
        }

        if (formData.userId > 0) {
          const response = await userService.updateUser(formData);
          userId = response.userId;
        } else {
          const response = await userService.createUser(formData);
          userId = response.userId;
        }
        
        if(subirImagen && userId>0) {
          
            try {
              const imgResponse = await userService.subirImagenUsuarioAdmin(
                formData.uploadImage.file,
                userId
              );
              console.log(imgResponse);
            } catch (error) {
              console.error(
                "Hubo un error al subir imagen del usuario:",
                error
              );
            }
        }
        
        Swal.fire(
          "Felicidades!",
          "Información del usuario guardada con éxito!",
          "success"
        );
        
        if (onClose) onClose();
        setFormData(initData); // Asumiendo que tienes un estado inicial para docentes
      } catch (error) {
        console.error("Hubo un error al guardar la información del usuario:", error);
        Swal.fire(
          "Ha ocurrido un error",
          "Por favor intentelo màs tarde",
          "error"
        );
      }
      setShowLoading(false);
    };
  }

  const habilitarCambiarPass = (e) =>{
    e.preventDefault();
    setShowUpdatePass(true);
  }

    // Manejo de cambios en las contraseñas
    const handleChangePassword = (e) => {
      const { name, value } = e.target;
      setPasswords(prevState => ({
        ...prevState,
        [name]: value,
      }));
    };
    

    // Envío del formulario de cambio de contraseña
  const handleSubmitPassword = async (e) => {
    e.preventDefault();
    
    if (passwords.newPassword !== passwords.verifyPassword) {
      toast.error("Por favor, verifica la nueva contraseña, las contraseñas no coinciden.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return;
    }

    setShowLoading(true);
    try {
      const result = await userService.updateUserPasswordAdmin(passwords);
      if(result.data){
        Swal.fire(
          "Felicidades!",
          "Contraseña guardada con éxito!",
          "success"
        );
        setPasswords(prevState => ({
          ...prevState,
          currentPassword: '',
          newPassword: '',
          verifyPassword: '',
        }));
        setShowUpdatePass(false);
      }else{
        Swal.fire(
          "Ha ocurrido un error",
          "Por favor intentelo mas tarde",
          "error"
        );
      }
    } catch (error) {
      const message = error?.response?.data?.message??'Intente más tarde.';
      Swal.fire("Ha ocurrido un error",message,"error");
      console.error("Hubo un error al actualizar contraseña:",error);
    }
    setShowLoading(false);
    // Lógica para enviar los datos de cambio de contraseña
  };

  return (
    <>
      {showLoading && <Loading />}

      {!showLoading && (
        <form onSubmit={handleSubmit}>
          <div className="card-inf containerForm">
            <div className="card-header bg-light text-center">
            <h5 className="card-title mb-0">
            <br />
                INFORMACIÓN DEL USUARIO
                <br />
            </h5>
        </div>
        <div className="card-body">
            <div className="row">
                <div className="col-md-4">
                    <label className="col-form-label  text-sm-right">
                        Identificación del Usuario*
                    </label>
                    <input
                        type="text"
                        className="form-control validarForm"
                        name="identification"
                        value={formData.identification}
                        onChange={handleInputChange}
                        title="Por favor, ingrese solo números."
                        readOnly={ReadOnly}
                        required
                    />
                </div>
                {/* Tipo de identificación */}
                <div className="col-md-4">
                  <label className="col-form-label  text-sm-right">Tipo de usuario*</label>
                  <select
                    className="form-control form-select validarForm"
                    name="userTypeId"
                    value={formData.userTypeId}
                    onChange={handleInputChange}
                    readOnly={(ReadOnly || user!==null)}
                    required
                  >
                    <option value="" disabled={ReadOnly || user!==null}>
                      Seleccione
                    </option>
                    {userTypes.map((option) => (
                      <option
                        key={option.userTypeId}
                        value={option.userTypeId}
                        disabled={ReadOnly || user!==null}
                      >
                        {option.userTypeName}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="col-md-4 d-flex flex-column align-items-center">
                    <div
                        className="profile-picture bg-light mt-4"
                        style={{
                            width: "auto",
                            height: "150px",
                            overflow: "hidden",
                        }}
                    >
                        <img
                            id="urlImage"
                            src={formData.showImageProfile}
                            alt="Foto de perfil"
                            style={{
                                width: "100%",
                                height: "100%",
                                objectFit: "cover",
                            }}
                        />
                    </div>
                    {!ReadOnly  && (
                    <label className="btn btn-primary">
                        Subir Foto de perfil:*
                        <input
                            type="file"
                            id="btnProfile"
                            className="form-control d-none"
                            onChange={handleFileChange}
                            readOnly={ReadOnly}
                        />
                    </label>
                    )}
                </div>
                <div className="col-md-3">
                    <label className="col-form-label  text-sm-right">Nombre de usuario*</label>
                    <input
                        type="text"
                        className="form-control validarForm"
                        name="userName"
                        title="Por favor, ingrese solo letras."
                        value={formData.userName}
                        onChange={handleInputChange}
                        readOnly={ReadOnly}
                        required
                    />
                </div>
                {/* Email */}
                <div className="col-md-3">
                    <label className="col-form-label  text-sm-right">
                        Email*
                    </label>
                    <input
                        type="email"
                        className="form-control validarForm"
                        name="email"
                        value={formData.email}
                        onChange={handleInputChange}
                        readOnly={ReadOnly}
                        required
                    />
                </div>
                {/* Email */}
                <div className="col-md-3">
                    <label className="col-form-label  text-sm-right">
                        Email Institucional*
                    </label>
                    <input
                        type="institutionalEmail"
                        className="form-control validarForm"
                        name="institutionalEmail"
                        value={formData.institutionalEmail}
                        onChange={handleInputChange}
                        readOnly={ReadOnly}
                    />
                </div>

                {!ReadOnly && user && !showUpdatePass  && (
                    <div className="row">
                      <div className="col-md-3">
                        <button
                          className="btn btn-primary button-form"
                          onClick={habilitarCambiarPass}
                        >
                          Cambiar contraseña
                        </button>
                      </div>
                    </div>
                  )}

                {showUpdatePass && (
                  <>
                    <div className="row padding-top-20">
                      <div className="col-md-4">
                        <label htmlFor="newPassword" className="col-form-label  text-sm-right">Nueva Contraseña *</label>
                        <input type="password" className="form-control" id="newPassword" name="newPassword" value={passwords.newPassword} onChange={handleChangePassword} />
                      </div>
                      <div className="col-md-4">
                        <label htmlFor="verifyPassword" className="col-form-label  text-sm-right">Verificar Nueva Contraseña *</label>
                        <input type="password" className="form-control" id="verifyPassword" name="verifyPassword" value={passwords.verifyPassword} onChange={handleChangePassword} />
                      </div>
                      <div className="col-md-3 padding-top-20">
                        <button
                          className="btn btn-primary button-form"
                          onClick={handleSubmitPassword}
                        >
                          Guardar contraseña
                        </button>
                      </div>
                    </div>
                  </>
                )}

                {!ReadOnly && !user && (
                    <div className="col-md-3">
                      <label htmlFor="password" className="col-form-label  text-sm-right">Contraseña*</label>
                      <input type="password" className="form-control" id="password" name="password" value={formData.password} onChange={handleInputChange} />
                  </div>
                )}

                {/* Activo */}
                <div className="col-md-8 mb-3 padding-top-20 padding-left-10">
                    <input type="checkbox" className="form-check-input" id="isActive" name="isActive" checked={formData.isActive} onChange={e => setFormData({...formData, isActive: e.target.checked})}  disabled={ReadOnly} />
                    <label className="form-check-label" htmlFor="isActive">Activo</label>
                </div>

                <div className="d-flex justify-content-center mb-4">
                 
                  {!ReadOnly  && (
                    <button
                      type="submit"
                      className="btn btn-primary button-form"
                    >
                      Guardar usuario
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </form>
      )}
    </>
  );
}

export default UsuarioForm;
