const useCompactDataTableStyles = (additionalStyles = {}) => {
  const defaultStyles = {
    rows: {
      style: {
        minHeight: "24px", // Altura mínima de las filas reducida
        backgroundColor: "var(--bs-light-bg-subtle)", // Fondo claro para las filas
        color: "var(--bs-dark-text-emphasis)", // Texto oscuro para contraste
        "&:nth-of-type(odd)": {
          backgroundColor: "#00000000", // Fondo para las filas impares
        },
        "&:nth-of-type(even)": {
          backgroundColor: "#4950570d", // Fondo para las filas pares
        },
        "&:hover": {
          backgroundColor: "var(--bs-secondary-bg-subtle)", // Fondo al pasar el mouse
        },
      },
    },
    headCells: {
      style: {
        backgroundColor: "var(--bs-light)", // Fondo específico para las celdas del encabezado
        color: "#1659c7", // Texto oscuro para el encabezado
        fontSize: "12px", // Tamaño de fuente más pequeño
        fontWeight: "700", // Negrita para el texto del encabezado
        textTransform: "uppercase",
        letterSpacing: "0.03em",
        border: "none",
        padding: "4px 8px", // Padding reducido
      },
    },
    cells: {
      style: {
        color: "var(--bs-heading-color)", // Texto oscuro para las celdas
        fontSize: "12px", // Tamaño de fuente más pequeño
        fontWeight: "var(--bs-body-font-weight)",
        padding: "4px 8px", // Padding reducido
        border: "none",
        borderBottom: "1px solid var(--bs-light-border-subtle)", // Borde sutil para las celdas
        "&:hover": {
          cursor: "pointer",
          backgroundColor: "var(--bs-secondary-bg-subtle)", // Fondo al pasar el mouse
        },
      },
    },
    pagination: {
      style: {
        backgroundColor: "var(--bs-light)", // Fondo claro para la paginación
        color: "var(--bs-dark)", // Texto oscuro para la paginación
        borderTop: "1px solid var(--bs-light-border-subtle)", // Borde superior sutil
        fontSize: "12px", // Tamaño de fuente más pequeño
        padding: "4px", // Padding reducido
        minHeight: "32px", // Altura mínima reducida
      },
    },
    noData: {
      style: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "10px",
        color: "var(--bs-gray-500)", // Color gris para texto sin datos
        fontSize: "14px", // Tamaño de fuente reducido
      },
    },
    // Añade aquí otros estilos por defecto si es necesario
  };

  // Fusiona los estilos por defecto con los adicionales proporcionados
  return { ...defaultStyles, ...additionalStyles };
};

export default useCompactDataTableStyles;
