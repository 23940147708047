import React, { useState, useEffect, useRef } from "react";
import api from "../services/estudiantesService";
import { Button } from "react-bootstrap";
import procesoService from "../services/procesoService";
import "../css/tabla-notas.css";
import JornadaAnioPeriodoNivelCursoAsig from "../components/Filters/JornadaAnioPeriodoNivelCursoAsig";
import utils from "../utils/generalFunctions";
import Swal from "sweetalert2";
import moment from "moment";
import { Tooltip } from "react-tooltip";
import preventiveNoteService from "../services/preventiveNoteService";
import collectionsService from "../services/collectionsService";
import evaluativeScaleService from "../services/evaluativeScaleService";
import Loading from "../components/Loading";
import types from "../services/types";
import TablaEvaluacionPreventiva from "./tablaEvaluacionPreventiva";

const EvaluacionPreventivaView = () => {
  const [estudiantes, setEstudiantes] = useState([]); // Almacenamos los estudiantes aquí
  const [procesos, setProcesos] = useState([]); // Almacenamos los procesos y sus notas aquí
  const [notas, setNotas] = useState([]); // Almacenamos los procesos y sus notas aquí
  const [notasTabla, setNotasTabla] = useState([]); // Almacenamos los procesos y sus notas aquí
  const [isLoading, setIsLoading] = useState(true); // Estado para el loading
  const [filters, setFilters] = useState({
    // Estado para los filtros aplicados
    teacherId: "0",
    journeyId: "0",
    schoolYearId: "0",
    educationLevelId: "0",
    courseId: "0",
  });
  const [paramPlanilla, setParamPlanilla] = useState({});
  const filteReference = useRef(null);
  const [showLoading, setShowLoading] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const [decimalesPlanilla, setDecimalesPlanilla] = useState(0);
  const [evaluationScale, setEvaluationScale] = useState([]);
  let notasTemp = [];

  const fetchLoadGeneralParams = async () => {
    try {
      const parameters = await collectionsService.getParameters();
      const decimales = parameters.find(
        (op) => String(op.keyName) === "decimalesPlanilla"
      );
      if (decimales) {
        setDecimalesPlanilla(Number(decimales.value));
      }
    } catch (error) {
      console.error("Error al obtener parametros de formulario:", error);
    }
  };

  useEffect(() => {
    fetchLoadGeneralParams();
  }, []);

  const cargarNotasPreventivas = async () => {
    const notasDB = await preventiveNoteService.getPreventivoNotasCourse(
      paramPlanilla.courseId,
      paramPlanilla.schoolSubjectId,
      paramPlanilla.schoolYearId,
      paramPlanilla.periodId
    );
    setNotas(notasDB);
    notasTemp = notasDB;
  };

  const cargarProcesos = async (
    courseId,
    schoolSubjectId,
    schoolYearId,
    academicPeriodId,
    evaluationTypeId 
  ) => {
    const procesosDB = await procesoService.getProcesosToNotas(
      courseId,
      schoolSubjectId,
      schoolYearId,
      academicPeriodId,
      evaluationTypeId
    );
    const notasDB = await preventiveNoteService.getPreventivoNotasCourse(
        courseId,
        schoolSubjectId,
        schoolYearId,
        academicPeriodId
      );
      setNotas(notasDB);
      notasTemp = notasDB;
    setProcesos(procesosDB);
    return procesosDB;
  };

  const fillNotes = (estudiantes, procesos) => {
    const notasBD = notasTemp.length > 0 ? notasTemp : notas;

    let notes = [];
      for (let i = 0; i < estudiantes.length; i++) {
        for (let j = 0; j < procesos.length; j++) {
            let nota = notasBD.find(
              (n) =>
                n.evaluationProcessId === Number(procesos[j].evaluationProcessId) &&
                n.studentId === Number(estudiantes[i].studentId)
            );
            notes[`${estudiantes[i].studentId}-${procesos[j].evaluationProcessId}`] = nota? {
              evaluativeScaleId:nota.evaluativeScaleId,
              preventiveEvaluateId: nota.preventiveEvaluateId
            } : ""; 
        }
      }
      setNotasTabla(notes);
  };

  const handleFilterChange = (newFilters) => {
    // Actualiza el estado de filters con los nuevos valores
    setFilters((prev) => ({
      ...prev,
      ...newFilters,
    }));
  };

  const cargarPlanilla = async (e) => {
    e.preventDefault(); // Añadir esta línea para prevenir el comportamiento predeterminado del botón
    setShowLoading(true);
    setIsLoading(true); // Cambia el estado a false una vez que los datos se carguen
    const copyData = utils.copy(filters);
    const cursos = filteReference?.current?.getCursos();
    if (cursos.length > 0) {
      copyData.gradeId = cursos.find(
        (c) => String(c.courseId) === String(copyData.courseId)
      )?.gradeId;
    }
    const educationLevels = filteReference?.current?.getEducationLeves();
    if (educationLevels.length > 0) {
      copyData.evaluationTypeId = educationLevels.find(
        (c) => String(c.educationLevelId) === String(copyData.educationLevelId)
      )?.evaluationTypeId;
    }
    copyData.evaluationTypeId = types.evaluationTypes.PLANILLA_PREVENTIVA;
    setParamPlanilla(copyData);
    try {
      const prevData = await api.getEstudiantesPorCurso(
        "",
        copyData.journeyId,
        copyData.schoolYearId,
        copyData.educationLevelId,
        copyData.courseId
      ); // Obtenemos los estudiantes de tu sistema
      const data = prevData.filter(s=>s.isActive);
      setEstudiantes(data); // Guardamos los estudiantes en el estado
      const evaluationScale = await evaluativeScaleService.getEvaluativeScale(
        copyData.evaluationTypeId
      );
      setEvaluationScale(evaluationScale);
      const process = await cargarProcesos(
        copyData.courseId,
        copyData.schoolSubjectId,
        copyData.schoolYearId,
        copyData.periodId,
        copyData.evaluationTypeId
      );
      fillNotes(data, process);
    } catch (error) {
      console.error("Error al obtener estudiantes:", error);
    }
    setIsLoading(false); // Cambia el estado a false una vez que los datos se carguen
    setShowLoading(false);
    setShowSearch(true);
  };

  // Aquí renderizamos el componente
  return (
    <>
      <div className="card p-3 mb-3">
        <h1>Evaluación preventiva</h1>
        <div className="card-body">
          <form className="row mb-3 align-items-end" onSubmit={cargarPlanilla}>
            {/* Añadir onSubmit al formulario */}
            <JornadaAnioPeriodoNivelCursoAsig
              callback={handleFilterChange}
              ref={filteReference}
              properties={{
                className: "col-md-4",
                id: "formEvPrev",
                filtro: false,
              }}
            />
            <div className="col-md-4 mb-2 d-flex text-end align-items-end">
              <button
                type="submit"  // Cambiar el tipo del botón a submit
                className="btn btn-primary w-100"
              >
                Buscar
              </button>
            </div>
          </form>
        </div>
      </div>

      {showLoading && <Loading />}

      {showSearch && (
        <>
          {!showLoading && (
            <div className="card p-3">
              <div className="d-flex justify-content-between align-items-center my-4">
                <h1 className="text-center my-4">Evaluación preventiva</h1>
              </div>

              {paramPlanilla.evaluationTypeId === types.evaluationTypes.PLANILLA_PREVENTIVA && (
                <div className="row">
                  <div
                    className="alert alert-primary alert-outline alert-dismissible"
                    role="alert"
                  >
                    <button
                      type="button"
                      className="btn-close display-none"
                      data-bs-dismiss="alert"
                      aria-label=""
                    ></button>
                    <div className="alert-icon">
                      <i className="far fa-fw fa-bell"></i>
                    </div>
                    <div className="alert-message text-center">
                      {evaluationScale.map((scale, index) => (
                        <span key={index} className="margin-left-10">
                          {scale.name}{" "}
                          <strong style={{ color: scale.color }}>
                            {" ("} {scale.abbreviation} {") "}
                          </strong>
                        </span>
                      ))}
                    </div>
                  </div>
                </div>
              )}

              <div className="custom-table div-table-notas scroll-table">
                {paramPlanilla.evaluationTypeId === types.evaluationTypes.PLANILLA_PREVENTIVA && (
                  <TablaEvaluacionPreventiva
                    students={estudiantes}
                    process={procesos}
                    evaluationScale={evaluationScale}
                    tablaNotes={notasTabla}
                    paramPlanilla={paramPlanilla}
                    refreshNotas={cargarNotasPreventivas}
                  />
                )}
              </div>

              {paramPlanilla.evaluationTypeId === types.evaluationTypes.PLANILLA_PREVENTIVA && (
                <div className="row">
                  <hr />
                  <div className="col-md-12 scroll-table">
                    <div className="card definicio definicion-notas-container">
                      <div className="card-header definicion-notas-header">
                        <h5 className="card-title mb-0 definicion-notas-title">
                          Procesos
                        </h5>
                      </div>
                      <div className="card-body definicion-notas-body scroll-table">
                        <table className="table table-sm my-2 definicion-notas-tabla">
                          <tbody>
                            {procesos.map((proceso, indexNota) => (
                              <tr key={indexNota}>
                                <th>Proceso {indexNota + 1}:</th>
                                <td>{proceso.description}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <Tooltip id="my-tooltip" />
            </div>
          )}
        </>
      )}
    </>
  );
};

export default EvaluacionPreventivaView;
