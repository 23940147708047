import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { ProgressBar } from 'react-bootstrap'; // Asegúrate de tener instalado react-bootstrap
import DashboardCard from '../../components/TituloDashboard';
import studentPhoto from '../../img/user-profile.png';
import FiltroPeriodoEscolar from '../../components/filtroperiodoescolarEstudianteNotasVista';
import util from '../../utils/generalFunctions';
import organizacionAcademicaService from '../../services/organizacionAcademicaService';
import moment from 'moment';
import estudiantesService from '../../services/estudiantesService';
import evaluativeScaleService from '../../services/evaluativeScaleService';
import collectionsService from '../../services/collectionsService';
import "../../css/tabla-notas-viewEstudiantes.css";
import reportsService from '../../services/reportsService';
import { toast } from 'react-toastify';


const NotasEstudiantePc = () => {
  const studentInfo = util.getStudentInformation();
  const [listaAsignaturas, setListaAsignaturas] = useState([]);
  const [listaNotas, setListaNotas] = useState([]);
  const [notas, setNotas] = useState(null);
  const [evaluationScale, setEvaluationScale] = useState([]);
  const [decimalesPlanilla, setDecimalesPlanilla] = useState(0);
  const filteReference = useRef(null);
  const [showRecuperation, setShowRecuperation] = useState(false);
  const [searchRecuperation, setSearchRecuperation] = useState(false);
  let listaNotasTemp = [];
  //
  const [formData, setFormData] = useState({
    periodId:'',
    schoolSubjectId:'',
  });

  
  const seleccionarAsignatura = (asignatura) => {

    setFormData(prevState => ({
      ...prevState,
      schoolSubjectId:asignatura.schoolSubjectId
    }));
  };

  const fetchLoadGeneralParams = async () => {
    try {
      const parameters = await collectionsService.getParameters();
      const decimales = parameters.find(
        (op) => String(op.keyName) === "decimalesPlanilla"
      );
      if (decimales) {
        setDecimalesPlanilla(Number(decimales.value));
      }
    } catch (error) {
      console.error("Error al obtener parametros de formulario:", error);
    }
  };

  const fetchLoadAsignaturas = async () => {
    const courseId = studentInfo.courseId;
    const schoolYearId = studentInfo.schoolYearId;
    const evaluationTypeId = studentInfo.evaluationTypeId;
    try{
      await fetchLoadGeneralParams();
      const evaluationScale = await evaluativeScaleService.getEvaluativeScale(evaluationTypeId);
      setEvaluationScale(evaluationScale);

      await cargarListaNotas();
      const asignaturas = await organizacionAcademicaService.getAcademicOrganizationsByCourse(courseId, schoolYearId);
      setListaAsignaturas(asignaturas);

      if(asignaturas!=null && asignaturas.length>0){
        setFormData(prevState => ({
          ...prevState,
          schoolSubjectId:asignaturas[0].schoolSubjectId
        }));
      }
    }catch(ex){
      console.error("Error al obtener listas de asignaturas:", ex);
    }
  }

  const updateForm = async (data)  => {
    setFormData(prevState => ({
        ...prevState,
        periodId:data
      }));
  }

  useEffect(() => {
    cargarNotas();

  }, [formData]);

  const cargarNotas= async () =>{
    await cargarListaNotas();
    if(formData.schoolSubjectId!=='' && formData.periodId!==''){   
      const result = await reportsService.getPermisosEstudiantes(
        studentInfo.studentId,
        studentInfo.schoolYearId,
        formData.periodId
      ); // Obtenemos los estudiantes de tu sistema
          const permiso = result.find(d=>d.reportTypeId===2 && d.visible===true);
      if(permiso){
        const studentId = studentInfo.studentId;
        const courseId = studentInfo.courseId;
        const schoolYearId = studentInfo.schoolYearId;
        try{
          const recuperation = searchRecuperation?1:0;
          const resultNotas = await estudiantesService.getEstudianteNota(studentId, courseId, formData.schoolSubjectId, schoolYearId, formData.periodId, recuperation);
          if(resultNotas && resultNotas.length > 0 ){
            const item = resultNotas[0];
            setNotas(item);
            console.log(item);
          }else{
            setNotas([]);
          }
          
          const lostEstudiantes = await estudiantesService.getEstudianteToNivelar(
            studentId,
            courseId,
            schoolYearId,
            formData.periodId
          ); // Obtenemos los estudiantes de tu sistema
          const estContent = lostEstudiantes.students.find(e=>e.studentId === studentId);
          if(estContent){
            const recuperation = estContent.areasNotes.filter(a=> a.subjectNotes && a.subjectNotes.length > 0);
            setShowRecuperation(recuperation.length>0);
          }

        }catch(ex){
          console.error("Error al obtener listas de asignaturas:", ex);
        }
      }
    }
  }

  const showMensajePermiso = () => {
    toast.info("Reporte no habilitado para este período", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
}

  const cargarListaNotas= async () =>{
    if(formData.periodId!==''){
      
      const result = await reportsService.getPermisosEstudiantes(
        studentInfo.studentId,
        studentInfo.schoolYearId,
        formData.periodId
      ); // Obtenemos los estudiantes de tu sistema
          const permiso = result.find(d=>d.reportTypeId===2 && d.visible===true);
          if(permiso){
          const studentId = studentInfo.studentId;
          const courseId = studentInfo.courseId;
          const schoolYearId = studentInfo.schoolYearId;
          try{
            const recuperation = searchRecuperation?1:0;
            const resultNotas = await estudiantesService.getEstudianteNota(studentId, courseId, "", schoolYearId, formData.periodId,recuperation);
            if(resultNotas && resultNotas.length > 0 ){
              setListaNotas(resultNotas);
              listaNotasTemp=resultNotas;
            }else{
              setListaNotas([]);
            }
          }catch(ex){
            console.error("Error al obtener listas de asignaturas:", ex);
          }
        }else{
          showMensajePermiso();
          setNotas([]);
        } 
    }
  }

  useEffect(() => {
    const listaAsig = util.copy(listaAsignaturas);
    listaAsig.map(a=> {
      a.change = (a.change)?false:true;
      return a;
    });
    setListaAsignaturas(listaAsig);
  }, [notas]);

  useEffect(() => {
    cargarNotas();
  }, [searchRecuperation]);

  const getPorcetajeAsignatura= (schoolSubjectId, listaNotas) =>{
      const filtro = (listaNotasTemp.length > 0)?listaNotasTemp:listaNotas;
      const asig = filtro.find(n=> n.schoolSubjectId===schoolSubjectId);
      if(asig){
          return asig.note;
      }else{
        return 0;
      }
  }

  const getNotaAsignatura= (schoolSubjectId, listaNotas) =>{
    const filtro = (listaNotasTemp.length > 0)?listaNotasTemp:listaNotas;
    const asig = filtro.find(n=> n.schoolSubjectId===schoolSubjectId);
    if(asig && asig.note){
        return getScaleFormatText(asig.note)
    }else{
      return " - ";
    }
}

  useEffect(() => {
    fetchLoadAsignaturas();
  }, []);

  const getScaleFormat = (totalNota) => {
    if(totalNota){
      const total = util.roundNumber(totalNota, decimalesPlanilla);
      const scale = evaluationScale.find(
        (s) => total >= s.minScore && total <= s.maxScore
      );

      if (scale) {
        return (
          <span style={{ color: scale.color }}>
            {total + " - " + scale.abbreviation}
          </span>
        );
      } else {
        return <span>{total}</span>;
      }
    }else{
      return <span> - </span>;
    }
  };

  const getScaleFormatText = (totalNota) => {
    if(totalNota){
      const total = util.roundNumber(totalNota, decimalesPlanilla);
      const scale = evaluationScale.find(
        (s) => total >= s.minScore && total <= s.maxScore
      );

      if (scale) {
        return total + " - " + scale.abbreviation;
      } else {
        return total;
      }
    }else{
      return " - ";
    }
  };

  return (
    <div className=" ">
        <div className="col-12 mb-3">
        <Link to="/dashboard" className="back-to-dashboard-pc">
                <FontAwesomeIcon icon={faArrowLeft} />
            </Link> 
      <DashboardCard
          studentPhoto={studentPhoto}
          title="Notas"
          subtitle="Detalles de tus notas"
        />

        </div>
        <div className="row">
          <div className='col-12 mb-3'>
              <div class="alert-message text-center">
                            {evaluationScale.map((scale, index) => (
                              <>
                                <span className="margin-left-10">
                                  {scale.name}{" "}
                                  <strong style={{ color: scale.color }}>
                                    {" "}
                                    {scale.abbreviation +
                                      " (" +
                                      scale.minScore.toFixed(decimalesPlanilla) +
                                      " - " +
                                      scale.maxScore.toFixed(decimalesPlanilla) +
                                      ") "}{" "}
                                  </strong>
                                </span>
                              </>
                            ))}
              </div>
          </div>
        <div className="card col-md-6 lista-asig-print">
        <FiltroPeriodoEscolar 
        schoolYearId={studentInfo.schoolYearId} 
        callback={updateForm}
        ref={filteReference} />
        <div className="lista-asig-print">
          {showRecuperation  && (<>
            <div className="col-md-12">
                <div class="form-check form-switch align-self-end text-end">

                    <input class="form-check-input float-none margin-right-5 align-self-end text-end" type="checkbox" id="chk-recuperation" name='chk-recuperation'
                    checked={searchRecuperation} 
                    onChange={(e)=> setSearchRecuperation(e.target.checked) } />
                    <label class="form-check-label" for="chk-recuperation">Ver notas de recuperación </label>
                </div>
            </div>
          </>)}
          <h3>Asignaturas</h3>
          {listaAsignaturas.map((asignatura, index) => (
            <div key={index} className="mb-3">
              <div className="d-flex justify-content-between align-items-center">
                <h4>{asignatura.schoolSubjectName}</h4>
                <a href='#titulo-notasPanel'>
                <button className="btn " onClick={() => seleccionarAsignatura(asignatura)}>
                  {/* <i class="fa-solid fa-angles-right"></i> */}
                  <small> Ver detalle <i class="fa-solid fa-chevron-right"></i></small>
                </button>
                </a>
              </div>
              <a href='#titulo-notasPanel'>
                <ProgressBar variant="primary" 
                onClick={() => seleccionarAsignatura(asignatura)}
                now={getPorcetajeAsignatura(asignatura.schoolSubjectId, listaNotas)} 
                label={`${getNotaAsignatura(asignatura.schoolSubjectId, listaNotas)}`} />
              </a>
            </div>
          ))}
        </div>
        </div>
        <div className=" col-md-6">
  {formData.schoolSubjectId && notas && (
    <div id="detalles-asignatura-container">
      <div className="card-header" id="cabecera-detalles">
        <h2 className='card-title mb-3' id="titulo-notasPanel">DETALLES DE {listaAsignaturas.find(s => s.schoolSubjectId === formData.schoolSubjectId).schoolSubjectName}</h2>
        <h4 className='card-subtitle' id="nota-definitiva">Nota Definitiva: {getScaleFormat(notas.note)}</h4>
      </div>
      {notas?.processNotes?.map((proceso, indexProceso) => (
        <div className='card-body proceso-detalle' key={indexProceso} id={`detalle-proceso-${indexProceso}`}>
        <h4 className='card-subtitle text mb-4 proceso-titulo' id={`titulo-proceso-${indexProceso}`}><strong>
          {proceso.evaluationProcessName}</strong> <span className="badge-subtle-info"> {" (" + proceso.evaluationProcessProcentage + "%)"}</span> <br/>Nota: {getScaleFormat(proceso.note)}
        </h4>
        <table className="dataTables_wrapper dt-bootstrap5 table-notas" id={`tabla-evaluaciones-${indexProceso}`}>
          <thead>
            <tr>
              <th className="descripcion-column" id={`th-descripcion-${indexProceso}`}>Descripción</th>
              <th className="nota-column" id={`th-nota-${indexProceso}`}>Nota</th>
            </tr>
          </thead>
          <tbody>
            {proceso?.definitionNotes?.map((evaluacion, indexEvaluacion) => (
              <tr key={indexEvaluacion} className="fila-evaluacion" id={`fila-evaluacion-${indexProceso}-${indexEvaluacion}`}>
                <td className="descripcion-evaluacion" id={`td-descripcion-${indexProceso}-${indexEvaluacion}`}>
                  {evaluacion.notesDefinitionName}
                  <br />
                  <small className="fecha-evaluacion" id={`fecha-evaluacion-${indexProceso}-${indexEvaluacion}`}>
                    {moment(evaluacion.evaluationDate).format("yyyy-MM-DD")}
                  </small>
                </td>
                <td className="nota-evaluacion" id={`td-nota-${indexProceso}-${indexEvaluacion}`}>
                  {getScaleFormat(evaluacion.note)}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      
      ))}
      <h5 id="nota-final-asignatura">Nota Final de {listaAsignaturas.find(s => s.schoolSubjectId === formData.schoolSubjectId)?.schoolSubjectName}: {getScaleFormat(notas.note)}</h5>
    </div>
  )}
</div>

    </div>
    </div>
  );
};

export default NotasEstudiantePc;