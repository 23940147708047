
import { useEffect, useRef, useState } from "react";
import HeaderActa from "../../../components/headerActas";
import FooterActa from "../../../components/FooterActa";
import moment from "moment";
import utils from "../../../utils/generalFunctions";
import types from "../../../services/types";
import estudiantesService from "../../../services/estudiantesService";


const RepoObservacionesActa = ({ reporte }) => {
    const filteReference = useRef(null);
    const [showLoading, setShowLoading] = useState(false);
    const [process, setProcess] = useState([]);
    const [aspectos, setAspectos] = useState([]);
    const [parent, setParent] = useState([]);
    const [todayDay, setTodayDay] = useState(moment());
    const student = reporte.student;

    const cargarProcesos =  () => {
        const aspectos = utils.removeDuplicates(reporte.evaluationProcess.map((as) => {return {evaluationType: as.evaluationType, evaluationTypeId: as.evaluationTypeId}}));
        setAspectos(aspectos);
    }

    const cargarPadres = async (studentId) => {
        try {
            const parents = await estudiantesService.getParents(studentId);              
            if(parents && parents.length>0){
                let main = parents[0];
                parents.forEach(p=>{
                    if(p.isMainParent){
                        main=p;
                    }
                });
                setParent(main);
            }            
        } catch (error) {
             console.error("Error al obtener información de padres:", error);
        }
      }

    //getParents

    useEffect(()=>{
        cargarProcesos();
        cargarPadres(student.studentId);
    },[]);

    const getMes = (mes) =>{
        switch(mes){
            case "1":return "Enero";
            case "2":return "Febrero";
            case "3":return "Marzo";
            case "4":return "Abril";
            case "5":return "Mayo";
            case "6":return "Junio";
            case "7":return "Julio";
            case "8":return "Agosto";
            case "9":return "Septiembre";
            case "10":return "Octubre";
            case "11":return "Noviembre";
            case "12":return "Diciembre";
            default:return "Enero";
        }
    }
   
    return (
        <>
          {!showLoading && reporte && (

            <>
                <div className="col-md-3 mb-2 non-printable">
                        <label htmlFor="txtAsistenciaDate" className="col-form-label  text-sm-right">Fecha Informe: </label>
                        <input
                            id="txtAsistenciaDate"
                            type="date"
                            className="form-control "
                            value={todayDay.format("yyyy-MM-DD")}
                            onChange={(e) => setTodayDay(moment(e.target.value))}/>
                </div>
                <div class="card acta-container">
                
                    {/* <!-- Cabezera --> */}
                    <div className="card-body">
                    
                        <HeaderActa />
                        {/* <!-- Ficha de Estudiante --> */}
                        <section class="acta-atencion">
                            <h2 class="acta-atencion-titulo">OBSERVADOR</h2>
                            <p>En la ciudad de Barranquilla, siendo los {todayDay.format("DD \\d\\í\\a\\s \\d\\e\\l \\m\\e\\s \\d\\e ")} {getMes(String(todayDay.month()+1))} {todayDay.format(" \\d\\e YYYY")}, se cita al señor(a) <strong>{parent?.fullName} con {parent?.identificationTypeName} {parent?.identification}</strong> de BARRANQUILLA, con el fin de entregar INFORME DEL OBSERVADOR DEL <strong> {reporte.student.periodName.toUpperCase()} ACADÉMICO</strong> del estudiante <strong>{reporte.student.studentFullName?.toUpperCase()}</strong> matriculado en el grado <strong>{reporte.student.grade}</strong> :</p>
                        </section>

                        {aspectos?.map((asp, index) => (

                            <section class="incidencia-info">
                                <table class="table-corte-preventivo">
                                    <thead>
                                        <tr>
                                            <th colSpan={3} >ASPECTO: {asp.evaluationType}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {reporte.evaluationProcess?.filter(e=>e.evaluationTypeId === asp.evaluationTypeId)?.map((ev, indexE)=>{
                                            <tr>
                                                <td>{indexE+1}</td>
                                                <td>{ev.evaluationProcess}</td>
                                                <td>{ev.evaluativeScale}</td>
                                            </tr>
                                        })}
                                    </tbody>
                                </table>
                            </section>
                        ))}

                        <br />
                        <FooterActa 
                            creadorId={reporte.student.groupDirectorId??0}
                            reportTypeId={types.reportTypes.ACTA}></FooterActa>
                    </div>

                </div>

                {/* <FooterActa></FooterActa> */}

                <br />
                    <button className="btn btn-primary center display-block" onClick={() => window.print()}>Imprimir</button>
                <br />

            </>
          )}
        </>
    );
  };
  
  export default RepoObservacionesActa;