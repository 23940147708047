import React from 'react';
import ReactDOM from 'react-dom/client';
import './css/light.css'
import 'react-toastify/dist/ReactToastify.css';
import './css/ReactSelectSearch.css';

import App from './App';

//sidebar-toggle
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);