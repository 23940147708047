import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faEnvelope, faPhone, faChalkboardTeacher, faHome, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import '../../css/DashboardEstudiante.css'; // Asegúrate de crear este archivo CSS para estilizar tu componente
import studentPhoto from '../../img/foto-estudiante.png';
import { Link } from 'react-router-dom';


const InformacionBasicaEstudiante = () => {
    // Datos ficticios basados en las imágenes que proporcionaste
    const datosEstudiante = {
        nombreCompleto: "Ana María López",
        foto: studentPhoto, // Reemplaza con el path de la imagen real        grado: "10 B",
        email: "ana.lopez@example.com",
        celular: "123-456-7890",
        acudiente: "José López",
        celularAcudiente: "098-765-4321",
        emailAcudiente: "jose.lopez@example.com",
        direccion: "Calle Falsa 123, Springfield",
        nombre: "Ana María López",
        curso: "10 B",
        grado: "Décimo",
        nivelEducativo: "Secundaria",
        anio: "2024",
        // ... otros datos que puedas necesitar
    };

    return (
        <div className="informacion-estudiante-container">
                       <Link  to="/dashboard-estudiante" className="back-to-dashboard">
                        <FontAwesomeIcon icon={faArrowLeft} />
                        </Link>    
            <div className="card-estudiante-info">
            <img src={studentPhoto} alt="Foto del estudiante" className="foto-estudiante rounded-circle rounded me-2 mb-2"/>
                <h2 className="nombre-estudiante">{datosEstudiante.nombre}</h2>
                <p className="info-estudiante">{datosEstudiante.curso} - {datosEstudiante.nivelEducativo} - Año {datosEstudiante.anio}</p>
                
            </div>
            <div className="informacion-basica">
                <h3 className="subtitulo">Datos del Estudiante</h3>
                <div className="info-item">
                    <FontAwesomeIcon icon={faUser} className="fa-icon" />
                    <span>Nombre completo: {datosEstudiante.nombreCompleto}</span>
                </div>
                <div className="info-item">
                    <FontAwesomeIcon icon={faChalkboardTeacher} className="fa-icon" />
                    <span>Grado: {datosEstudiante.grado}</span>
                </div>
                <div className="info-item">
                    <FontAwesomeIcon icon={faEnvelope} className="fa-icon" />
                    <span>Email: {datosEstudiante.email}</span>
                </div>
                <div className="info-item">
                    <FontAwesomeIcon icon={faPhone} className="fa-icon" />
                    <span>Celular: {datosEstudiante.celular}</span>
                </div>
                <hr />
            </div>
            
            {/* Datos del Acudiente */}
            <div className="informacion-basica">
                <h3 className="subtitulo">Datos del Acudiente</h3>
                <div className="info-item">
                    <FontAwesomeIcon icon={faUser} className="fa-icon" />
                    <span>Nombre: {datosEstudiante.acudiente}</span>
                </div>
                <div className="info-item">
                    <FontAwesomeIcon icon={faPhone} className="fa-icon" />
                    <span>Teléfono: {datosEstudiante.celularAcudiente}</span>
                </div>
                <div className="info-item">
                    <FontAwesomeIcon icon={faEnvelope} className="fa-icon" />
                    <span>Email: {datosEstudiante.emailAcudiente}</span>
                </div>
                <hr />
            </div>
            
            {/* Otros Datos */}
            <div className="informacion-basica">
                <h3 className="subtitulo">Otra Información</h3>
                <div className="info-item">
                    <FontAwesomeIcon icon={faHome} className="fa-icon" />
                    <span>Dirección: {datosEstudiante.direccion}</span>
                </div>
                {/* Agrega aquí más items de información si es necesario */}
            </div>
            {/* ... */}

        </div>
    );
};

export default InformacionBasicaEstudiante;
