import React, { useEffect, useRef, useState } from "react";
import DataTable from "react-data-table-component";
import "../css/estudiantes.css";
import {
    MDBBtn,
    MDBModal,
    MDBModalDialog,
    MDBModalContent,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBModalFooter,
} from 'mdb-react-ui-kit';
import utils from '../utils/generalFunctions';
import { toast } from 'react-toastify';
import Swal from "sweetalert2";
import JornadaAnioPeriodoNivelCursoAsig from "../components/Filters/JornadaAnioPeriodoNivelCursoAsig";
import { Button } from "react-bootstrap";
import Loading from "../components/Loading";
import useCustomDataTableStyles from "../components/estilosDeDataTable";
import asistenciaService from "../services/asistenciaService";
import api from "../services/estudiantesService";
import moment from "moment";
import types from "../services/types";

const AsistenciaView = () => {

    const [filters, setFilters] = useState({
        teacherId: '0',
        journeyId: '0',
        schoolYearId: '0',
        educationLevelId: '0',
        courseId: '0'
    });
    const [parametros, setParametros] = useState({});
    const filteReference = useRef(null);
    const [showLoading, setShowLoading] = useState(false);
    const [asistencias, setAsistencias] = useState([]);
    const [estudiantes, setEstudiantes] = useState([]);
    const [asistenciaSelected, setAsistenciaSelected] = useState(null);
    const [asistenciaId, setAsistenciaId] = useState(null);
    const [asistenciaDate, setAsistenciaDate] = useState(moment().format("yyyy-MM-DD"));
    const [showSearch, setShowSearch] = useState(false);
    const [filterUserId, setFilterUserId] = useState(0);

    const customStyles = useCustomDataTableStyles();

    const handleFilterChange = (newFilters) => {
        setFilters(prev => ({
            ...prev,
            ...newFilters
        }));
    };

    const validateBusqueda = () => {
        const requiredFields = ['teacherId', 'journeyId', 'schoolYearId', 'periodId', 'educationLevelId', 'courseId', 'schoolSubjectId'];
        for (let field of requiredFields) {
            if (!filters[field] || filters[field] === "0") {
                toast.error("Por favor, selecciona todos los campos obligatorios.", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                return false;
            }
        }
        return true;
    }

    const cargarAsistencias = async () => {
        if (validateBusqueda()) {
            setShowLoading(true);
            cargarInformacionUsuario();
            const copyData = utils.copy(filters);
            const cursos = filteReference?.current?.getCursos();
            if (cursos.length > 0) {
                copyData.gradeId = cursos.find(c => String(c.courseId) === String(copyData.courseId))?.gradeId;
            }
            const educationLevels = filteReference?.current?.getEducationLeves();
            if (educationLevels.length > 0) {
                copyData.evaluationTypeId = educationLevels.find(c => String(c.educationLevelId) === String(copyData.educationLevelId))?.evaluationTypeId;
            }
            setParametros(copyData);
            try {
                
                const data = await api.getEstudiantesPorCurso("",copyData.journeyId,copyData.schoolYearId,copyData.educationLevelId,copyData.courseId); // Obtenemos los estudiantes de tu sistema
                const students = data.filter(s=>s.isActive);
                if(students){
                    setEstudiantes(students); 
                }else{
                    setEstudiantes([]);
                }

                const asistenciasDB = await asistenciaService.getAsistenciasToNotas(copyData.courseId, copyData.schoolSubjectId, copyData.schoolYearId, copyData.periodId);
                setAsistencias(asistenciasDB || []);
                setAsistenciaSelected(null);
                setAsistenciaId(null);
            } catch (error) {
                console.error("Error al obtener asistencias:", error);
            }
            setShowSearch(true);
            setShowLoading(false);
        }
    }

    const cargarInformacionUsuario = () => {
        const user = utils.getUserInformation();
        if (user) {
            const typeId = Number(user.userTypeId);
            if (typeId === types.userTypes.TEACHER) {
                setFilterUserId(user.userId);
            }
        }
    };

    const cargarAsistencia = async (asistencia) => {
        try {
            const asistenciaDB = await asistenciaService.getAsistenciaAcademico(asistencia.attendanceId);
            if (asistenciaDB) {
                let newAsistencia = utils.copy(estudiantes);
                newAsistencia.map(a => {
                    const item = asistenciaDB.assistDetails?.find(as => as.studentId === a.studentId);
                    a.attendanceId = asistencia.attendanceId;
                    a.present = item ? item.present : false;
                    a.lateArrival = item ? item.lateArrival : false;
                    a.excused = item ? item.excused : false;
                    return a;
                });
                setAsistenciaId(asistencia.attendanceId);
                setAsistenciaSelected(newAsistencia);
                setAsistenciaDate(moment(newAsistencia.attendanceDate).format("yyyy-MM-DD"));
            } else {
                Swal.fire(
                    "No se encontró asistencia",
                    "Por favor intente nuevamente",
                    "error"
                );
                setAsistenciaDate(moment().format("yyyy-MM-DD"));
                setAsistenciaSelected(null);
                setAsistenciaId(null);
            }
        } catch (error) {
            console.error("Error al obtener asistencias:", error);
            setAsistenciaSelected(null);
            setAsistenciaId(null);
        }
    }

    const columns = [
        {
            name: "N°",
            selector: row => row.number,
            sortable: true,
            cell: (row, index) => <span>{index + 1}</span>,
        },
        { name: "Fecha", selector: row => moment.utc(row.attendanceDate).format('DD-MM-YYYY'), grow:2, },
        { name: "Asistencia", selector: "assists", grow:1 },
        { name: "Inasistencia", selector: "noAssists", grow:1 },
        { name: "Excusas", selector: "excuses", grow:0.5 },
        { name: "Llegadas tarde", selector: "lates", grow:1 },
        { name: "Tomado por", selector: "createdUserName", grow:2 },
        { name: "", selector: "evaluationProcessId" ,  cell: (row, index) => (
            <>
                    {(filterUserId===0 || (filterUserId>0 && filterUserId===Number(row.createdUser))) && (
                    <>
                        <button
                            onClick={() => { cargarAsistencia(row)}}
                            className="btn btn-outline-info me-1"
                            title="Editar"
                        >
                            <i className="fas fa-edit"></i>
                        </button>
                        <button
                        onClick={() => handleRemoveAsistencias(index)}
                        className="btn btn-outline-danger me-1"
                        title="Eliminar"
                        >
                            <i className="fas fa-trash-alt"></i>
                        </button>
                    </>
                    )}
                </>
            )
        },
    ];

    const columnsAsistencia = [
        {
            name: "N°",
            selector: row => row.number,
            sortable: true,
            cell: (row, index) => <span>{index + 1}</span>,
            grow: 0.1
        },
        { name: "Estudiante", selector: "fullName", grow: 1.5, },
        {
            name: "Asistió", selector: "present", cell: (row, index) => (
                <>
                    <div className="form-check form-switch">
                        <input className="form-check-input" type="checkbox" id={"chkAsist-" + row.studentId}
                            checked={(row.present)}
                            onChange={(e) => handleEneableTomarAsistencia(row, e.target)} />
                        <label className="form-check-label" htmlFor={"chkAsist-" + row.studentId}></label>
                    </div>
                </>
            ), grow: 0.1
        },
        {
            name: "Inasistió", selector: "present", cell: (row, index) => (
                <>
                    <div className="form-check form-switch">
                        <input className="form-check-input" type="checkbox" id={"chkNoAsist-" + row.studentId}
                            checked={(!row.present)}
                            onChange={(e) => handleEneableTomarAsistencia(row, e.target)} />
                        <label className="form-check-label" htmlFor={"chkNoAsist-" + row.studentId}></label>
                    </div>
                </>
            ), grow: 0.1
        },
        {
            name: "Llegó tarde", selector: "lateArrival", cell: (row, index) => (
                <>
                    <div className="form-check form-switch">
                        <input className="form-check-input" type="checkbox" id={"chkTarde-" + row.studentId}
                            checked={(row.lateArrival)}
                            onChange={(e) => handleEneableTomarAsistencia(row, e.target)} />
                        <label className="form-check-label" htmlFor={"chkTarde-" + row.studentId}></label>
                    </div>
                </>
            ), grow: 0.3
        },
        {
            name: "Excusa", selector: "excused", cell: (row, index) => (
                <>
                    <div className="form-check form-switch">
                        <input className="form-check-input" type="checkbox" id={"chkExcusa-" + row.studentId}
                            checked={(row.excused)}
                            onChange={(e) => handleEneableTomarAsistencia(row, e.target)} />
                        <label className="form-check-label" htmlFor={"chkExcusa-" + row.studentId}></label>
                    </div>
                </>
            ), grow: 0.1
        },
        {
            name: "", selector: "", cell: (row, index) => (
                <>
                    <div className="col-md-12">
                    </div>
                </>
            ), grow: 2
        }

    ];

    const handleRemoveAsistencias = (index) => {
        const removedElement = asistencias[index];
        if (removedElement.attendanceId) {
            Swal.fire({
                title: '¿Está seguro de que desea eliminar esta toma de asistencia?',
                text: "Se eliminará la asistencia tomada de todos los estudiantes",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Sí, eliminar!'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    try {

                        await asistenciaService.deleteAsistenciasAcademicoById(removedElement.attendanceId);
                        Swal.fire(
                            'Eliminado!',
                            'Asistencia eliminada exitosamente!',
                            'success'
                        );
                        await cargarAsistencias();
                    } catch (error) {
                        Swal.fire(
                            "Error",
                            "Por favor intente más tarde",
                            "error"
                        );
                    }
                }
            })
        }
    };

    const handleEneableTomarAsistencia = (asistencia, chk) => {
        if (chk.id === "chkAsist-" + asistencia.studentId) {
            asistencia.present = chk.checked;
            asistencia.lateArrival = false;
            asistencia.excused = false;
        }
        if (chk.id === "chkNoAsist-" + asistencia.studentId) {
            asistencia.present = !chk.checked;
            asistencia.lateArrival = false;
            asistencia.excused = false;
        }
        if (chk.id === "chkTarde-" + asistencia.studentId) {
            if (chk.checked) {
                asistencia.present = true;
                asistencia.lateArrival = document.getElementById("chkTarde-" + asistencia.studentId).checked;
            } else {
                asistencia.present = false;
                asistencia.lateArrival = false;
            }
        }
        if (chk.id === "chkExcusa-" + asistencia.studentId) {
            asistencia.excused = chk.checked;
        }

        let newAsistencia = utils.copy(asistenciaSelected);
        newAsistencia.map(a => {
            if (a.studentId === asistencia.studentId) {
                a.present = asistencia.present;
                a.lateArrival = asistencia.lateArrival;
                a.excused = asistencia.excused;
            }
            return a;
        });
        setAsistenciaSelected(newAsistencia);
    }

    const handleAddAsistencia = () => {
        let newAsistencia = utils.copy(estudiantes);
        newAsistencia.map(a => {
            a.attendanceId = 0;
            a.present = true;
            a.lateArrival = false;
            a.excused = false;
            return a;
        });
        setAsistenciaSelected(newAsistencia);
        setAsistenciaId(null);
    }

    const guardarAsistencia = async () => {
        setShowLoading(true);
        try {
            const assistDetails = asistenciaSelected.map(a => {
                return {
                    attendanceId: (asistenciaId) ? asistenciaId : 0,
                    studentId: a.studentId,
                    present: a.present,
                    lateArrival: a.lateArrival,
                    excused: a.excused
                };
            });

            const newAsistencia = {
                attendanceId: (asistenciaId) ? asistenciaId : 0,
                schoolSettingId: 0,
                schoolYearId: parametros.schoolYearId,
                periodId: parametros.periodId,
                schoolSubjectId: parametros.schoolSubjectId,
                courseId: parametros.courseId,
                gradeId: parametros.gradeId,
                teacherId: parametros.teacherId,
                attendanceDate: asistenciaDate,
                assistDetails: assistDetails
            };

            let response = null;
            if (newAsistencia.attendanceId > 0) {
                response = await asistenciaService.updateAsistenciaAcademico(newAsistencia);
            } else {
                response = await asistenciaService.createAsistenciaAcademico(newAsistencia);
            }

            if (response && response.attendanceId > 0) {
                await cargarAsistencias();
                setAsistenciaId(null);
                setAsistenciaSelected(null);
                Swal.fire(
                    'Felicidades!',
                    'Asistencia guardada exitosamente!',
                    'success'
                );
            } else {
                Swal.fire(
                    "Error",
                    "Por favor intente más tarde",
                    "error"
                );
            }
        } catch (ex) {
            console.log(ex);
            Swal.fire(
                "Error",
                "Por favor intente más tarde",
                "error"
            );
        }
        setShowLoading(false);
    }

    const volverLista = async () => {
        setAsistenciaSelected(null);
    };

    return (
        <>
            <div className="card p-3 mb-3">
                <h1>Asistencia</h1>
                <p>Esta es la vista de asistencias.</p>
                <div className="card-body">
                    <form className="row mb-3 align-items-end" onSubmit={(e) => e.preventDefault()}>
                        <JornadaAnioPeriodoNivelCursoAsig
                            callback={handleFilterChange}
                            ref={filteReference}
                            loadTutoria={true}
                            loadCoordinators={true}
                            properties={{
                                className: "col-md-4",
                                id: "formTest2",
                                filtro: false,
                            }}
                        />
                        <div className="col-md-4 mb-2 d-flex text-end align-items-end">
                            <button
                                type="button"
                                onClick={cargarAsistencias}
                                className="btn btn-primary w-100"
                            >
                                Buscar
                            </button>
                        </div>
                    </form>
                </div>
            </div>

            {showLoading && <Loading />}

            {showSearch && (
                <>
                    {!showLoading && (
                        <>
                            <div className="card p-3">
                                {!asistenciaSelected && (
                                    <>
                                        <div className="col-md-3 mb-3 d-flex align-items-end margin-top-48">
                                            <button type="button" className="btn btn-primary" onClick={handleAddAsistencia}>
                                                Añadir Asistencia
                                            </button>
                                        </div>
                                        <DataTable
                                            columns={columns}
                                            data={asistencias}
                                            customStyles={customStyles}
                                            className="students-datatable shadow-sm rounded table table-corporate table-bordered table-sm  my-0 dataTables_wrapper dt-bootstrap5 no-footer"
                                            noDataComponent="No hay asistencias que mostrar, por favor agregalas"
                                            noHeader
                                            dense
                                            highlightOnHover
                                            pointerOnHover
                                            fixedHeader
                                            fixedHeaderScrollHeight="450px"
                                        />
                                    </>
                                )}

                                {asistenciaSelected && (
                                    <>
                                        <div className="col-md-3">
                                        <button className="btn btn-secondary" onClick={volverLista}><i className="fas fa-arrow-left"></i> Volver</button>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-3  mb-2">
                                                <label htmlFor="txtAsistenciaDate" className="col-form-label  text-sm-right">Fecha asistencia:* </label>
                                                <input
                                                    id="txtAsistenciaDate"
                                                    type="date"
                                                    className="form-control "
                                                    value={asistenciaDate}
                                                    onChange={(e) => setAsistenciaDate(moment(e.target.value).format("yyyy-MM-DD"))} />
                                            </div>

                                            <DataTable
                                                columns={columnsAsistencia}
                                                data={asistenciaSelected}
                                                customStyles={customStyles}
                                                className="students-datatable shadow-sm rounded table table-corporate table-bordered table-sm  my-0 dataTables_wrapper dt-bootstrap5 no-footer"
                                                noDataComponent="No se encontraron estudiantes"
                                                noHeader
                                                dense
                                                highlightOnHover
                                                pointerOnHover
                                                fixedHeader
                                                fixedHeaderScrollHeight="450px"
                                            />

                                            <hr />
                                            <div className="col-md-12">
                                                <button type="button" className="btn btn-success center display-block" onClick={guardarAsistencia}>
                                                    Guardar asistencia
                                                </button>
                                            </div>
                                        </div>
                                    </>
                                )}
                            </div>
                        </>
                    )}
                </>
            )}
        </>
    );
};

export default AsistenciaView;
