import React from 'react';
import '../../css/DashboardEstudiante.css'; // Asegúrate de crear este archivo CSS para estilizar tu componente
import studentPhoto from '../../img/foto-estudiante.png';
import { Link } from 'react-router-dom';



const DashboardEstudiante = () => {
    // Datos ficticios de la estudiante
    const datosEstudiante = {
        nombre: "Ana María López",
        foto: "foto-estudiante.png", // Reemplaza con el path de la imagen real
        curso: "10 B",
        nivelEducativo: "Secundaria",
        anio: "2024",
        observaciones: "Excelente participación en clase.",
        calificaciones: "Promedio: 4.5",
        estadoCuenta: "Al día"
    };

    return (
        <div className="dashboard-container">
            <div className="card-estudiante-info">
            <img src={studentPhoto} alt="Foto del estudiante" className="foto-estudiante" />
                <h2 className="nombre-estudiante">{datosEstudiante.nombre}</h2>
                <p className="info-estudiante">{datosEstudiante.curso} - {datosEstudiante.nivelEducativo} - Año {datosEstudiante.anio}</p>
            </div>
            
            <Link to="/dashboard-estudiante/datos-basicos-estudiante" className="dashboard-card" id="datos-basicos">
                <i className="fa-regular fa-address-card icono-dashboard"></i>
                <h3>Datos Básicos</h3>
                <p>{datosEstudiante.nombre}</p> </Link>

            <Link className="dashboard-card" id="observaciones" to="/dashboard-estudiante/observaciones-estudiante">
                <i className="fa-solid fa-clipboard-user icono-dashboard"></i>
                <h3>Observaciones</h3>
                <p>{datosEstudiante.observaciones}</p>
            </Link>

            <Link className="dashboard-card" id="calificaciones" to="/dashboard-estudiante/calificaciones-estudiante">
                <i className="fa-solid fa-chart-simple icono-dashboard"></i>
                <h3>Calificaciones</h3>
                <p>{datosEstudiante.calificaciones}</p>
            </Link>

            <Link className="dashboard-card" id="estado-cuenta" to="/dashboard-estudiante/estado-cuenta-estudiante">
                <i className="fa-solid fa-hand-holding-dollar icono-dashboard"></i>
                <h3>Estado de Cuenta</h3>
                <p>{datosEstudiante.estadoCuenta}</p>
            </Link>
        </div>
    );
};

export default DashboardEstudiante;
