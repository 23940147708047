import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import SelectSearch from 'react-select-search';
import estudiantesService from '../../services/estudiantesService';
import utils from "../../utils/generalFunctions.js";

const EstudianteFilterCustomList =  forwardRef((props, ref) => {
    const { callback, isMatriculado, properties, ReadOnly } = props; 
    const initData =['0'];
    const [formData, setFormData] = useState(initData);    
    const [studentList, setStudentList] = useState([]);
    
    useImperativeHandle(ref, () => ({
        setFormValues(data) {
            setFormData(data);
        }
    }));

    const handleAddConcept = () => {                
        let item = utils.copy(formData);
        item.push("0");
        setFormData(item); 
    };

    const handleRemoveConcept = (index) => {         
        let item = utils.copy(formData);
        const updatedDetails = item.filter((_, i) => i !== index);
        setFormData(updatedDetails); 
    };

    // const  handleSelectChange =  (selectedValue, selectedOption, optionSnapshot) => {
    //     const { name, value } = {name:'studentId', value:selectedValue};
    //     setFormData(prevState => ({
    //         ...prevState,
    //         [name]: value
    //     }));
    // };

    const  handleSelectChange =  (selectedValue, index) => {
        let item = utils.copy(formData);
        item[index]=selectedValue;
        setFormData(item);     
    };

    useEffect(() => {
        if(callback){
            callback(formData);
        }
      }, [formData]);

    const clearForm = (index) =>{
        let item = utils.copy(formData);
        item[index]="0";
        setFormData(item);
    }

    useEffect(() => {
        const fetchStudents = async () => {
            try {
                const estudentResp = await estudiantesService.getEstudiantes();
                const students = estudentResp.map((student, index, array) => {
                    return {name: student.identification+ ' '+ student.fullName, value: student.studentId}
                });
                setStudentList(students);
            } catch (error) {
                console.error("Error al obtener listas de formulario:", error);
            }
        };
        fetchStudents();
    }, []);


    return (
        <>

            {formData.map((option,index) => (
                <>
                    <div className={(properties && properties.className)?properties.className:"col-md-4 mb-4"}>
                        <label htmlFor="studentId" className="col-form-label  text-sm-right">Estudiante:</label>
                        <div className='container-searcher-general'>
                            <SelectSearch options={studentList} 
                                id={(properties && properties.id)?`cmbEstudiante-${properties.id}`:"cmbEstudiante"}
                                value={option}  name="fullName" search placeholder="Selecciona a un estudiante" 
                                onChange={(selectedValue, selectedOption, optionSnapshot)=>{
                                    handleSelectChange(selectedValue, index)
                                }}
                                readOnly={ReadOnly} 
                                disabled={ReadOnly} />
                                {option !== '0' && !ReadOnly && (
                                    <i className="fas fa-xmark clear-button-searcher" aria-hidden="true" onClick={(e)=>{clearForm(index);}}></i>
                                )}
                        </div>
                    </div>
                    {index===0 && (
                    <div className="col-md-3 mb-3 d-flex align-items-start margin-top-48">
                        <button type="button" className="btn btn-success" onClick={handleAddConcept}>
                            Añadir otro estudiante
                        </button>
                    </div>
                    )}
                     {index>0 && (
                    <div className="col-md-3 mb-3 d-flex align-items-start margin-top-48">
                        <button type="button" className="btn btn-danger" onClick={ (e)=>{ handleRemoveConcept(index)}}>
                            Remover estudiante
                        </button>
                    </div>
                    )}
                </>
            ))}

        </>
    );
});

export default EstudianteFilterCustomList;
