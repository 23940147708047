import api from './api';

const getDefinicionDeNotas = async () => {
    try {
        const response = await api.get(`academic/note-definition`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

const createDefinicionDeNota = async (periodoData) => {
    try {
        const response = await api.post(`academic/note-definition`, periodoData);
        return response.data;
    } catch (error) {
        throw error;
    }
};

const updateDefinicionDeNota = async (periodoData) => {
    try {
        const response = await api.put(`academic/note-definition`, periodoData);
        return response.data;
    } catch (error) {
        throw error;
    }
};

const getDefinicionDeNota = async (id) => {
    try {
        const response = await api.get(`academic/note-definition/${id}`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

const getDefinicionDeNotaByProcessId = async (id) => {
    try {
        const response = await api.get(`academic/note-definition/process/${id}`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

const deleteDefinicionDeNotaById = async (id) => {
    try {
        const response = await api.delete(`academic/note-definition/${id}`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export default {
    getDefinicionDeNotas,
    createDefinicionDeNota,
    updateDefinicionDeNota,
    getDefinicionDeNota,
    deleteDefinicionDeNotaById,
    getDefinicionDeNotaByProcessId
};