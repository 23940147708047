// DescuentosEstudiantes.js
import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import { Button, ButtonToolbar } from 'react-bootstrap';
import moment from 'moment'
import Loading from '../components/Loading';
import utils from '../utils/generalFunctions';
import useCustomDataTableStyles from '../components/estilosDeDataTable';
import Swal from "sweetalert2";
import academicService from '../services/academicService';
import collectionsService from '../services/collectionsService';
import types from '../services/types';
import billingConceptService from '../services/billingConceptService';
import SelectSearch from 'react-select-search';
import billingService from '../services/billingService';
import { toast } from 'react-toastify';


const BillingReport = () => {

    
    const [filterText, setFilterText] = useState('');
    const [showLoading, setShowLoading] = useState(false);
    const [showSearch, setShowSearch] = useState(true);
    
    const initData ={
        schoolYearId: 0,
        paymentTypeId:0,
        billingDateStart: moment().startOf('month').format("yyyy-MM-DD"),
        billingDateEnd: moment().endOf('month').format("yyyy-MM-DD"),
    };
    const [formData, setFormData] = useState(initData);
    const [years, setYears] = useState([]);
    const [paymentTypeList, setPaymentTypeList] = useState([]);
    const [billingConcepts, setBillingConcepts] = useState([]);
    const [billingSelectedConceptList, setBillingSelectedConceptList] = useState([]);
    const [reportData, setReportData] = useState([]);
    

    const fetchSchoolYears = async () => {
        try {
            const yearsResp = await academicService.getYears();
            setYears(yearsResp);
            const paymentTypeRes = await collectionsService.getCollection(types.collections.PAYMENT_TYPE);
            setPaymentTypeList(paymentTypeRes);
            fetchBillingConcepts();
        } catch (error) {
            console.error("Error al obtener listas de formulario:", error);
        }
    };

    useEffect(() => {
        fetchSchoolYears();
      }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
        if(name==='schoolYearId'){
            LoadBillingConcepts(value);
            handleConceptSelectChange([]);
        }
    };

    const fetchBillingConcepts = async () => {
        const fullConcepts = await billingConceptService.getAllBillingItems();
        const concepts = fullConcepts.filter(c=>!c.description.toLowerCase().includes("descuento"));
        const sdata = concepts.map((concept, index, array) => {
            return {name: concept.description, value: concept.billingConceptId}
        });
        setBillingConcepts(sdata);
    };

    useEffect(() => {
        addingDisplayConcepList("none");
      }, [billingConcepts]);

    const LoadBillingConcepts = async (schoolYearId) => {
        const fullConcepts = await billingConceptService.getAllBillingItems();
        const concepts = fullConcepts.filter(c=>!c.description.toLowerCase().includes("descuento"));
        if(schoolYearId !== "0"){
            if(concepts.length > 0){
                let data = concepts.filter((op) => (String(op.schoolYearId) === String(schoolYearId) || !op.schoolYearId));
                const sdata = data.map((concept, index, array) => {
                    return {name: concept.description, value: concept.billingConceptId}
                });
                setBillingConcepts(sdata);
            }
        }else{
            const sdata = concepts.map((concept, index, array) => {
                return {name: concept.description, value: concept.billingConceptId}
            });
            setBillingConcepts(sdata);
        }
    };

    const  handleConceptSelectChange =  (data) => {
        setBillingSelectedConceptList(data);
    };

    const addingDisplayConcepList = (display) => {
        const cmb = document.getElementById("cmbBillingConceptId");
        if(cmb){
            const list = cmb.getElementsByClassName("select-search-select")[0];
            if(list)list.style.display = display;   
        }
    }


    const generarReporte = async (e) => {
      e.preventDefault();
      setShowLoading(true);

      // Validación básica
      const requiredFields = ['billingDateStart', 'billingDateEnd'];
      for (let field of requiredFields) {
          if (!formData[field]) {
              toast.error("Por favor, completa todos los campos obligatorios.", {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light",
                });
              return;
          }
      }
      await loadBillingReport();
      setShowLoading(false);
      setShowSearch(true);
    };

    const loadBillingReport = async () => {
        try {
            const reportInformation = await billingService.getBillingReport(formData.schoolYearId, formData.paymentTypeId,formData.billingDateStart,formData.billingDateEnd,billingSelectedConceptList.toString());
            setReportData(reportInformation);
        } catch (error) {
            console.error("Error al obtener listas de formulario:", error);
        }
    };

    const exportarExcel = () =>{
        const id="billingReport";
        const name ="Reporte de ingresos";
        utils.exportTableToExcel(id, name);
    }

    return (
      <>
      
        <div className="card p-3">
            <div className="card-header">
        <h2 className="">Reporte de ingresos</h2>
        <h5 className="card-subtitle text-muted">Vista extendida de los ingresos obtenidos por mes.</h5>
      </div>
      
      <div className="card-body">
  <form className="row g-2 align-items-center mb-3">
    <div className="col-md-3">
      <label htmlFor="concept" className="form-label">Conceptos:</label>
      <div className='container-searcher-general'>
        <SelectSearch
          options={billingConcepts}
          id="cmbBillingConceptId"
          multiple={true}
          search={false}
          value={billingSelectedConceptList}
          name="description"
          placeholder="Seleccionar conceptos"
          onChange={handleConceptSelectChange}
          onBlur={() => {
            addingDisplayConcepList("none");
          }}
          onFocus={() => {
            addingDisplayConcepList("");
          }}
        />
        {billingSelectedConceptList.length > 0 && (
          <i
            className="fas fa-xmark clear-button-searcher"
            aria-hidden="true"
            onClick={() => {
              setBillingSelectedConceptList([]);
            }}
          ></i>
        )}
      </div>
    </div>
    <div className="col-md-2">
      <label htmlFor="schoolYearId" className="form-label">Año escolar:</label>
      <select
        className="form-control form-select"
        id="schoolYearId"
        name="schoolYearId"
        value={formData.schoolYearId}
        onChange={handleChange}
      >
        <option value="0">Todos</option>
        {years.map((schoolYear) => (
          <option key={schoolYear.schoolYearId} value={schoolYear.schoolYearId}>
            {schoolYear.year}
          </option>
        ))}
      </select>
    </div>
    <div className="col-md-2">
      <label className="form-label">Forma Pago:</label>
      <select
        className="form-control form-select validarForm"
        name="paymentTypeId"
        value={formData.paymentTypeId}
        onChange={handleChange}
      >
        <option value="0">Todos</option>
        {paymentTypeList.map((option) => (
          <option key={option.id} value={option.id}>
            {option.text}
          </option>
        ))}
      </select>
    </div>
    <div className="col-md-2">
      <label htmlFor="billingDateStart" className="form-label">Fecha Inicio:</label>
      <input
        type="date"
        className="form-control validarForm"
        name="billingDateStart"
        value={formData.billingDateStart}
        onChange={handleChange}
        placeholder="dd/mm/aaaa"
        required
      />
    </div>
    <div className="col-md-2">
      <label htmlFor="billingDateEnd" className="form-label">Fecha Final:</label>
      <input
        type="date"
        className="form-control validarForm"
        name="billingDateEnd"
        value={formData.billingDateEnd}
        onChange={handleChange}
        placeholder="dd/mm/aaaa"
        required
      />
    </div>
    <div className="col-md-1  d-flex align-items-end">
      <button onClick={generarReporte} className="btn btn-primary mt-4 w-100">Buscar</button>
    </div>
  </form>
</div>


        </div>
        

      
              <div className="card p-3 margin-top-10">
                    <div className="row">
                        {showLoading && <Loading />}

                        {showSearch && (
                            <>
                                {!showLoading && (
                                        <>
                                            {/* Tabla de descuentos */}
                                            <div class=" pt-0 table-responsive">
                                                <div class="card-actions float-end margin-bottom-15">
                                                    <button className='btn btn-success' onClick={(e)=>{exportarExcel()}}>Exportar a Excel</button>
                                                </div>
                                                <table id="billingReport" className="table table-responsive table-striped table-corporate table-bordered table-sm my-0 dataTables_wrapper dt-bootstrap5 no-footer">
                                                    <thead>
                                                        <tr>
                                                            <th>No.</th>
                                                            <th>Fecha</th>
                                                            <th>Año</th>
                                                            <th>Consecutivo</th>
                                                            <th>Tipo de pago</th>
                                                            <th>Concepto</th>                                                            
                                                            <th>Valor</th>
                                                            <th>Estudiante</th>
                                                            <th>Jornada</th>
                                                            <th>Curso</th>
                                                            <th>Nivel Educativo</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                            {reportData.map((data,index) => (
                                                                    <tr>
                                                                        <td>{index+1}</td>
                                                                        <td>{moment(data.billingDate).format('YYYY-MM-DD')}</td>
                                                                        <td>{data.year}</td>
                                                                        <td>{data.consecutive}</td>
                                                                        <td>{data.paymentTypeName}</td>
                                                                        <td>{data.billingConceptName}</td>
                                                                        <td>{utils.formatNumber.new(data.valuePaid, "$")}</td>
                                                                        <td>{data.studentName}</td>
                                                                        <td>{data.journey}</td>
                                                                        <td>{data.course}</td>
                                                                        <td>{data.educationLevel}</td>
                                                                    </tr>
                                                            ))}
                                                    </tbody>
                                                    <tfoot>
                                                            <tr>
                                                                <td colSpan={6}>Total</td>
                                                                <td>{utils.formatNumber.new(
                                                                    reportData.reduce((sum, data) => { 
                                                                    return sum + (data.valuePaid??0) }, 0), "$")} 
                                                                </td>
                                                                <td colSpan={5}></td>
                                                            </tr>
                                                    </tfoot>
                                                </table>
                                            </div>
                                        </>
                                    )}
                            </>
                        )}
                          </div>
                    </div>
              
      </>
    );
};

export default BillingReport;
