import api from './api';

const getIncidencias = async () => {
    try {
        const response = await api.get(`disciplinary/incidence`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

const createIncidencia = async (incidencia) => {
    try {
        const response = await api.post(`disciplinary/incidence`, incidencia);
        return response.data;
    } catch (error) {
        throw error;
    }
};

const updateIncidencia = async (incidencia) => {
    try {
        const response = await api.put(`disciplinary/incidence`, incidencia);
        return response.data;
    } catch (error) {
        throw error;
    }
};

const getIncidencia = async (id) => {
    try {
        const response = await api.get(`disciplinary/incidence/${id}`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

const deleteIncidenciasById = async (id) => {
    try {
        const response = await api.delete(`disciplinary/incidence/${id}`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export default {
    getIncidencias,
    createIncidencia,
    updateIncidencia,
    getIncidencia,
    deleteIncidenciasById,
};