import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Dropdown, ProgressBar } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import DashboardCard from "../../components/TituloDashboard";
import studentPhoto from '../../img/foto-estudiante-prees.png';


const NotasEstudiante = () => {
  const [asignaturaSeleccionada, setAsignaturaSeleccionada] = useState(null);
  const datosEstudiante = {
    asignaturas: [
      {
        nombre: "Matemáticas",
        progreso: 75,
        detalles: [
          { concepto: "Álgebra", nota: 80 },
          { concepto: "Geometría", nota: 70 },
        ],
      },
      {
        nombre: "Ciencias Naturales",
        progreso: 85,
        detalles: [
          { concepto: "Biología", nota: 88 },
          { concepto: "Química", nota: 82 },
        ],
      },
      // Añadir más asignaturas según sea necesario
    ],
  };

  const seleccionarAsignatura = (asignatura) => {
    setAsignaturaSeleccionada(asignatura);
  };

  return (
    <div className="vista-notas-estudiante">
      <div className="header-vista">
      <Link to="/dashboard-estudiantepc" className="back-to-dashboard-pc">
                <FontAwesomeIcon icon={faArrowLeft} />
            </Link> 
       <DashboardCard
        studentPhoto={studentPhoto}
          title="Vista de Notas académica"
          subtitle="Calificaciones por asignatura"
          nombre="Sofía Goméz"
          curso= "P Sección A"
          nivelEducativo="Preescolar"
          anio="2025"
        />
      </div>
      {datosEstudiante.asignaturas.map((asignatura, index) => (
        <div key={index} className="card flex-fill mb-3">
          <div className="card-header">
            <div className="card-actions float-end">
              <Dropdown>
                <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                  {/* Icono de más opciones */}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item href="#">Action</Dropdown.Item>
                  <Dropdown.Item href="#">Another action</Dropdown.Item>
                  <Dropdown.Item href="#">Something else</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <h5 className="card-title mb-0">{asignatura.nombre}</h5>
          </div>
          <ProgressBar
            now={asignatura.progreso}
            label={`${asignatura.progreso}%`}
            className="progress-bar bg-success"
            style={{ cursor: "pointer", width: '100%' }}
            onClick={() => seleccionarAsignatura(asignatura)}
          />
          {asignaturaSeleccionada && asignaturaSeleccionada.nombre === asignatura.nombre && (
            <ul className="list-group list-group-flush">
              {asignaturaSeleccionada.detalles.map((detalle, indexDetalle) => (
                <li key={indexDetalle} className="list-group-item">
                  {detalle.concepto}: {detalle.nota}
                </li>
              ))}
            </ul>
          )}
        </div>
      ))}
    </div>
  );
};

export default NotasEstudiante;