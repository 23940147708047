import React from 'react';
import '../../css/DashboardEstudiante.css'; // Asegúrate de crear este archivo CSS para estilizar tu componente
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import studentPhoto from '../../img/foto-estudiante.png';


const PagosEstudiante = () => {
    const datosEstudiante = {
        nombreCompleto: "Ana María López",
        foto: studentPhoto, // Reemplaza con el path de la imagen real        grado: "10 B",
        email: "ana.lopez@example.com",
        celular: "123-456-7890",
        acudiente: "José López",
        celularAcudiente: "098-765-4321",
        emailAcudiente: "jose.lopez@example.com",
        direccion: "Calle Falsa 123, Springfield",
        nombre: "Ana María López",
        curso: "10 B",
        grado: "Décimo",
        nivelEducativo: "Secundaria",
        anio: "2024",
        // ... otros datos que puedas necesitar
    };
  // Datos ficticios de los pagos
  const pagos = [
    { concepto: "Matrícula", monto: "500$", estado: "Pagado", fecha: "2023-01-10" },
    { concepto: "Uniforme Diario", monto: "100$", estado: "Pendiente", fecha: "2023-02-05" },
    { concepto: "Uniforme Educación Física", monto: "80$", estado: "Pendiente", fecha: "2023-02-12" },
    { concepto: "Mes", monto: "200$", estado: "Pagado", fecha: "2023-01-05" },
    { concepto: "Plataforma", monto: "50$", estado: "Pagado", fecha: "2023-01-15" },
    // ... otros pagos ...
  ];

  const obtenerEstadoPago = (estado) => {
    return estado === "Pagado"
      ? "badge rounded-pill bg-success"
      : "badge rounded-pill bg-danger";
  };

  return (
    <div className="pagos-estudiante-container">
                     <Link  to="/dashboard-estudiante" className="back-to-dashboard">
                        <FontAwesomeIcon icon={faArrowLeft} />
                        </Link>    
            <div className="card-estudiante-info">
            <img src={studentPhoto} alt="Foto del estudiante" className="foto-estudiante rounded-circle rounded me-2 mb-2"/>
                <h2 className="nombre-estudiante">{datosEstudiante.nombre}</h2>
                <p className="info-estudiante">{datosEstudiante.curso} - {datosEstudiante.nivelEducativo} - Año {datosEstudiante.anio}</p>
                
            </div>  

      {pagos.map((pago, index) => (
        <div key={index} className="pago-card">
          <h3>{pago.concepto}</h3>
          <p>Monto: {pago.monto}</p>
          <span className={obtenerEstadoPago(pago.estado)}>
            {pago.estado}
          </span>
          <p>Fecha de Pago: {pago.fecha}</p>
        </div>
      ))}
    </div>
  );
};

export default PagosEstudiante;
