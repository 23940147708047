import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import "../css/estudiantes.css";
import {
    MDBBtn,
    MDBModal,
    MDBModalDialog,
    MDBModalContent,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBModalFooter,
} from 'mdb-react-ui-kit';
import Loading from '../components/Loading';
import asignaturaService from "../services/asignaturaService";
import Swal from "sweetalert2";
import AsignaturaForm from "../components/Forms/FormAsignatura";
import useCustomDataTableStyles from "../components/estilosDeDataTable";

const Asignatura = () => {

    const [fullscreenXlModal, setFullscreenXlModal] = useState(false);
    const [asignaturas, setAsignaturas] = useState([]);
    const [selectedAsignatura, setSelectedAsignatura] = useState(null);
    const [filterText, setFilterText] = useState('');
    const [BtnVer, setBtnVer] = useState('');
    const customStyles = useCustomDataTableStyles();

    const toggleShow = () => {
        setFullscreenXlModal(!fullscreenXlModal);
        setSelectedAsignatura(null);
    }

    const openMaximizeModal = (asignatura) => {
        setSelectedAsignatura(asignatura);
        setFullscreenXlModal(true);
        setBtnVer(true);
    };

    const openEditingModal = async (asignatura) => {
        setSelectedAsignatura(asignatura);
        setFullscreenXlModal(true);
        setBtnVer(false);
    };

    const openAddingModal = () => {
        setSelectedAsignatura(null);
        setFullscreenXlModal(true);
        setBtnVer(false);
    };

    const deleteAsignatura = async (asignatura) => {
        Swal.fire({
            title: '¿Está seguro de que desea eliminar esta asignatura?',
            text: "",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, eliminar'
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    await asignaturaService.deleteAsignaturaById(asignatura.schoolSubjectId);
                    Swal.fire(
                        'Eliminado',
                        'Asignatura eliminada exitosamente!',
                        'success'
                    );
                    fetchAsignaturas();
                } catch (error) {
                    Swal.fire(
                        "Error",
                        "Ha ocurrido un error al intentar eliminar la asignatura.",
                        "error"
                    );
                }
            }
        })
    }

    const fetchAsignaturas = async () => {
        try {
            const response = await asignaturaService.getAsignaturas();
            setAsignaturas(response);
        } catch (error) {
            console.error("Error al obtener las asignaturas:", error);
        }
    };

    useEffect(() => {
        fetchAsignaturas();
    }, []);

    const columns = [
        {
            name: "N°",
            selector: "consecutive",
            grow: 0.05,
            sortable: true,
            cell: (row, index) => <span>{index + 1}</span>,
        },
        { name: "Nombre", selector: "name", sortable: true, grow: 4 },
        { name: "Abrev.", selector: "abbreviation", sortable: true },
        { name: "Area", selector: "academicAreaName", grow: 3.5, sortable: true },
        { name: "Orden", selector: "orden", sortable: true },
        {
            name: "Activo",
            selector: "isActive",
            sortable: true,
            cell: (row) => (
                <>
                    {row.isActive ? <span>Sí</span> : <span>No</span>}
                </>
            ),
        },
        {
            name: "Acciones",
            grow: "3",
            selector: "actions",
            cell: (row) => (
                <div className="btn-group btn-group-sm">
                    <button
                        onClick={() => openMaximizeModal(row)}
                        className="btn btn-outline-success me-1"
                        title="Maximizar información"
                    >
                        <i className="fas fa-eye"></i>
                    </button>
                    <button
                        onClick={() => openEditingModal(row)}
                        className="btn btn-outline-primary me-1"
                        title="Editar"
                    >
                        <i className="icon-edit fas fa-pencil-alt"></i>
                    </button>
                    <button
                        onClick={() => deleteAsignatura(row)}
                        className="btn btn-outline-danger me-1"
                        title="Eliminar"
                    >
                        <i className="icon-deactivate fas fa-times"></i>
                    </button>
                </div>
            ),
        },
    ];

    const normalizeString = (str) => {
        return str
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .toLowerCase();
    };

    const filteredItems = asignaturas?.filter((item) => {
        if (item) {
            const allData = `${item.consecutive} ${item.name} ${item.order} ${item.abbreviation} ${item.isActive}`;
            const normalizedFilterText = normalizeString(filterText);
            const normalizedItemName = normalizeString(allData);
            return normalizedItemName.includes(normalizedFilterText);
        }
        return item;
    });

    const paginationPerPage = 50; // Ajusta este número según tus necesidades

    return (
        <div className="col-md-12">
            <div className="card">
                <div className="card-header">
                    <h2 className="">Asignaturas</h2>
                    <h5 className="card-subtitle text-muted">Esta es la vista de las asignaturas académicas.</h5>
                </div>
                <div className="card-body">
                    <form className="row mb-3" onSubmit={(e) => e.preventDefault()}>
                        <div className="col-md-6 col-xl-4 mb-2 mb-md-0">
                            <input
                                type="text"
                                placeholder="Buscar"
                                value={filterText}
                                onChange={e => setFilterText(e.target.value)}
                                className="form-control"
                            />
                        </div>
                        <div className="col-md-6 col-xl-8">
                            <div className="text-end">
                                <button type="button" onClick={openAddingModal} className="btn btn-primary mb-2">
                                    Nueva asignatura <i className="fa-solid fa-circle-plus"></i>
                                </button>
                            </div>
                        </div>
                    </form>
                </div>

                <DataTable
                    className="students-datatable shadow-sm rounded"
                    noDataComponent="No hay información que mostrar, por favor añadela"
                    columns={columns}
                    data={filteredItems}
                    pagination
                    fixedHeader
                    customStyles={customStyles}
                    paginationPerPage={paginationPerPage}
                    paginationRowsPerPageOptions={[50, 100]}
                    fixedHeaderScrollHeight="450px"
                />

                <MDBModal tabIndex='-1' show={fullscreenXlModal} setShow={setFullscreenXlModal}>
                    <MDBModalDialog className="modal-dialog modal-md">
                        <MDBModalContent>
                            <MDBModalHeader>
                                <MDBModalTitle>DETALLES DE LA ASIGNATURA</MDBModalTitle>
                                <MDBBtn
                                    type='button'
                                    className='btn-close'
                                    color='none'
                                    onClick={toggleShow}
                                ></MDBBtn>
                            </MDBModalHeader>
                            <MDBModalBody>
                                <div className='card'>
                                    {selectedAsignatura && (
                                        <AsignaturaForm
                                            key={Date.now()}
                                            asignatura={selectedAsignatura.schoolSubjectId}
                                            onClose={() => {
                                                setFullscreenXlModal(false);
                                                fetchAsignaturas();
                                            }}
                                            ReadOnly={BtnVer}
                                        />
                                    )}

                                    {!selectedAsignatura && (
                                        <AsignaturaForm
                                            key={Date.now()}
                                            asignatura={0}
                                            onClose={() => {
                                                setFullscreenXlModal(false);
                                                fetchAsignaturas();
                                            }}
                                            ReadOnly={false}
                                        />
                                    )}
                                </div>
                            </MDBModalBody>
                            <MDBModalFooter>
                                <button type='button' className='btn-footer-close btn-primary' onClick={toggleShow}>
                                    Cerrar
                                </button>
                            </MDBModalFooter>
                        </MDBModalContent>
                    </MDBModalDialog>
                </MDBModal>
            </div>
        </div>
    );
};

export default Asignatura;
