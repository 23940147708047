import api from './api';

const getNotasAcademicos = async () => {
    try {
        const response = await api.get(`academic/note`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

const getNotasCourse = async (courseId, schoolSubjectId, schoolYearId, academicPeriodId) => {
    try {
        const response = await api.get(`academic/note-course?courseId=${courseId}&schoolSubjectId=${schoolSubjectId}&schoolYearId=${schoolYearId}&academicPeriodId=${academicPeriodId}`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

const createNotaAcademico = async (periodoData) => {
    try {
        const response = await api.post(`academic/note`, periodoData);
        return response.data;
    } catch (error) {
        throw error;
    }
};

const updateNotaAcademico = async (periodoData) => {
    try {
        const response = await api.put(`academic/note`, periodoData);
        return response.data;
    } catch (error) {
        throw error;
    }
};

const getNotaAcademico = async (id) => {
    try {
        const response = await api.get(`academic/note/${id}`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

const deleteNotasAcademicoById = async (id) => {
    try {
        const response = await api.delete(`academic/note/${id}`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export default {
    getNotasAcademicos,
    createNotaAcademico,
    updateNotaAcademico,
    getNotaAcademico,
    deleteNotasAcademicoById,
    getNotasCourse
};