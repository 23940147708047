import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import "../css/estudiantes.css";
import {
    MDBBtn,
    MDBModal,
    MDBModalDialog,
    MDBModalContent,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBModalFooter,
} from 'mdb-react-ui-kit';
import Loading from '../components/Loading';
import SchoolYearForm from "../components/Forms/SchoolYearForm";
import academicService from "../services/academicService";
import Swal from "sweetalert2";
import useCustomDataTableStyles from "../components/estilosDeDataTable";

const ConfigureYears = () => {
    const [fullscreenXlModal, setFullscreenXlModal] = useState(false);
    const [years, setYears] = useState([]);
    const [selectedYear, setSelectedYear] = useState(null);
    const [filterText, setFilterText] = useState('');
    const [BtnVer, setBtnVer] = useState('');

    const toggleShow = () => {
        setFullscreenXlModal(!fullscreenXlModal);
        setSelectedYear(null); 
    }

    const openMaximizeModal = (year) => {
        setSelectedYear(year);
        setFullscreenXlModal(true);
        setBtnVer(true);
    };

    const openEditingModal = async (year) => {
        setSelectedYear(year);
        setFullscreenXlModal(true);
        setBtnVer(false);
    };

    const deleteYear = async(year)=>{
      Swal.fire({
          title: 'Esta seguro que desea eliminar este año?',
          text: "",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Si, eliminar!'
        }).then(async(result) => {
          if (result.isConfirmed) {
            try{
            await academicService.deleteYearById(year.schoolYearId);
            Swal.fire(
              'Felicidades!',
              'Año eliminado exitosamente!',
              'success'
            );
            fetchYears();
            }catch(error){
              Swal.fire(
                  "Ha ocurrido un error",
                  "Por favor intentelo mas tarde",
                  "error"
              );
            }
          }
        })
    }
    
    const openAddingModal = (e) => {
        e.preventDefault(); // Añadir esto para prevenir el comportamiento predeterminado del botón
        setSelectedYear(null);
        setFullscreenXlModal(true);
        setBtnVer(false);
    };

    const fetchYears = async () => {
        try {
            const response = await academicService.getYears();
            setYears(response);
        } catch (error) {
            console.error("Error al obtener los años escolares:", error);
        }
    };

    useEffect(() => {
      fetchYears();
    }, []);

    const columns = [
        { name: "N°", selector: "consecutive", sortable: true, cell: (row, index) => <span>{index + 1}</span> }, // Número identificatorio del año escolar
        { name: "Año", selector: "year", sortable: true }, // Ejemplo: 2023-2024
        { name: "Jornada", selector: "journeyName", sortable: true }, // Ejemplo: Anual o Semestral
        { name: "Activo", selector: "isActive", sortable: true, cell: (row) => (row.isActive ? <span>Si</span> : <span>No</span>) },
        {
            name: "Acciones", grow: "3", selector: "actions",
            cell: (row) => (
                <div className="btn-group btn-group-sm">
                    <button onClick={() => openMaximizeModal(row)} className="btn btn-outline-success me-1" title="Maximizar información">
                        <i className="fas fa-eye"></i>
                    </button>
                    <button onClick={() => openEditingModal(row)} className="btn btn-outline-info me-1" title="Editar">
                        <i className="icon-edit fas fa-pencil-alt"></i>
                    </button>
                    <button onClick={() => deleteYear(row)} className="btn btn-outline-danger me-1" title="Eliminar">
                        <i className="icon-deactivate fas fa-times"></i>
                    </button>
                </div>
            ),
        },
    ];

    const normalizeString = (str) => {
        return str
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .toLowerCase();
    };

    const filteredItems = years?.filter((item) => {
        if (item) {
            const allData = `${item.consecutive} ${item.year} ${item.journeyName} ${item.isActive}`;
            const normalizedFilterText = normalizeString(filterText);
            const normalizedItemName = normalizeString(allData);
            return normalizedItemName.includes(normalizedFilterText);
        }
        return item;
    });

    const customStyles = useCustomDataTableStyles();

    const paginationPerPage = 50; // Ajusta este número según tus necesidades

    return (
        <div className="col-md-12">
            <div className="card">
                <div className="card-header">
                    <h2 className="">Administración de año escolar</h2>
                    <h5 className="card-subtitle text-muted">Esta es la vista del año lectivo.</h5>
                </div>
                <div className="card-body">
                    <form className="row mb-3" onSubmit={openAddingModal}>
                        <div className="col-md-6 col-xl-4 mb-2 mb-md-0">
                            <input
                                type="text"
                                placeholder="Buscar"
                                value={filterText}
                                onChange={e => setFilterText(e.target.value)}
                                className="form-control"
                            />
                        </div>
                        <div className="col-md-6 col-xl-8">
                            <div className="text-end">
                                <button type="submit" className="btn btn btn-primary mb-2">
                                    Nuevo Año Escolar <i className="fa-solid fa-circle-plus"></i>
                                </button>
                            </div>
                        </div>
                    </form>
                </div>

                <DataTable
                    className="students-datatable shadow-sm rounded"
                    noDataComponent="No hay información que mostrar, por favor añadela"
                    columns={columns}
                    data={filteredItems}
                    pagination
                    fixedHeader
                    fixedHeaderScrollHeight="450px"
                    customStyles={customStyles}
                    paginationPerPage={paginationPerPage} // Agrega esta propiedad para controlar las filas por página
                    paginationRowsPerPageOptions={[50, 100]}
                />

                {/* Renderiza el modal y el formulario aquí */}
                <>
                    <MDBModal tabIndex='-1' show={fullscreenXlModal} setShow={setFullscreenXlModal}>
                        <MDBModalDialog className="modal-dialog modal-lg">
                            <MDBModalContent>
                                <MDBModalHeader>
                                    <MDBModalTitle>DETALLES DEL AÑO ESCOLAR</MDBModalTitle>
                                    <MDBBtn
                                        type='button'
                                        className='btn-close'
                                        color='none'
                                        onClick={toggleShow}
                                    ></MDBBtn>
                                </MDBModalHeader>
                                <MDBModalBody>
                                    <div className='card'>
                                        {selectedYear && (
                                            <SchoolYearForm
                                                key={Date.now()}
                                                yearItem={selectedYear.schoolYearId}
                                                hideBtnInscription={true}
                                                onClose={() => {
                                                    setFullscreenXlModal(false);
                                                    fetchYears();
                                                }}
                                                ReadOnly={BtnVer}
                                            />
                                        )}

                                        {!selectedYear && (
                                            <SchoolYearForm
                                                key={Date.now()}
                                                student={0}
                                                hideBtnInscription={true}
                                                onClose={() => {
                                                    setFullscreenXlModal(false);
                                                    fetchYears();
                                                }}
                                                ReadOnly={false}
                                            />
                                        )}
                                    </div>
                                </MDBModalBody>
                                <MDBModalFooter>
                                    <button type='button' className='btn-footer-close btn-primary' onClick={toggleShow}>
                                        Cerrar
                                    </button>
                                </MDBModalFooter>
                            </MDBModalContent>
                        </MDBModalDialog>
                    </MDBModal>
                </>
            </div>
        </div>
    );
};

export default ConfigureYears;
