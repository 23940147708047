import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import "../../css/estudiantes.css";
import academicService from "../../services/academicService";
import courseService from '../../services/courseService';
import moment from "moment";
import collectionsService from "../../services/collectionsService";
import utils from '../../utils/generalFunctions';
import groupDirector from "../../services/groupDirector";
import types from "../../services/types";
import organizacionAcademicaService from "../../services/organizacionAcademicaService";
import docentesService from "../../services/docentesService";


const JornadaAnioPeriodoNivelCurso = forwardRef((props, ref) => {

    const { callback, properties, ReadOnly } = props; 
    const [journeyList, setJourneyList] = useState([]);
    const [schoolYearList, setSchoolYearList] = useState([]);
    const [years, setYears] = useState([]);
    const [educationLevelsList, setEducationLevelsList] = useState([]);
    const [educationLevels, setEducationLevels] = useState([]);
    const [coursesList, setCoursesList] = useState([]);
    const [courses, setCourses] = useState([]);
    const [periodos, setPeriodos] = useState([]);
    const [directores,setDirectores] = useState([]);

    let yearsList = [];
    let allEducationLevels = [];
    let allCourses = [];
    const initData ={
        schoolYearId:'0',
        journeyId:'0',
        educationLevelId:'0',
        courseId:'0',
        periodId:'0',
    };
    const [formData, setFormData] = useState(initData);
    const filteReference = useRef(null);
    const [orgAcademica, sertOrgAcademica] = useState([]);

    useImperativeHandle(ref, () => ({
        setFormValues(data) {
            setFormData(prevState => ({
            ...prevState,
            ...data
        }));
        },
        getCursos(){
            return courses;
        },
        getEducationLeves(){
            return educationLevels;
        },
        getSchoolYears(){
            return years;
        },
        getJourneys(){
            return journeyList;
        }
    }));

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
        if (name.includes("schoolYearId")){
            LoadPeriodos(value);
        }else if (name.includes("journeyId")){    
          const cmbJourney = (properties && properties.id)?`cmbJourney-${properties.id}`:'cmbJourney';
          const journeyId = document.getElementById(cmbJourney).value;
          LoadYears(journeyId);
          LoadCourses(journeyId,"0");
          LoadEducationLevels(journeyId);
        }else if(name.includes("educationLevelId")){
            const cmbJourney = (properties && properties.id)?`cmbJourney-${properties.id}`:'cmbJourney';
            const journeyId = document.getElementById(cmbJourney).value;
            const cmbEducationLevelId = (properties && properties.id)?`educationLevelId-${properties.id}`:'educationLevelId';
            const educationLevelId = document.getElementById(cmbEducationLevelId).value;
            LoadCourses(journeyId,educationLevelId);
        }
    };

    useEffect(() => {
        if(callback){
            callback(formData);
        }
      }, [formData]);

      useEffect(() => {
        LoadPeriodos(formData.schoolYearId);
      }, [formData.schoolYearId]);
      
    const fetchSchoolYears = async () => {
        try {
            const yearsResp = await academicService.getYears();
            setSchoolYearList(yearsResp);
            setYears(yearsResp);
        } catch (error) {
            console.error("Error al obtener listas de formulario:", error);
        }
    };

    const fetchEducationLevels = async () => {
        try {
            const educationLevelsResp = await courseService.getEducationLevels();
            setEducationLevelsList(educationLevelsResp);
            setEducationLevels(educationLevelsResp);
        } catch (error) {
            console.error("Error al obtener listas de formulario:", error);
        }
    };

    const fetchCourses = async () => {
        try {
            const coursesResp = await courseService.getCursos();
            setCoursesList(coursesResp);
            setCourses(coursesResp);
        } catch (error) {
            console.error("Error al obtener listas de formulario:", error);
        }
    };

    const fetchLoadFiltersSchoolYears = async () => {
      try {
          const journeyResp = await academicService.getJourneys();
          setJourneyList(journeyResp);
          
          yearsList  = await academicService.getYears();
          setSchoolYearList(yearsList);
          setYears(yearsList);

          allEducationLevels =  await courseService.getEducationLevels(); 
          setEducationLevelsList(allEducationLevels);
          setEducationLevels(allEducationLevels);

          allCourses =  await courseService.getCursos(); 
          setCoursesList(allCourses);
          setCourses(allCourses);
      } catch (error) {
          console.error("Error al obtener listas de formulario:", error);
      }
    };

    const LoadYears = (journeyId) => {
      if(journeyId !== "0"){

        if (yearsList.length > 0) {
          const yrs = yearsList.filter((op) => String(op.journeyId) === journeyId);
          setYears(yrs);
          fechtCurrentAnioToTeacher(yrs);
        } else if (schoolYearList.length > 0) {
          const yrs = schoolYearList.filter((op) => String(op.journeyId) === journeyId);
          setYears(yrs);
          fechtCurrentAnioToTeacher(yrs);
        }
      }else{
        fetchSchoolYears();
      }
      setFormData(prevState => ({
        ...prevState,
        schoolYearId: '0'
      }));
    };

    const fechtCurrentAnioToTeacher = async (years) => {
        const parameters = await collectionsService.getParameters();
        const currentAnio = parameters.find((op) => String(op.keyName) === "AnioElectivo");
        if(currentAnio){
            if(years.find(y=> String(y.schoolYearId) === currentAnio.value)){
                setFormData(prevState => ({
                    ...prevState,
                    schoolYearId:currentAnio.value
                }));
                LoadPeriodos(currentAnio.value);
            }else{
                if(years.length === 1){
                    const preLoad = years[0].schoolYearId;
                    setFormData(prevState => ({
                        ...prevState,
                        schoolYearId: preLoad
                    }));
                    LoadPeriodos(preLoad);
                }else{
                    setPeriodos([]);
                }
            }
        }
    }

    const LoadPeriodos = async (schoolYearId) =>{
        if(schoolYearId!=='0'){
            const resYear = await academicService.getYearById(schoolYearId);
            if(resYear){
                const peri = resYear.periods.filter(p=>p.isRecuperation===0 || !p.isRecuperation);
                setPeriodos(peri);
                let selectedP = null;
                for (let i = 0; i < peri.length; i++) {
                    const p = peri[i];
                    selectedP = p;
                    const today = moment().toDate();
                    const start = moment(p.startDate).toDate();
                    const end = moment(p.endDate).toDate();
                    if((today >= start) && (today <= end)){
                        selectedP = p;
                        break;
                    }else if(today <= start){
                        selectedP = p;
                        break;
                    }
                }
                if(selectedP!==null){
                    setFormData(prevState => ({
                        ...prevState,
                        periodId: selectedP.academicPeriodId
                    }));
                }
            }else{
                setPeriodos([]);
            }
        }else{
            setPeriodos([]);
        }
    }

    const LoadEducationLevels = (journeyId) => {
        if(journeyId !== "0"){

            let tempCouse=[];
            if (allCourses.length > 0) {
                tempCouse = allCourses.filter((op) => String(op.journeyId) === journeyId);
            } else if (coursesList.length > 0) {
                tempCouse = coursesList.filter((op) => String(op.journeyId) === journeyId);
            }

            if (allEducationLevels.length > 0) {
                const edFiltred = allEducationLevels.filter((ed) => tempCouse.filter((c) => c.educationLevelId === ed.educationLevelId).length > 0 );
                setEducationLevels(edFiltred);
            } else if (educationLevelsList.length > 0) {
                const edFiltred = educationLevelsList.filter((ed) => tempCouse.filter((c) => c.educationLevelId === ed.educationLevelId).length > 0);
                setEducationLevels(edFiltred);
            }
        }else{
            fetchEducationLevels();
        }
        setFormData(prevState => ({
        ...prevState,
        educationLevelId: '0'
        }));
    };

    const LoadCourses = (journeyId, educationLevelId) => {
        if(journeyId !== "0" || educationLevelId !== "0"){
          if (allCourses.length > 0) {
            let courseFiltred = (journeyId !== "0")? allCourses.filter((op) =>  (String(op.journeyId) === journeyId)) : allCourses;
            courseFiltred = (educationLevelId !== "0") ? courseFiltred.filter((op) => (String(op.educationLevelId) === educationLevelId)) : courseFiltred;
            setCourses(courseFiltred);
          } else if (coursesList.length > 0) {
            let courseFiltred = (journeyId !== "0")? coursesList.filter((op) =>  (String(op.journeyId) === journeyId)) : coursesList;
            courseFiltred = (educationLevelId !== "0") ? courseFiltred.filter((op) => (String(op.educationLevelId) === educationLevelId)) : courseFiltred;
            setCourses(courseFiltred);
          }
        }else{
          fetchCourses();
        }
        setFormData(prevState => ({
          ...prevState,
          courseId: '0'
        }));
    };

    const fetchLoadFiltersSchoolYearsTeachers = async () => {
        try {
            let respOrgAcademica = [];
            respOrgAcademica = await organizacionAcademicaService.getAcademicOrganizations();            
            const respDirectores = await cargarDirectoresGrupo();
                    for(let asig of respDirectores){
                        respOrgAcademica.push({
                            teacherId: asig.teacherId,
                            journeyId: asig.journeyId,
                            journeyName: asig.journeyName,
                            schoolYearId: asig.schoolYearId,
                            schoolYear: asig.schoolYear,
                            educationLevelId: asig.educationLevelId,
                            educationLevelName: asig.educationLevelName,
                            evaluationTypeId: asig.evaluationTypeId,
                            courseId: asig.courseId,
                            courseName: asig.courseName,
                            gradeId: asig.gradeId
                        })
                }
            sertOrgAcademica(respOrgAcademica);  
        } catch (error) {
            console.error("Error al obtener listas de formulario:", error);
        }
    };

    const cargarDirectoresGrupo= async () => {
        try {
          const respDirectores =  await groupDirector.getDirectorGrupos();
          setDirectores(respDirectores);
          return respDirectores;
        } catch (error) {
            console.error("Error al obtener listas de formulario:", error);
        }
        return [];
    }

    const LoadTeacherInformation = (teacherId) => {
        let orgTeacher = orgAcademica.filter(o=> o.teacherId === teacherId);
        const journeyResp = utils.removeDuplicates(orgTeacher.map((o) => {return {journeyId: o.journeyId, name: o.journeyName}}));
        setJourneyList(journeyResp);
        const journeyId =  (journeyResp.length===1) ?journeyResp[0].journeyId:"";

        yearsList = utils.removeDuplicates(orgTeacher.map((o) => {return {schoolYearId: o.schoolYearId, year: o.schoolYear, journeyId: o.journeyId}}));
        setSchoolYearList(yearsList);
        setYears(yearsList);
        fechtCurrentAnioToTeacher(yearsList);

        allEducationLevels = utils.removeDuplicates(orgTeacher.map((o) => {return {educationLevelId: o.educationLevelId, name: o.educationLevelName, evaluationTypeId:o.evaluationTypeId}}));
        setEducationLevelsList(allEducationLevels);
        setEducationLevels(allEducationLevels);

        allCourses = utils.removeDuplicates(orgTeacher.map((o) => {return {courseId: o.courseId, name: o.courseName, journeyId: o.journeyId, educationLevelId: o.educationLevelId, gradeId:o.gradeId}}));
        setCoursesList(allCourses);
        setCourses(allCourses);
        

        const educationLevelId =  (allEducationLevels.length===1) ?allEducationLevels[0].educationLevelId:"";
        const courseId =  (allCourses.length===1) ?allCourses[0].courseId:"";

        setFormData(prev => ({
            ...prev,
            journeyId:journeyId,
            educationLevelId:educationLevelId,
            courseId: courseId,
        }));
        
    }
    
    const cargarInformacionUsuario = async () => {
        const user= utils.getUserInformation();
        if(user){
            const typeId=Number(user.userTypeId);
            if(typeId===types.userTypes.TEACHER){
                await fetchLoadFiltersSchoolYearsTeachers();
                filteReference?.current?.setDocenteByUser(user.userId);
            }else{
                await fetchLoadFiltersSchoolYears();
            }
        }
    };

    const cargarInformacionTeacher = async () => {
        const user= utils.getUserInformation();
        if(user){
            const typeId=Number(user.userTypeId);
            if(typeId===types.userTypes.TEACHER){
                const docentesResp = await docentesService.getDocentes();
                const teacher = docentesResp.find(t=>t.userId === user.userId);
                await LoadTeacherInformation(teacher.teacherId);
            }
        }
    };

    useEffect( () =>  {
        cargarInformacionTeacher();
    }, [orgAcademica]);

    useEffect(() => {
        cargarInformacionUsuario();
    }, []);

  return (
    <>
        <div className={(properties && properties.className)?properties.className:"col-md-2 mb-2"} >
            <label className="col-form-label  text-sm-right">Jornada:</label>
            <select
            id={(properties && properties.id)?`cmbJourney-${properties.id}`:"cmbJourney"}
            className="form-control form-select validarForm"
            name="journeyId"
            value={formData.journeyId}
            onChange={handleChange}
            disabled={ReadOnly}
            >
            <option value="0"> {(properties && !properties.filtro)?'Seleccione':'Todos'}</option>
            {journeyList.map((option) => (
                <option key={option.journeyId} value={option.journeyId}>
                {option.name}
                </option>
            ))}
            </select>
        </div>
        <div className={(properties && properties.className)?properties.className:"col-md-2 mb-2"}>
                <label htmlFor={(properties && properties.id)?`schoolYearId-${properties.id}`:"schoolYearId"}
                 className="col-form-label  text-sm-right">Año escolar:</label>
                <select className="form-control form-select" 
                id={(properties && properties.id)?`schoolYearId-${properties.id}`:"schoolYearId"}
                name="schoolYearId" 
                value={formData.schoolYearId} 
                onChange={handleChange} 
                disabled={ReadOnly}>
                    <option value="0" > {(properties && !properties.filtro)?'Seleccione':'Todos'}</option>
                    {years.map((schoolYear) => (
                        <option key={schoolYear.schoolYearId} value={schoolYear.schoolYearId} >
                            {schoolYear.year}
                        </option>
                    ))}
                </select>
        </div>
        <div className={(properties && properties.className)?properties.className:"col-md-2 mb-2"}>
                <label htmlFor={(properties && properties.id)?`periodId-${properties.id}`:"periodId"}
                 className="col-form-label  text-sm-right">Periodo:</label>
                <select className="form-control form-select" 
                id={(properties && properties.id)?`periodId-${properties.id}`:"periodId"}
                name="periodId" 
                value={formData.periodId} 
                onChange={handleChange} 
                disabled={ReadOnly}>
                    <option value="0" > {(properties && !properties.filtro)?'Seleccione':'Todos'}</option>
                    {periodos.map((periodo) => (
                        <option key={periodo.academicPeriodId} value={periodo.academicPeriodId} >
                            {periodo.periodName}
                        </option>
                    ))}
                </select>
        </div>
        <div className={(properties && properties.className)?properties.className:"col-md-2 mb-2"}>
                <label htmlFor={(properties && properties.id)?`educationLevelId-${properties.id}`:"educationLevelId"} className="col-form-label  text-sm-right">Nivel Ed.:</label>
                <select className="form-control form-select" 
                id={(properties && properties.id)?`educationLevelId-${properties.id}`:"educationLevelId"}
                name="educationLevelId" 
                value={formData.educationLevelId} 
                onChange={handleChange} 
                disabled={ReadOnly}>
                    <option value="0" > {(properties && !properties.filtro)?'Seleccione':'Todos'}</option>
                    {educationLevels.map((level) => (
                        <option key={level.educationLevelId} value={level.educationLevelId} >
                            {level.name}
                        </option>
                    ))}
                </select>
        </div>
        <div className={(properties && properties.className)?properties.className:"col-md-2 mb-2"}>
                <label htmlFor={(properties && properties.id)?`courseId-${properties.id}`:"courseId"} className="col-form-label  text-sm-right">Curso:</label>
                <select className="form-control form-select" 
                id={(properties && properties.id)?`courseId-${properties.id}`:"courseId"}
                name="courseId" 
                value={formData.courseId} 
                onChange={handleChange} 
                disabled={ReadOnly}>
                    <option value="0" > {(properties && !properties.filtro)?'Seleccione':'Todos'}</option>
                    {courses.map((course) => (
                        <option key={course.courseId} value={course.courseId} >
                            {course.name}
                        </option>
                    ))}
                </select>
        </div>
    </>
    );
});

export default JornadaAnioPeriodoNivelCurso;