import React, { useState, useEffect } from 'react';
import logroService from '../../services/logrosServices.js'; // Asegúrate de tener este servicio
import Swal from 'sweetalert2';
import Loading from '../Loading';
import academicService from '../../services/academicService.js';
import gradesService from '../../services/gradesService.js';
import asignaturaService from '../../services/asignaturaService.js';
import evaluativeScaleService from '../../services/evaluativeScaleService.js';
import procesoService from '../../services/procesoService.js';
import utils from "../../utils/generalFunctions.js";
import collectionsService from '../../services/collectionsService.js';
import types from '../../services/types.js';

const LogroForm = (props) => {
    const { logro, onClose, ReadOnly } = props;
    const initData = {
        achievementId: 0,
        schoolSubjectId: "",
        gradeId: "",
        evaluativeScaleId: [""],
        evaluationProcessId: [""],
        description: [""],
        schoolYearId: "",
        periodId: "",
        evaluationTypeId:types.evaluationTypes.ESCALA_VALORATIVA
    };

    const [formData, setFormData] = useState(initData);
    const [showLoading, setShowLoading] = useState(true);
    const [years, setYears] = useState([]);
    const [subjectsList, setSubjectsList] = useState([]);
    const [gradesList, setGradesList] = useState([]);
    const [periodosList, setPeriodosList] = useState([]);
    const [evaluationScale, setEvaluationScale] = useState([]);
    const [procesos, setProcesos] = useState([]); 
    const [evaluationTypeId, setEvaluationTypeId]= useState(types.evaluationTypes.ESCALA_VALORATIVA); 
    const [logros, setLogros] = useState([]); 

    const handleChange = (e, index) => {
        const { name, value } = e.target;
        let item = utils.copy(formData);
        let evaluationType = evaluationTypeId;
        if(name.includes("description")){    
            formData.description[index] = value;
            setFormData(prevState => ({
                ...prevState,
               ...formData
            }));
        } else if(name.includes("evaluativeScaleId")){
            formData.evaluativeScaleId[index] = value;
            setFormData(prevState => ({
                ...prevState,
               ...formData
            }));
        }else if(name.includes("evaluationProcessId")){
            formData.evaluationProcessId[index] = value;
            setFormData(prevState => ({
                ...prevState,
               ...formData
            }));
        }else{

            if(name==="gradeId"){
                const grade = gradesList.find(g=>g.gradeId===Number(value));
                if(grade){
                    setEvaluationTypeId(grade.evaluationTypeId);
                    evaluationType = grade.evaluationTypeId;
                }
            }
            setFormData(prevState => ({
                ...prevState,
                [name]: value,
                evaluationTypeId:evaluationType
            }));
            item[name]=value;        
            if(name==="schoolYearId"){
                cargarPeriodos(value);
            }
        
            if((name==="gradeId" || name==="schoolSubjectId" || name==="schoolYearId" || name==="periodId") && evaluationType === 1){ 
                if(item.gradeId!=="" && item.schoolSubjectId!=="" && item.schoolYearId!=="" && item.periodId!==""){
                    cargarProcesos(item);
                }
            }
        }
    };

    const validateInformationArray = () =>{
        if(evaluationTypeId===types.evaluationTypes.DIMENSION_VALORATIVA){
            for(let i=0; i < formData.description.length; i++){
                if(!formData.description[i]) return true;
                if(!formData.evaluationProcessId[i]) return true;
            }
        }else{
            for(let i=0; i < formData.description.length; i++){
                if(!formData.description[i]) return true;
                if(!formData.evaluativeScaleId[i]) return true;
            }
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Validación básica
        const requiredFields = ['schoolSubjectId', 'gradeId', 'schoolYearId', 'periodId'];
        for (let field of requiredFields) {
            if (!formData[field] || validateInformationArray()) {
                Swal.fire(
                    "Campos Obligatorios",
                    "Por favor, completa todos los campos obligatorios.",
                    "error"
                );
                return;
            }
        }

        if (!ReadOnly) {
            setShowLoading(true);
            try {
                const response = await logroService.saveLogros(formData);
                if (response && response.message==="ok") {
                    Swal.fire(
                        "Felicidades!",
                        "Logro guardado con éxito!",
                        "success"
                    );
                    if (onClose) onClose();
                    setFormData(initData);
                } else {
                    Swal.fire(
                        "Ha ocurrido un error",
                        "Por favor intente más tarde",
                        "error"
                    );
                }
            } catch (error) {
                console.error('Error al enviar el formulario:', error);
                Swal.fire(
                    "Ha ocurrido un error",
                    "Por favor intente más tarde",
                    "error"
                );
            }
            setShowLoading(false);
        }
    };

    const fetchCollections = async () => {
        try {
            const yearsResp = await academicService.getYears();
            setYears(yearsResp);

            const parameters = await collectionsService.getParameters();
            const currentAnio = parameters.find((op) => String(op.keyName) === "AnioElectivo");
            if(currentAnio){
                setFormData(prevState => ({
                    ...prevState,
                    schoolYearId:currentAnio.value
                }));
              await cargarPeriodos(currentAnio.value);
            }

            const repGradesList = await gradesService.getAllGrades();
            setGradesList(repGradesList);

            const respAsignatura = await asignaturaService.getAsignaturas();
            setSubjectsList(respAsignatura);

            const evaluationScale = await evaluativeScaleService.getEvaluativeScale(2);
            setEvaluationScale(evaluationScale);
          
        } catch (error) {
          console.error("Error al obtener listas de formulario:", error);
        }
    };

    const cargarProcesos = async (data) => {
        try {
            
            const procesosDB = await procesoService.getProcesosToLogros(data.gradeId, data.schoolSubjectId, data.schoolYearId, data.periodId);
            if(procesosDB){
                setProcesos(procesosDB);
            }else{
                setProcesos([]);
            }
        } catch (error) {
          console.error("Error al obtener procesos:", error);
        }
        formData.evaluationProcessId = [""];
    }

    const cargarPeriodos = async (schoolYearId) => {
        try {
            const schoolYear = await academicService.getYearById(schoolYearId);              
            if(schoolYear){
                setPeriodosList(schoolYear.periods)
            }            
        } catch (error) {
        console.error("Error al obtener información de año escolar:", error);
      }
    }

    const addLogro = () =>{
        formData.evaluativeScaleId.push("");
        formData.evaluationProcessId.push("");
        formData.description.push("");
        setFormData(prevState => ({
            ...prevState,
           ...formData
        }));
    }

    const removeLogro = (index) =>{
        const newScale = formData.evaluativeScaleId.filter((p, i) => i !== index);
        const newProcess = formData.evaluationProcessId.filter((p, i) => i !== index);
        const newDescription = formData.description.filter((p, i) => i !== index);
        formData.evaluativeScaleId = newScale;
        formData.evaluationProcessId = newProcess;
        formData.description = newDescription;
        setFormData(prevState => ({
            ...prevState,
           ...formData
        }));
    }
    
 // Asegúrate de que el efecto para obtener un logro use la función correcta (getLogro en lugar de getLogroById)
 useEffect(() => {
    const fetchLogro = async () => {
        setShowLoading(true);
        await fetchCollections();
        if (logro) {
            try {
                const resLogro = await logroService.getLogro(logro.achievementId); // Asegúrate de que este método existe y es correcto
                setEvaluationTypeId(resLogro.evaluationTypeId);                
                await cargarPeriodos(resLogro.schoolYearId);
                await cargarProcesos(resLogro);
                resLogro.evaluativeScaleId=[resLogro.evaluativeScaleId];
                resLogro.evaluationProcessId=[resLogro.evaluationProcessId];
                resLogro.description=[resLogro.description];
                setFormData((prevState) => ({ ...prevState, ...resLogro }));
            } catch (error) {
                console.error("Error al obtener el logro:", error);
            }
        }
        setShowLoading(false);
    };

    fetchLogro();
}, [logro]);

return (
    <>
        {showLoading && <Loading />}

        {!showLoading && (
            <div className="container-fluid mt-4">
                <p className="text-muted">Por favor, completa todos los campos para registrar un nuevo logro.</p>
                <form onSubmit={handleSubmit} className="mt-3 row">
                    {/* Campos existentes ... */}

                    {/* Campo para Año Escolar */}
                    <div className="col-md-4 mb-3">
                        <label htmlFor="schoolYearId" className="col-form-label  text-sm-right">Año escolar:*</label>
                        <select className="form-control form-select" 
                                id="schoolYearId"
                                name="schoolYearId"  
                                disabled={ReadOnly}
                                value={formData.schoolYearId} onChange={handleChange}>
                            <option value="">Seleccione</option>
                            {years.map((schoolYear) => (
                                <option key={schoolYear.schoolYearId} value={schoolYear.schoolYearId} >
                                    {schoolYear.year}
                                </option>
                            ))}
                        </select>
                    </div>

                        {/* Campo para Periodo Académico */}
                    <div className="col-md-4 mb-3">
                        <label htmlFor="periodId" className="col-form-label  text-sm-right">Periodo Académico:*</label>
                        <select className="form-control form-select" 
                                id="periodId"
                                name="periodId"  
                                disabled={ReadOnly}
                                value={formData.periodId} onChange={handleChange}>
                            <option value="">Seleccione</option>
                            {periodosList.map((period) => (
                                <option key={period.academicPeriodId} value={period.academicPeriodId} >
                                    {period.periodName}
                                </option>
                            ))}
                        </select>
                    </div>

                     {/* Campo para Grado */}
                     <div className="col-md-4 mb-3">
                        <label htmlFor="gradeId" className="col-form-label  text-sm-right">Grado:*</label>
                        <select
                                className="form-control form-select validarForm"
                                name="gradeId"
                                value={formData.gradeId}
                                onChange={handleChange}
                                readOnly={ReadOnly}
                                required
                            >
                                <option value="" disabled={ReadOnly}>
                                Seleccione
                                </option>
                                {gradesList?.map((option) => (
                                <option
                                    key={option.gradeId}
                                    value={option.gradeId}
                                    disabled={ReadOnly}
                                >
                                    {option.name}
                                </option>
                                ))}
                            </select>
                    </div>

                    {/* Campo para Asignatura */}
                    <div className="col-md-4 mb-3">
                        <label className="col-form-label  text-sm-right">Seleccione Asignatura:*</label>
                            <select
                                className="form-control form-select"
                                name="schoolSubjectId"
                                value={formData.schoolSubjectId}
                                onChange={handleChange}
                                required
                            >
                                <option value="" disabled>Seleccione una asignatura</option>
                                {subjectsList.map((subject) => (
                                    <option key={subject.schoolSubjectId} value={subject.schoolSubjectId}>
                                        {subject.name}
                                    </option>
                                ))}
                        </select>
                    </div>

                    <div className="col-md-12 mb-12">
                        <table className='table table-bordered table-sm table-striped my-0'>
                                <thead>
                                    <tr>
                                        <th colSpan={3}>
                                        <button type='button' className='btn btn-success' onClick={addLogro}>
                                            Añadir <i class="fa-sharp fa-solid fa-plus" ></i>
                                        </button>
                                        </th>
                                    </tr>
                                    <tr>
                                        
                                            {evaluationTypeId===types.evaluationTypes.ESCALA_VALORATIVA && (
                                            <>
                                                <th>Desempeño</th>
                                            </>)}
                                        
                                        {evaluationTypeId===types.evaluationTypes.DIMENSION_VALORATIVA && (
                                            <>
                                                <th>Proceso Evaluativo:</th>
                                            </>)}
                                            <th>Descripción:</th>
                                            <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                {formData.description.map((scale,index) => (
                                    <>
                                        <tr>
                                            {evaluationTypeId===types.evaluationTypes.ESCALA_VALORATIVA && (
                                            <td>
                                                <select className="form-control form-select" 
                                                            id={"evaluativeScaleId"+index}
                                                            name={"evaluativeScaleId"+index}
                                                            disabled={ReadOnly}
                                                            value={formData.evaluativeScaleId[index]} onChange={(e)=> {handleChange(e, index) }}>
                                                        <option value="">Seleccione</option>
                                                        {evaluationScale.map((scale) => (
                                                            <option key={scale.evaluativeScaleId} value={scale.evaluativeScaleId} >
                                                                {scale.name}
                                                            </option>
                                                        ))}
                                                    </select>
                                            </td>
                                            )}
                        
                                            {evaluationTypeId===types.evaluationTypes.DIMENSION_VALORATIVA && (
                                            <td>
                                                <select className="form-control form-select" 
                                                            id={"evaluationProcessId"+index}
                                                            name={"evaluationProcessId"+index}
                                                            disabled={ReadOnly}
                                                            value={formData.evaluationProcessId[index]} onChange={(e)=> {handleChange(e, index) }}>
                                                        <option value="">Seleccione</option>
                                                        {procesos.map((process) => (
                                                            <option key={process.evaluationProcessId} value={process.evaluationProcessId} >
                                                                {process.description}
                                                            </option>
                                                        ))}
                                                    </select>
                                            </td>
                                            )}
                                            <td>
                                                <textarea readOnly={ReadOnly} className="form-control" id={"description"+index} name={"description"+index} placeholder="Ingrese la descripción" 
                                                value={formData.description[index]} onChange={(e)=> {handleChange(e, index) }} required />
                                            </td>
                                            <td>
                                               { !(index===0 && (logro?.achievementId > 0)) && (

                                                <button
                                                    onClick={() => removeLogro(index)}
                                                    className="btn btn-outline-danger me-1"
                                                    title="Eliminar"
                                                >
                                                    <i className="icon-deactivate fas fa-times"></i>
                                                </button>
                                                )}
                                            </td>
                                        </tr>
                                    </>
                                ))}
                                </tbody>
                        </table>
                    </div>

                     

                    {/* Campo para Descripción */}
                    {/* <div className="col-md-12 mb-3">
                        <label htmlFor="description" className="col-form-label  text-sm-right">Descripción:*</label>
                        <textarea readOnly={ReadOnly} className="form-control" id="description" name="description" placeholder="Ingrese la descripción" value={formData.description} onChange={handleChange} required />
                    </div> */}

                    {/* ... Resto de campos existentes ... */}

                    {!ReadOnly && (
                        <div className='row'>
                            <div className='col-md-12 text-center'>
                                <button type="submit" className="btn btn-primary">Guardar</button>
                            </div>
                        </div>
                    )}
                </form>
            </div>
        )}
    </>
);
}

export default LogroForm;