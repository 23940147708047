import React, { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import Loading from '../Loading';
import estudiantesService from "../../services/estudiantesService";
import JornadaAnioCursoFilter from '../Filters/JornadaAnioCurso';

const FormMatricula = (props) => {
    const { students, enrollmentId, onClose, ReadOnly } = props; 
    const initData ={
        studentId:0,
        schoolYearId: 0,
        journeyId:0,
        gradeId: 0,
        courseId:0,
        billingReference:"",
        isActive: true,
        studentsId: [],
    };
    const [formData, setFormData] = useState(initData);
    const [showLoading, setShowLoading] = useState(false);
    const filteReference = useRef(null);

    const fetchMatricula = async ()=> {
        if(enrollmentId){
            const matricula = await estudiantesService.getMatricula(enrollmentId);
            filteReference.current?.setFormValues(matricula);
        }
    }

    useEffect(() => {
        fetchMatricula();
      }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const updateForm = (data) => {
        setFormData(prevState => ({
            ...prevState,
            ...data
          }));
    }

    const validateStudents = async () => {
        let valid = true;
        if(!enrollmentId){
            if(formData.studentsId && formData.studentsId.length>0){
                for(let sId of formData.studentsId){
                    const responseMatriculados = await estudiantesService
                    .getEstudiantesPorAnio(sId, formData.journeyId,formData.schoolYearId,'1');
                    if(responseMatriculados && responseMatriculados.length>0){
                        toast.error("algunos estudiantes ya se encuentra matriculados en el año escolar y curso seleccionado.", {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });
                    valid = false;
                    break;
                    }
                }
            }else{
                const responseMatriculados = await estudiantesService
                .getEstudiantesPorAnio(formData.studentId, formData.journeyId,formData.schoolYearId,'1');
                    if(responseMatriculados && responseMatriculados.length>0){
                        toast.error("Este estudiante ya se encuentra matriculado en el año escolar y curso seleccionado.", {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                    });
                    valid = false;
                }
            }
        }
        return valid;
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Validación básica
        const requiredFields = ['schoolYearId', 'courseId', 'journeyId'];
        for (let field of requiredFields) {
            if (!formData[field] || (formData[field] && formData[field]==='0')) {
                toast.error("Por favor, completa todos los campos obligatorios.", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                  });
                return;
            }
        }

        // Aquí podrías agregar otras validaciones si es necesario
        if(!ReadOnly)
        {
            setShowLoading(true);
            try {
                let idResponse = 0;
                formData.studentsId = students;

                let response = null;
                if(await validateStudents()){
                    if(!enrollmentId){
                        response = await estudiantesService.matricularEstudiante(formData);
                    }else{
                        response = await estudiantesService.updateMatricula(formData);
                    }
                    idResponse = response.schoolYearId;
                    if (idResponse) {
                        Swal.fire(
                            "Felicidades!",
                            "Estudiante matriculado con éxito!",
                            "success",
                            );
                        if (onClose) onClose();
                        setFormData(initData);
                    } else {
                        Swal.fire(
                            "Ha ocurrido un error",
                            "Por favor intentelo mas tarde",
                            "error"
                        );
                    }
                }
            } catch (error) {
                console.error('Error al enviar el formulario:', error);
                Swal.fire(
                    "Ha ocurrido un error",
                    "Por favor intentelo mas tarde",
                    "error"
                );
            }
            setShowLoading(false);
        }
    };

    return (
        <>
        {showLoading && <Loading />}

        {!showLoading && (
            <div className="container-fluid mt-4">
            <p className="text-muted">Por favor, llena todos los campos para registrar una matricula.</p>
            
            <form onSubmit={handleSubmit} className="mt-3 ">
            <div className="row">

                <JornadaAnioCursoFilter
                    callback={updateForm}
                    ref={filteReference}
                    properties={
                        {
                          className:'col-md-3 mb-3',
                          id:'formMatricula',
                          filtro:false
                        }
                    }
                    
                />
        </div>
                    
                {!ReadOnly  && (
                    <div className='row'>
                        <div className='col-md-2 center padding-top-20 padding-bottom-20'>
                            <button type="submit" className="btn btn-primary">Guardar</button>
                        </div>
                    </div>
                )}
            </form>
            </div>
        )}
        </>
    );
}

export default FormMatricula;
