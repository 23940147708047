import React, { useState } from 'react';
import Header from './components/header'
import Sidebar from './components/sidebar'
import { BrowserRouter as Router, Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import routesArray from './routes';
import Login from './views/login';
import Inscriptions from './views/inscriptions';
import { ToastContainer } from 'react-toastify';
import '../src/App.css';
import types from '../src/services/types';
import userServices from '../src/services/userService';
import studentService from "../src/services/estudiantesService";
import moment from 'moment';
import Footer from './components/footer.js';


function App() {

  const [isAuthenticated, setIsAuthenticated] = useState(!!localStorage.getItem('token'));
  moment.locale("es");
     
  const getUsersAccount=async () => {
    const reponse  = await userServices.getUsersByAccount();
    return reponse.data;
  } 

  const getStudentAccount=async () => {
    const reponse = await studentService.getEstudiantesPorUsuario();
    return reponse;
  }

  const getStudentsByParent=async () => {
    const reponse = await studentService.getEstudiantesByParents();
    return reponse;
  }
  

  const handleLogin  = async (data) => {
      const token = data.token
      localStorage.setItem('userTypeId', data.userTypeId);
      localStorage.setItem('token', token);

      const users =  await getUsersAccount();
      const uData = btoa(JSON.stringify(users));
      localStorage.setItem('_data', uData);
      if(data.userTypeId === types.userTypes.STUDENT){
        const studentData = await getStudentAccount();
        const stuData = btoa(JSON.stringify(studentData));
        localStorage.setItem('_student', stuData);
      }else if(data.userTypeId === types.userTypes.PARENTS){
        const students = await getStudentsByParent();
        const sData = btoa(JSON.stringify(students));
        localStorage.setItem('_students', sData);
        if(students && students.length>0){
          const stuData = btoa(JSON.stringify(students[0]));
          localStorage.setItem('_student', stuData);
        }
      }else{
        localStorage.setItem('_student', '');
        localStorage.setItem('_students', '');
      }

      setIsAuthenticated(true);
      window.location.replace('/');
  };

  const validarSession = () => {
      const data1 = localStorage.getItem('userTypeId');
      const data2 = localStorage.getItem('token');
      const data3 = localStorage.getItem('_data');
      if(!data1 || !data2 || !data3){
          cerrarSession();
      }
  }

  const cerrarSession = () => {
    localStorage.setItem('userTypeId', '');
    localStorage.setItem('token', '');
    localStorage.setItem('_data', '');
    localStorage.setItem('_student', '');
    localStorage.setItem('_students', '');
    setIsAuthenticated(false);    
    window.location.replace('/');
  };

  const getRoutes = () => {
    validarSession();
     const type = localStorage.getItem('userTypeId');
     if(type){
        const value = Number(type);
        switch(value){
            case types.userTypes.ADMIN:
              return routesArray.filter(m=> m.key!=="DashboardDocente" );
            case types.userTypes.WORKER:
              return routesArray.filter(m=> 
              [
                "UserProfile",
                "inscripciones",
                "dashboard",
                "students",
                "teachers",
                "matriculas",
                "BillingConcepts",
                "Billing",
                "Discounts",
                "EstadoCuentas",
                "Morosos",
                "build",
                "GestionIncidencias",
                "EvaluacionPreventiva",
                "Employees"
              ].includes(m.key) && m.key!=="DashboardDocente" );
            case types.userTypes.COODINATOR:
              return routesArray.filter(m=> 
                [
                  "UserProfile",
                  "inscripciones",
                  "DashboardDocente",
                  "students",
                  "teachers",
                  "matriculas",
                  "build",
                  "LogrosEstudiantes",
                  "Procesos",
                  "NotasEstudiantes",
                  "Asistencia",
                  "GestionIncidencias",
                  "ConfiguracionAcademica",
                  "DashInasistencias",
                  "VistaAsignacionTutores",
                  "EvaluacionPreventiva",
                  "DashInformes",
                  "ObservadorView",
                  "DesactivarReportesEstudiantesBoletin"
                ].includes(m.key) && m.key!=="dashboard" );
            case types.userTypes.TEACHER:
              return routesArray.filter(m=> 
                [
                  "UserProfile",
                  "DashboardDocente",
                  "students",
                  "build",
                  "GestionIncidencias",
                  "LogrosEstudiantes",
                  "Procesos",
                  "NotasEstudiantes",
                  "Asistencia",
                  "DashInasistencias",
                  "EvaluacionPreventiva",
                  "DashInformes",
                  "ObservadorView"
                ].includes(m.key) && m.key!=="dashboard" );
            case types.userTypes.STUDENT:
            case types.userTypes.PARENTS:
              return routesArray.filter(m=> 
                [
                  "UserProfile",
                  "build",
                  "DashboardEstudiantePc",
                  "perfil",
                  "notasDelEstudiante",
                  "PagosEstudiantepc",
                  "ObservacionesAsistenciaPC",
                  "HorarioDeClases",
                  "ReporteEstudiantePC",
                  "CalificacionesPreescolar"

                ].includes(m.key) && m.key!=="dashboard");
            default:
              return [
                <Route key="inscripciones" path="/inscripciones" element={<Inscriptions />} />,
                <Route key="root" path="/" element={<Login onLogin={handleLogin} />} />,
                <Route key="login" path="/login" element={<Login onLogin={handleLogin} />} />  
              ];
        }

     }else{
        return [
          <Route key="inscripciones" path="/inscripciones" element={<Inscriptions />} />,
          <Route key="root" path="/" element={<Login onLogin={handleLogin} />} />,
          <Route key="login" path="/login" element={<Login onLogin={handleLogin} />} />  
        ];
     }     
  }
  
  const Logins = () => {
    if(!isAuthenticated){
    return (
    <>
        <Routes>
          <Route key="inscripciones" path="/inscripciones" element={<Inscriptions />} />
          <Route key="root" path="/" element={<Login onLogin={handleLogin} />} />
          <Route key="login" path="/login" element={<Login onLogin={handleLogin} />} />   
      </Routes>
      <ToastContainer />
    </>);
    }else{
      return (
        <>
            <div className="wrapper">
                <Sidebar />
                  <div className="main">
                    <Header cerrarSession={cerrarSession} />
                    
                    <main className="content" id="mainContainer">
                        <Routes>
                                {getRoutes()}
                        </Routes>  
                         
                    </main>
                    <Footer></Footer>
                    
                  </div>
                  
            </div>    
            <ToastContainer /> 
                   
        </>
        
      );
    }
  }

  return (
    <Router>
        {Logins()}
    </Router>
  );
}

export default App;
