import React from 'react';
import util from '../utils/generalFunctions';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const DashboardCard = ({ studentPhoto, title, subtitle, nombre, curso, nivelEducativo, anio, periodo, }) => {
  const studentInfo = util.getStudentInformation();
  const space =(studentInfo)?" - ":" ";
  return (
    <div className="col-12 col-sm-12 col-xxl-12 d-flex">
      <div className="card flex-fill">
        <div className="card-body p-0 d-flex">
          <div className="row g-0 w-100">
            <div className="col-6">
              <div className="p-3 m-1">
                <h4 className="mb-2">{title}</h4>
                <p className="mb-0">{subtitle}</p>
              </div>
            </div>
            <div className=" card-estudiante-info">
            <LazyLoadImage
                src={studentInfo?.urlImage ?? studentPhoto} // Utiliza un fallback para la imagen con el operador de coalescencia nula
                alt="Foto del estudiante" // Texto alternativo para la imagen
                className="foto-estudiante" // Conserva las clases existentes para mantener el estilo
                effect="blur" // Opcional: efecto de carga para mejorar la experiencia visual mientras la imagen carga
            />
                <h4 className=" text-head">{(studentInfo?.fullName??nombre??'').toUpperCase()}</h4>
                <span className=" text">
                {(studentInfo?.courseName??curso??'')+space} 
                {(studentInfo?.educationLevelName??nivelEducativo??'')+space}  
                {"Año "+(studentInfo?.schoolYear??anio??'')}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardCard;
