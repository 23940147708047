import React from 'react';
import '../css/Building.css';
import buildImage from '../img/Building1 (2).png';


function Build() {
    return (
        <div className="build">
        <img src={buildImage} alt="Background" className="build-bg"/>
            <div className="build-content">
                <h1>En Mantenimiento</h1>
                <p>
                🚧 Estamos trabajando arduamente para mejorar esta sección <br/>y brindarte una experiencia más completa y eficiente.
                </p>
            </div>
        </div>
    );
}

export default Build;
