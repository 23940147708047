import api from './api';

const getDirectorGrupos = async () => {
    try {
        const response = await api.get('/academic/group-director');
        return response.data;
    } catch (error) {
        throw error;
    }
};

const createDirectorGrupo = async (teacherData) => {
    try {
        const response = await api.post('/academic/group-director', teacherData);
        return response.data;
    } catch (error) {
        throw error;
    }
};

const updateDirectorGrupo = async (teacherData) => {
    try {
        const response = await api.put('/academic/group-director', teacherData);
        return response.data;
    } catch (error) {
        throw error;
    }
};

const getDirectorGrupo = async (id) => {
    try {
        const response = await api.get(`/academic/group-director/${id}`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

const deleteDirectorGrupoById = async (id) => {
    try {
        const response = await api.delete(`/academic/group-director/${id}`);
        return response.data;
    } catch (error) {
        throw error;
    }
};


export default {
    getDirectorGrupos,
    createDirectorGrupo,
    getDirectorGrupo,
    updateDirectorGrupo,
    deleteDirectorGrupoById
};
