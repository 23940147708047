import React, { useState } from 'react';
import { Tab, Tabs, Container } from 'react-bootstrap';
import '../css/estudiantes.css';
import ConfigureAcademicAreas from './ConfigureAcademicAreas';
import DimensionAcademica from './DimensionAcademica';
import AcademicPeriod from './periodoAcademico';
import ConfigureGrades from './grados';
import ConfigureYears from './ConfigureYears';
import Courses from './cursos';
import Asignatura from './asignatura';
import AcademicOrganization from './organizacionAcademica';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChalkboardTeacher, faCalendarAlt, faBookOpen, faAtom, faShapes, faGraduationCap, faClock, faBook } from '@fortawesome/free-solid-svg-icons';

const ConfiguracionAcademica = () => {
    const [activeTab, setActiveTab] = useState('organizacionAcademica');

    const handleSelect = (selectedTab) => {
        setActiveTab(selectedTab);
    };

    return (
        <div className="mt-4">
            <div className="card">
                
                <div className="card-body">
                    <Tabs activeKey={activeTab} onSelect={handleSelect} className="mb-3">
                        <Tab eventKey="añosEscolares" title={<span><FontAwesomeIcon icon={faCalendarAlt} /> Año Esc.</span>}>
                            <ConfigureYears />
                        </Tab>
                        <Tab eventKey="periodos" title={<span><FontAwesomeIcon icon={faClock} /> Períodos</span>}>
                            <AcademicPeriod />
                        </Tab>
                        <Tab eventKey="areasAcademicas" title={<span><FontAwesomeIcon icon={faAtom} /> Áreas</span>}>
                            <ConfigureAcademicAreas />
                        </Tab>
                        <Tab eventKey="dimensionAcademica" title={<span><FontAwesomeIcon icon={faShapes} /> Dimensiones</span>}>
                            <DimensionAcademica />
                        </Tab>
                        <Tab eventKey="asignaturas" title={<span><FontAwesomeIcon icon={faBook} /> Asignaturas</span>}>
                            <Asignatura />
                        </Tab>
                        <Tab eventKey="grados" title={<span><FontAwesomeIcon icon={faGraduationCap} /> Grados</span>}>
                            <ConfigureGrades />
                        </Tab>
                        <Tab eventKey="cursos" title={<span><FontAwesomeIcon icon={faBookOpen} /> Cursos</span>}>
                            <Courses />
                        </Tab>
                        <Tab eventKey="organizacionAcademica" title={<span><FontAwesomeIcon icon={faChalkboardTeacher} /> Org. Académica</span>}>
                            <AcademicOrganization />
                        </Tab>
                    </Tabs>
                </div>
            </div>
        </div>
    );
};

export default ConfiguracionAcademica;
