import api from './api';

const getIncidenciasTipos = async () => {
    try {
        const response = await api.get(`disciplinary/incidenceType`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

const getParentsIncidenciasTipos = async () => {
    try {
        const response = await api.get(`disciplinary/incidenceType-parents`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

const createIncidenciaTipo = async (incidenciaTipo) => {
    try {
        const response = await api.post(`disciplinary/incidenceType`, incidenciaTipo);
        return response.data;
    } catch (error) {
        throw error;
    }
};

const updateIncidenciaTipo = async (incidenciaTipo) => {
    try {
        const response = await api.put(`disciplinary/incidenceType`, incidenciaTipo);
        return response.data;
    } catch (error) {
        throw error;
    }
};

const getIncidenciaTipo = async (id) => {
    try {
        const response = await api.get(`disciplinary/incidenceType/${id}`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

const deleteIncidenciasTipoById = async (id) => {
    try {
        const response = await api.delete(`disciplinary/incidenceType/${id}`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export default {
    getIncidenciasTipos,
    createIncidenciaTipo,
    updateIncidenciaTipo,
    getIncidenciaTipo,
    deleteIncidenciasTipoById,
    getParentsIncidenciasTipos
};