// api.js
import axios from 'axios';

const instance = axios.create({
    baseURL: process.env.REACT_APP_API_DOMAIN
});

instance.interceptors.request.use((config) => {
    const token = localStorage.getItem('token'); // Asumiendo que el token se almacena en localStorage
    if (token) {
        config.headers['Authorization'] = 'Bearer ' + token;
    }
    return config;
}, (error) => {
    return Promise.reject(error);
});


instance.interceptors.response.use(
  (response) => {
    
    return response;
  },
  (error) => {
    if (error.response) {
      if (error.response.status === 401) {
        localStorage.setItem('userTypeId', '');
        localStorage.setItem('token', '');
        const protocol = window.location.protocol;
        const host = window.location.host;
        const baseUrl = `${protocol}//${host}`;
        const loginPath = '/login';
        const loginUrl = `${baseUrl}${loginPath}`;
        window.location.href = loginUrl;
      }
    }

    return Promise.reject(error);
  }
);

export default instance;