import React, { useEffect, useRef, useState } from "react";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import incidenciasService from "../../services/incidenciasService";
import Loading from "../Loading";
import EstudianteListFilter from "../Filters/EstudentListFilter";
import tipoIncidenciaService from "../../services/tipoIncidenciaService";
import academicService from '../../services/academicService.js';
import collectionsService from '../../services/collectionsService.js';
import moment from "moment";
import EstudianteFilterCustomList from "../Filters/EstudianteFilterCustomList.js";
import util from "../../utils/generalFunctions.js"
import types from '../../services/types';

function IncidenciasForm(props) {
  const { incidencia, onClose, ReadOnly } = props;
  const [showLoading, setShowLoading] = useState(false);
  const filteReference = useRef(null);
  const [tiposIncidencias, setTiposIncidencias] = useState([]);
  const [categorias, setCategorias] = useState([]); // Inicializar como un array vacío
  const [years, setYears] = useState([]);
  const [periodosList, setPeriodosList] = useState([]);
  const [userTypeId, setUserTypeId] = useState(0);

  const initData = {
    incidenceId: incidencia,
    schoolSettingId:"",
    incidenceTypeId:"",
    title:"",
    description:"",
    isPrivate:true,
    incidenceDate:moment().format("yyyy-MM-DD"),
    schoolYearId:"",
    schoolYearName:"",
    periodId:"",
    studentsId:"",
  };
  
//
  const [formData, setFormData] = useState(initData);
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
    if(name==="schoolYearId"){
      cargarPeriodos(value);
    }
    if(name==="parentTypeId"){
      cargarTiposIncidencia(value);
    }
  };

useEffect(() => {
    fetchCategorias();
}, []);

const fetchCategorias = async () => {
    setShowLoading(true); // Cambia el estado a false una vez que los datos se carguen
    try {

      const user= util.getUserInformation();
      if(user) setUserTypeId(Number(user.userTypeId));

      const yearsResp = await academicService.getYears();
      setYears(yearsResp);

      const parameters = await collectionsService.getParameters();
      const currentAnio = parameters.find((op) => String(op.keyName) === "AnioElectivo");
      if(currentAnio){
          setFormData(prevState => ({
              ...prevState,
              schoolYearId:currentAnio.value
          }));
        await cargarPeriodos(currentAnio.value);
      }

      const response = await tipoIncidenciaService.getParentsIncidenciasTipos();
      if(response){
          setCategorias(response);
          //setTiposIncidencias(data);
      }else{
          setCategorias([]);
          setTiposIncidencias([]);
      }

      if (initData.incidenceId>0) {
        try {
            const resIncidencia = await incidenciasService.getIncidencia(initData.incidenceId); // Asegúrate de que este método existe y es correcto
            await cargarPeriodos(resIncidencia.schoolYearId);
            await cargarTiposIncidencia(resIncidencia.parentTypeId);
            resIncidencia.incidenceDate=moment(resIncidencia.incidenceDate).format("yyyy-MM-DD");
            setFormData((prevState) => ({ ...prevState, ...resIncidencia }));
            filteReference?.current?.setFormValues(resIncidencia.studentsId.split(","));
        } catch (error) {
            console.error("Error al obtener la incidencia:", error);
        }
    }

    } catch (error) {
      console.error("Error al obtener tipos de incidencias:", error);
    }
    setShowLoading(false);// Cambia el estado a false una vez que los datos se carguen
};

const cargarTiposIncidencia = async (categoriaId) => {
  try {
    const response = await tipoIncidenciaService.getIncidenciasTipos();
    if(response){
    const parentData = response.filter(r=>String(r.parentTypeId)===String(categoriaId));             
      if(parentData){
          setTiposIncidencias(parentData);
      }else{
        setTiposIncidencias([]);
      }
    }else{
      setTiposIncidencias([]);
    }
  } catch (error) {
    console.error("Error al obtener información de tipos de incidencia:", error);
    setTiposIncidencias([]);
  }
}

const cargarPeriodos = async (schoolYearId) => {
  try {
      const schoolYear = await academicService.getYearById(schoolYearId);              
      if(schoolYear){
          setPeriodosList(schoolYear.periods)
      }            
  } catch (error) {
  console.error("Error al obtener información de año escolar:", error);
  }
}

  const handleSubmit = async (event) => {
    event.preventDefault();
    // Validaciones y lógica de envío
    // Por ejemplo:
    if (!formData.title || 
        !formData.incidenceTypeId || 
        !formData.incidenceDate  || 
        !formData.studentsId || 
        !formData.description ||
        !formData.periodId ||
        !formData.schoolYearId ||
        formData.studentsId.includes(",0")) {
      toast.error("Por favor, completa todos los campos obligatorios.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return;
    }
    setShowLoading(true);
    // Supongamos que enviamos los datos al servidor:
    try {
      let response = null;
      if(formData.incidenceId > 0){
        response = await incidenciasService.updateIncidencia(formData);
      }else{
        response = await incidenciasService.createIncidencia(formData);
      }
      if(response && response.incidenceId>0){
        console.log("Enviar datos al servidor...", formData);
        Swal.fire("¡Exito!", "Incidencia guardada exitosamente.", "success");
        if (onClose) onClose();
      }else{
        Swal.fire("Error", "Hubo un problema al guardar la incidencia.", "error");
      }
    } catch (error) {
      console.error("Error al guardar la incidencia:", error);
      Swal.fire("Error", "Hubo un problema al guardar la incidencia.", "error");
    }
    setShowLoading(false);
  };

  const updateForm = (data) => {
    const students = data.join(',');
    setFormData(prevState => ({
        ...prevState,
        studentsId:students
      }));
  }

  const handleIsPrivate = async (e) => {
    const checked = e.target.checked;
    setFormData(prevState => ({
      ...prevState,
      isPrivate:checked
    }));
  }

  return (
    <div className="container">
    <div className="card">
      <div className="card-header">
        <h5 className="card-title">{formData.incidenceId > 0 ? "Actualizar Incidencia" : "Registro de Incidencia"}</h5>
        {formData.incidenceId === 0 && (
          <h6 className="card-subtitle text-muted">Completa el formulario para guardar la incidencia.</h6>
        )}
      </div>
      <div className="card-body">
        {showLoading && <Loading />}
  
        {!showLoading && (
          <form onSubmit={handleSubmit}>
            <div className="row g-2">
              {/* Año Escolar */}
              <div className="col-md-6">
                <label htmlFor="schoolYearId" className="form-label">Año escolar:*</label>
                <select
                  className="form-select"
                  id="schoolYearId"
                  name="schoolYearId"
                  disabled={ReadOnly}
                  value={formData.schoolYearId}
                  onChange={handleInputChange}
                  required
                >
                  <option value="">Seleccione</option>
                  {years.map((schoolYear) => (
                    <option key={schoolYear.schoolYearId} value={schoolYear.schoolYearId}>
                      {schoolYear.year}
                    </option>
                  ))}
                </select>
              </div>
              {/* Periodo */}
              <div className="col-md-6">
                <label htmlFor="periodId" className="form-label">Periodo Académico:*</label>
                <select
                  className="form-select"
                  id="periodId"
                  name="periodId"
                  disabled={ReadOnly}
                  value={formData.periodId}
                  onChange={handleInputChange}
                  required
                >
                  <option value="">Seleccione</option>
                  {periodosList.map((period) => (
                    <option key={period.academicPeriodId} value={period.academicPeriodId}>
                      {period.periodName}
                    </option>
                  ))}
                </select>
              </div>
            </div>
  
            <div className="row g-2">
              {/* Título */}
              <div className="col-md-6">
                <label htmlFor="title" className="form-label">Título:*</label>
                <input
                  type="text"
                  className="form-control"
                  id="title"
                  name="title"
                  placeholder="Título de la incidencia"
                  required
                  readOnly={ReadOnly}
                  value={formData.title}
                  onChange={handleInputChange}
                />
              </div>
              {/* Categoría */}
              <div className="col-md-3">
                <label htmlFor="categoria" className="form-label">Categoría:*</label>
                <select
                  className="form-select"
                  id="parentTypeId"
                  name="parentTypeId"
                  value={formData.parentTypeId}
                  onChange={handleInputChange}
                  required
                >
                  <option value="">Seleccione una categoría</option>
                  {categorias.map((c) => (
                    <option key={c.inicidenceTypeId} value={c.inicidenceTypeId}>
                      {c.description}
                    </option>
                  ))}
                </select>
              </div>
              {/* Tipo */}
              <div className="col-md-3">
                <label htmlFor="categoria" className="form-label">Tipo de incidencia:*</label>
                <select
                  className="form-select"
                  id="incidenceTypeId"
                  name="incidenceTypeId"
                  value={formData.incidenceTypeId}
                  onChange={handleInputChange}
                  required
                >
                  <option value="">Seleccione una categoría</option>
                  {tiposIncidencias.map((c) => (
                    <option key={c.inicidenceTypeId} value={c.inicidenceTypeId}>
                      {c.description}
                    </option>
                  ))}
                </select>
              </div>
            </div>
  
            {userTypeId && (userTypeId === types.userTypes.COODINATOR || userTypeId === types.userTypes.ADMIN) && (
              <div className="form-check form-switch mb-3">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="isPrivate"
                  checked={formData.isPrivate}
                  onChange={handleIsPrivate}
                />
                <label className="form-check-label" htmlFor="isPrivate">
                  Incidencia privada
                </label>
              </div>
            )}
  
            {/* Descripción */}
            <div className="mb-3">
              <label htmlFor="description" className="form-label">Descripción:*</label>
              <textarea
                className="form-control"
                id="description"
                name="description"
                rows="3"
                placeholder="Descripción detallada de la incidencia"
                required
                readOnly={ReadOnly}
                value={formData.description}
                onChange={handleInputChange}
              ></textarea>
            </div>
  
            {/* Implicados */}
            <div className="row g-2 mb-3">
              <EstudianteFilterCustomList
                callback={updateForm}
                ref={filteReference}
                properties={{
                  className: 'col-md-12',
                  id: 'formIncidencia'
                }}
                ReadOnly={ReadOnly}
              />
            </div>
  
            <div className="mb-3">
              <label htmlFor="date" className="form-label">Fecha:*</label>
              <input
                type="date"
                className="form-control"
                id="date"
                name="date"
                required
                readOnly={ReadOnly}
                value={formData.incidenceDate}
                onChange={(e) => {
                  setFormData({ ...formData, incidenceDate: moment(e.target.value).format("yyyy-MM-DD") });
                }}
              />
            </div>
  
            {/* Botón de envío */}
            {!ReadOnly && (
              <div className="text-center">
                <button type="submit" className="btn btn-primary">
                  Guardar Incidencia
                </button>
              </div>
            )}
          </form>
        )}
      </div>
    </div>
  </div>
  
  
  );
}

export default IncidenciasForm;
