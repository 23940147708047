import api from './api';

const getCursos = async () => {
    try {
        const response = await api.get(`/academic/course`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

const getEducationLevels= async () => {
    try {
        const response = await api.get(`/academic/education-level`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

const createCurso = async (cursoData) => {
    try {
        const response = await api.post(`/academic/course`, cursoData);
        return response.data;
    } catch (error) {
        throw error;
    }
};

const updateCurso = async (cursoData) => {
    try {
        const response = await api.put(`/academic/course`, cursoData);
        return response.data;
    } catch (error) {
        throw error;
    }
};

const getCurso = async (id) => {
    try {
        const response = await api.get(`/academic/course/${id}`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

const getEducationLevel = async (id) => {
    try {
        const response = await api.get(`/academic/education-level/${id}`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

const deleteCursoById = async (id) => {
    try {
        const response = await api.delete(`/academic/course/${id}`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export default {
    getCursos,
    getEducationLevels,
    createCurso,
    getCurso,
    getEducationLevel,
    updateCurso,
    deleteCursoById
};
